// project import
import { NavItemType } from "types/menu";

import { AdminMenu } from "modules/admin/menu";
import { FleetOperatorMenu } from "modules/fleet-operator/menu";
import { GarageOperatorMenu } from "modules/garage-operator/menu";
import { PlaygroundMenu } from "modules/playground/menu";
import { PowerManagementMenu } from "modules/power-management/menu";
import { SiteOperatorMenu } from "modules/site-operator/menu";

// ==============================|| MENU ITEMS ||============================== //

export interface ModuleMenuItems {
  id: string;
  items: NavItemType[];
}

const menuItems: { items: ModuleMenuItems[] } = {
  items: [
    {
      id: "fleet-operator",
      items: [FleetOperatorMenu],
    },
    {
      id: "site-operator",
      items: [SiteOperatorMenu],
    },
    {
      id: "garage-operator",
      items: [GarageOperatorMenu],
    },
    {
      id: "power-management",
      items: [PowerManagementMenu],
    },
    {
      id: "admin",
      items: [AdminMenu],
    },
    {
      id: "playground",
      items: [PlaygroundMenu],
    },
  ],
};

export default menuItems;
