import {
  Alert,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ChargingStation } from "service/api-slice";
import ChargingStationLastSeen from "./ChargingStationLastSeen";
import ChargingStationStatusIndicator from "./ChargingStationStatusIndicator";

const FaultedChargingStationTableRow = ({
  chargingStation,
  onClick,
}: {
  chargingStation: ChargingStation;
  onClick: (cs: ChargingStation) => void;
}) => {
  return (
    <TableRow hover={true} sx={{ cursor: "pointer" }} onClick={() => onClick(chargingStation)}>
      <TableCell>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Typography variant="h5">{chargingStation.name}</Typography>
            <Typography variant="caption" sx={{ color: "text.secondary" }}>
              {chargingStation.id}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" justifyContent="flex-end">
              <ChargingStationStatusIndicator chargingStation={chargingStation} />
            </Box>
          </Grid>
          <Grid item xs={6}>
            {chargingStation.model}
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end">
              <em style={{ textTransform: "lowercase" }}>
                <FormattedMessage id="last-seen" />
                &nbsp;
                <ChargingStationLastSeen chargingStation={chargingStation} />
              </em>
            </Box>
          </Grid>
          {chargingStation.status?.errors?.map((error) => (
            <Grid item key={`${chargingStation.id}-error-${error.code}`} xs={12}>
              <Alert severity="error">
                ERR{error.code} : {error.name} - {error.common_causes.join("; ")}
              </Alert>
            </Grid>
          ))}
        </Grid>
      </TableCell>
    </TableRow>
  );
};

const FaultedChargingStationsMobile = ({
  chargingStations,
  onClick,
}: {
  chargingStations: ChargingStation[];
  onClick: (cs: ChargingStation) => void;
}) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {chargingStations.map((chargingStation) => (
            <FaultedChargingStationTableRow
              key={chargingStation.id}
              chargingStation={chargingStation}
              onClick={onClick}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FaultedChargingStationsMobile;
