import { Replay5Outlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import {
  Row,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import JsonViewer from "components/JsonViewer";
import TableNoResults from "components/TableNoResults";
import TableRowSkeleton from "components/TableRowSkeleton";
import { parseISO, subHours } from "date-fns";
import { MouseEventHandler, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { OcppEvent, useGetOcppEventsQuery } from "service/api-slice";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";
import TraceID from "./TraceID";

const columnHelper = createColumnHelper<OcppEvent>();

const columns = [
  columnHelper.accessor("timestamp", {
    cell: (i) => {
      return parseAndFormatDate(i.getValue(), { format: "MM/dd/yyyy HH:mm:ss.SSS zzz" });
    },
    sortingFn: "datetime",
    header: () => <FormattedMessage id="message-time" />,
  }),
  columnHelper.accessor("trace_id", {
    header: () => <FormattedMessage id="trace-id" />,
    cell: (i) => <TraceID traceId={i.getValue()} />,
  }),
  columnHelper.accessor("type", {
    header: () => <FormattedMessage id="type" />,
  }),
  columnHelper.display({
    id: "unqiue_id",
    header: () => <FormattedMessage id="unique-id" />,
    cell: ({ row }) => {
      if (row.original.type === "ocpp_message") {
        return row.original.data?.unique_id;
      }
    },
  }),
  columnHelper.display({
    id: "message_type",
    header: () => <FormattedMessage id="message-type" />,
    cell: ({ row }) => {
      if (row.original.type === "ocpp_message") {
        return row.original.data?.message_type;
      }
    },
  }),
  columnHelper.display({
    id: "origin",
    header: () => <FormattedMessage id="origin" />,
    cell: ({ row }) => {
      if (row.original.type === "ocpp_message") {
        return row.original.data?.origin;
      }
    },
  }),
  columnHelper.display({
    id: "action",
    header: () => <FormattedMessage id="action" />,
    cell: ({ row }) => {
      if (row.original.type === "ocpp_message") {
        return row.original.data?.action;
      }
    },
  }),
];

const OcppEventsTable = ({
  csId,
  range: initialRange,
  traceId,
  allowTailing,
}: {
  csId: string;
  traceId?: string;
  range: { start: Date; end: Date };
  allowTailing: boolean;
}) => {
  const [range, setRange] = useState(initialRange);
  const [polling, setPolling] = useState(false);
  const [ocppEvents, setOcppEvents] = useState<OcppEvent[]>([]);
  const { data, isLoading, isFetching } = useGetOcppEventsQuery({
    csId,
    traceId,
    range: {
      start: range.start.toISOString(),
      end: range.end.toISOString(),
    },
  });

  const table = useReactTable({
    data: ocppEvents,
    columns: columns,
    initialState: {
      columnVisibility: {
        trace_id: traceId === undefined,
      },
      sorting: [
        {
          id: "timestamp",
          desc: true,
        },
      ],
    },
    getRowId: (row) => `${row.id}`,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand: (row) => true,
  });

  useEffect(() => {
    if (data?.data) {
      setOcppEvents((prev) => {
        const newItems = data.data.filter((event) => !prev.find((e) => e.id === event.id));
        return [...newItems, ...prev].sort((a, b) => b.id - a.id);
      });
    }
  }, [data]);

  const loadMore = useCallback(() => {
    const lastEvent = ocppEvents[ocppEvents.length - 1];
    const end = parseISO(lastEvent.timestamp);
    const start = subHours(end, 1);
    setRange({ start, end });
  }, [ocppEvents]);

  const startPolling = () => {
    setPolling(true);
  };

  const stopPolling = () => {
    setPolling(false);
  };

  useEffect(() => {
    if (polling) {
      const interval = setInterval(() => {
        const lastEvent = ocppEvents[0];
        setRange({ start: parseISO(lastEvent.timestamp), end: new Date() });
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [polling, ocppEvents]);

  const [hoveredRow, setHoveredRow] = useState<string>("");

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableCell key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody>
          <TableRowSkeleton
            isLoading={isLoading}
            columnCount={table.getLeafHeaders().length}
            rowCount={10}
          />
          {ocppEvents.length === 0 && (
            <TableNoResults columnCount={table.getLeafHeaders().length} />
          )}
          {polling && allowTailing && (
            <TableRow>
              <TableCell colSpan={table.getLeafHeaders().length}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <CircularProgress size={16} thickness={5} />
                  <Button onClick={stopPolling} size="small">
                    <FormattedMessage id="stop-polling" />
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          )}
          {!polling && allowTailing && (
            <TableRow>
              <TableCell colSpan={table.getLeafHeaders().length}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Replay5Outlined color="primary" />
                  <Button onClick={startPolling} size="small">
                    <FormattedMessage id="start-polling" />
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          )}
          {table.getRowModel().rows.map((row) => (
            <OcppEventRow
              key={row.id}
              row={row}
              highlight={
                row.original.type === "ocpp_message"
                  ? hoveredRow === row.original.data?.unique_id
                  : false
              }
              onMouseLeave={() => setHoveredRow("")}
              onMouseEnter={() => {
                if (row.original.type === "ocpp_message") {
                  setHoveredRow(row.original.data?.unique_id);
                }
              }}
            />
          ))}
          {ocppEvents.length > 0 &&
            ocppEvents[ocppEvents.length - 1].type !== "http_connection_started" && (
              <TableRow>
                <TableCell colSpan={table.getLeafHeaders().length}>
                  <LoadingButton
                    loading={isFetching}
                    onClick={loadMore}
                    size="small"
                    variant="contained"
                  >
                    {/* <Button onClick={loadMore} size="small" variant="contained" color="secondary"> */}
                    <FormattedMessage id="load-more" />
                    {/* </Button> */}
                  </LoadingButton>
                </TableCell>
              </TableRow>
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const OcppEventRow = ({
  row,
  onMouseEnter,
  onMouseLeave,
  highlight,
}: {
  row: Row<OcppEvent>;
  onMouseEnter: MouseEventHandler | undefined;
  onMouseLeave: MouseEventHandler | undefined;
  highlight: boolean;
}) => {
  const theme = useTheme();

  return (
    <>
      <TableRow
        key={row.id}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sx={{
          cursor: "pointer",
          bgcolor: getRowBgColor(
            row.original,
            theme.palette.warning.lighter,
            theme.palette.info.lighter,
            highlight
          ),
          "& > *": row.getIsExpanded() ? { borderBottom: "unset" } : {},
        }}
        onClick={() => row.toggleExpanded()}
      >
        {row.getVisibleCells().map((cell) => {
          return (
            <TableCell key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          );
        })}
      </TableRow>
      {row.getIsExpanded() && (
        <TableRow key={`${row.id}-data`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <TableCell sx={{ p: 0 }} colSpan={row.getVisibleCells().length}>
            <JsonViewer json={row.original.data} />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const getRowBgColor = (
  event: OcppEvent,
  warningColor: string,
  highlightColor: string,
  highlight: boolean
) => {
  let bgColor;
  if (event.type === "ocpp_message") {
    bgColor = highlight ? highlightColor : "inherit";
  } else {
    bgColor = warningColor;
  }
  return bgColor;
};

export default OcppEventsTable;
