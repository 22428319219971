import { Alert, AlertTitle, Button, Stack, TextField, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import { useEffect, useState } from "react";
import { useClearChargingProfileMutation, useSetChargingProfileMutation } from "service/api-slice";

export const PowerLimitControl = (props: { chargingStationId: string; transactionId: string }) => {
  const [limit, setLimit] = useState<number>();

  const [setChargingProfile, setProfileResult] = useSetChargingProfileMutation();
  const [clearChargingProfile, clearProfileResult] = useClearChargingProfileMutation();

  const apply = () => {
    if (limit) {
      setChargingProfile({
        csId: props.chargingStationId,
        txId: props.transactionId,
        limit: limit * 1000,
      });
    }
  };

  const clear = () => {
    clearChargingProfile({ csId: props.chargingStationId });
  };

  useEffect(() => {
    if (setProfileResult.isError) {
      setLimit(undefined);
    }
  }, [setProfileResult]);

  useEffect(() => {
    if (clearProfileResult.isSuccess) {
      setLimit(undefined);
    }
  }, [clearProfileResult]);

  return (
    <MainCard title="Power Limit">
      {setProfileResult.isSuccess && (
        <Stack direction="row" sx={{ mb: 4 }} spacing={2} justifyContent="space-between">
          <Typography alignSelf="center">
            Current Limit: <strong>{limit} kW</strong>
          </Typography>
          <Button onClick={clear} disabled={clearProfileResult.isLoading}>
            Clear Limit
          </Button>
        </Stack>
      )}
      <Stack direction="row" spacing={2}>
        <TextField
          fullWidth
          onChange={(e) => {
            setLimit(parseInt(e.target.value));
          }}
          InputProps={{
            endAdornment: "kW",
          }}
        />
        <Button onClick={apply} disabled={setProfileResult.isLoading}>
          Apply
        </Button>
      </Stack>
      {setProfileResult.isError && (
        <Alert sx={{ mt: 2 }} severity="error">
          <AlertTitle>An error occured</AlertTitle>
          {(setProfileResult.error!! as any).data.message}
        </Alert>
      )}
      {setProfileResult.isSuccess && (
        <Alert sx={{ mt: 2 }} severity="success">
          <AlertTitle>Applied</AlertTitle>
        </Alert>
      )}
      {clearProfileResult.isError && (
        <Alert sx={{ mt: 2 }} severity="error">
          <AlertTitle>An error occured</AlertTitle>
          {(clearProfileResult.error!! as any).data.message}
        </Alert>
      )}
      {clearProfileResult.isSuccess && (
        <Alert sx={{ mt: 2 }} severity="success">
          <AlertTitle>Cleared</AlertTitle>
        </Alert>
      )}
    </MainCard>
  );
};
