import { useModules } from "contexts/GravityUserContext";
import { ReactNode } from "react";
import { Navigate, Outlet } from "react-router";

export const ModuleGuard = (props: { modules: string[]; children?: ReactNode }) => {
  const allowedModules = useModules();

  return (
    <>
      {props.modules.some((module) => allowedModules.includes(module)) ? (
        props.children ? (
          props.children
        ) : (
          <Outlet />
        )
      ) : (
        <Navigate to={"/404"} replace />
      )}
    </>
  );
};
