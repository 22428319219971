import { FeatureFlagDefinition } from "./FeatureFlag";
import { useFeatureFlags } from "./FeatureFlagProvider";
import { Flags } from "./Flags";

export type FeatureProps = {
  flag: FeatureFlagDefinition;
  fallback?: React.ReactNode;
  match?: boolean | string;
  children: React.ReactNode;
};

export const Feature = (props: FeatureProps) => {
  const experimentation = useFeatureFlags();

  if (!checkFeatureFlag(props.flag)) {
    console.warn(`Feature flag ${props.flag.name} is not registered.`);
    return <>props.fallback</> ?? <></>;
  }

  const evaluation = experimentation.evaluateFeature(props.flag);
  return evaluation === (props.match ?? true) ? (
    <>{props.children}</>
  ) : (
    <>{props.fallback}</> ?? <></>
  );
};

export const checkFeatureFlag = (flag: FeatureFlagDefinition) => {
  return Object.values(Flags).some((f) => f === flag);
};
