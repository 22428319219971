import { OrganizationsPage } from "modules/admin/organizations";
import { OrganizationListPage } from "modules/admin/organizations/list";
import { UsersPage } from "modules/admin/users";
import { RouteObject } from "react-router";
import { ModuleGuard } from "utils/route-guard/ModuleGuard";
import { SitesPage } from "./sites";
import { SitesListPage } from "./sites/list";

export const AdminRoutes: RouteObject = {
  element: <ModuleGuard modules={["admin"]} />,
  path: "/admin",
  children: [
    {
      path: "organizations",
      element: <OrganizationsPage />,
      children: [
        {
          index: true,
          element: <OrganizationListPage />,
        },
      ],
    },
    {
      path: "sites",
      element: <SitesPage />,
      children: [
        {
          index: true,
          element: <SitesListPage />,
        },
      ],
    },
    {
      path: "users",
      element: <UsersPage />,
    },
  ],
};
