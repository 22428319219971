import {
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import MainCard from "components/MainCard";
import SessionFailuresByType from "components/charts/SessionFailuresByType";
import { useNavigateWithModule } from "hooks/useNavigateWithModule";
import { useQueryParamsState } from "hooks/useQueryParamsState";
import { TransactionsTable } from "pages/transactions/components/TransactionsTable";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  GetTransactionFailuresArgs,
  Transaction,
  useGetChargingStationsQuery,
  useGetMakesQuery,
  useGetModelsQuery,
  useGetTransactionFailuresQuery,
} from "service/api-slice";
import { useModuleContext } from "types/context";

const TransactionFailures = () => {
  const intl = useIntl();
  const { filter, module } = useModuleContext();
  const navigate = useNavigateWithModule(module);
  const [csId, setCsId] = useQueryParamsState("csId", "all");

  const { data: chargingStations } = useGetChargingStationsQuery({
    siteId: filter.siteId,
  });
  const [makeId, setMakeId] = useState<string>("all");
  const { data: makes } = useGetMakesQuery({ page: 0, count: 500 });

  const { data: models } = useGetModelsQuery(
    {
      makeId,
      page: 0,
      count: 500,
    },
    { skip: makeId === "all" }
  );
  const [vpicId, setVpicId] = useState<string>("all");
  const vpicIds = useMemo(() => {
    if (!models || !models.data) {
      return [];
    }

    return models.data.filter(
      (model, index, self) => self.findIndex((m) => m.vpic_id === model.vpic_id) === index
    );
  }, [models]);

  const [modelId, setModelId] = useState<string>("all");
  const { data: modelYears } = useGetModelsQuery(
    {
      makeId,
      vpicId: vpicId === "all" ? undefined : vpicId,
      page: 0,
      count: 500,
    },
    { skip: makeId === "all" }
  );
  const years = useMemo(() => {
    if (!modelYears || !modelYears.data) {
      return [];
    }

    return modelYears.data.filter(
      (model, index, self) => self.findIndex((m) => m.year === model.year) === index
    );
  }, [modelYears]);

  const getTransactionsArgs: GetTransactionFailuresArgs = useMemo(() => {
    return {
      start: filter.timeRange.start.toISOString(),
      end: filter.timeRange.end.toISOString(),
      orgId: filter.orgId,
      siteId: filter.siteId,
      csId: csId === "all" ? undefined : csId,
      makeId: makeId === "all" ? undefined : makeId,
      modelId: modelId === "all" ? undefined : modelId,
      vpicId: vpicId === "all" ? undefined : vpicId,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  }, [filter, csId, makeId, modelId, vpicId]);

  const { data } = useGetTransactionFailuresQuery({
    ...getTransactionsArgs,
    includeTransactions: true,
  });

  const shortDurationTransactions = useMemo(() => {
    return data?.data?.map((failures) => failures.short_duration_transactions ?? []).flat() ?? [];
  }, [data]);

  const lowPowerTransactions = useMemo(() => {
    return data?.data?.map((failures) => failures.low_power_transactions ?? []).flat() ?? [];
  }, [data]);

  const badErrorCodeTransactions = useMemo(() => {
    return data?.data?.map((failures) => failures.bad_error_code_transactions ?? []).flat() ?? [];
  }, [data]);

  const allTransactions = useMemo(() => {
    return badErrorCodeTransactions.concat(shortDurationTransactions, lowPowerTransactions);
  }, [badErrorCodeTransactions, shortDurationTransactions, lowPowerTransactions]);

  const topChargingStations = useMemo(() => {
    const chargingStationMap = new Map<string, { name: string; count: number }>();
    allTransactions.forEach((transaction) => {
      const count = chargingStationMap.get(transaction.charging_station.id) ?? {
        name: transaction.charging_station.name,
        count: 0,
      };
      chargingStationMap.set(transaction.charging_station.id, {
        name: count.name,
        count: count.count + 1,
      });
    });

    return Array.from(chargingStationMap.values())
      .sort((a, b) => b.count - a.count)
      .slice(0, 5);
  }, [allTransactions]);

  const topModels = useMemo(() => {
    const modelMap = new Map<string, { name: string; count: number }>();
    allTransactions.forEach((transaction) => {
      if (!transaction.vehicle?.model_id) {
        return;
      }

      const count = modelMap.get(transaction.vehicle.model_id) ?? {
        name: transaction.vehicle.description ?? "Unknown",
        count: 0,
      };
      modelMap.set(transaction.vehicle.model_id, { name: count.name, count: count.count + 1 });
    });

    return Array.from(modelMap.values())
      .sort((a, b) => b.count - a.count)
      .slice(0, 5);
  }, [allTransactions]);

  function navigateToTransaction(transaction: Transaction) {
    navigate(`charging-stations/${transaction.charging_station.id}/transactions/${transaction.id}`);
  }

  return (
    <>
      <Stack rowGap={2}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item container xs lg spacing={1} justifyContent={"flex-end"}>
            <Grid item xs={6} md="auto">
              <TextField
                select
                fullWidth
                value={chargingStations && csId ? csId : "all"}
                onChange={(e) => setCsId(e.target.value)}
              >
                <MenuItem key="all" value="all">
                  <FormattedMessage id="all-charging-stations" />
                </MenuItem>
                {chargingStations &&
                  chargingStations.data?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={6} md="auto">
              <TextField
                select
                fullWidth
                value={makes && makeId ? makeId : "all"}
                onChange={(e) => {
                  setMakeId(e.target.value);
                  setVpicId("all");
                  setModelId("all");
                }}
              >
                <MenuItem key="all" value="all">
                  <FormattedMessage id="all-makes" />
                </MenuItem>
                {makes &&
                  makes.data?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={6} md="auto">
              <TextField
                select
                fullWidth
                value={vpicIds && vpicId ? vpicId : "all"}
                onChange={(e) => {
                  setVpicId(e.target.value);
                  setModelId("all");
                }}
              >
                <MenuItem key="all" value="all">
                  <FormattedMessage id="all-models" />
                </MenuItem>
                {vpicIds &&
                  makeId !== "all" &&
                  vpicIds.map((option) => (
                    <MenuItem key={option.vpic_id!} value={option.vpic_id!}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={6} md="auto">
              <TextField
                select
                fullWidth
                value={years && modelId ? modelId : "all"}
                onChange={(e) => setModelId(e.target.value)}
              >
                <MenuItem key="all" value="all">
                  <FormattedMessage id="all-years" />
                </MenuItem>
                {years &&
                  vpicId !== "all" &&
                  years.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.year}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
      {data && <SessionFailuresByType height={300} args={getTransactionsArgs} data={data.data} />}
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={6}>
          <MainCard content={false} title={intl.formatMessage({ id: "top-charging-stations" })}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="charging-station" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="failure-count" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topChargingStations.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.count}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <MainCard content={false} title={intl.formatMessage({ id: "top-vehicle-models" })}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="description" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="failure-count" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topModels.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.count}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <MainCard content={false} title={intl.formatMessage({ id: "bad-error-code-sessions" })}>
            {badErrorCodeTransactions && (
              <TransactionsTable
                startTime={filter.timeRange.start}
                endTime={filter.timeRange.end}
                transactions={badErrorCodeTransactions}
                onClick={(transaction) => navigateToTransaction(transaction)}
                showFailureCodes={true}
              />
            )}
          </MainCard>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <MainCard content={false} title={intl.formatMessage({ id: "short-duration-sessions" })}>
            {shortDurationTransactions && (
              <TransactionsTable
                startTime={filter.timeRange.start}
                endTime={filter.timeRange.end}
                transactions={shortDurationTransactions}
                onClick={(transaction) => navigateToTransaction(transaction)}
                showFailureCodes={true}
              />
            )}
          </MainCard>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <MainCard content={false} title={intl.formatMessage({ id: "low-power-sessions" })}>
            {lowPowerTransactions && (
              <TransactionsTable
                startTime={filter.timeRange.start}
                endTime={filter.timeRange.end}
                transactions={lowPowerTransactions}
                onClick={(transaction) => navigateToTransaction(transaction)}
                showFailureCodes={true}
              />
            )}
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default TransactionFailures;
