import { useRef, useState } from "react";

// material-ui
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  MenuList,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project import
import Avatar from "components/@extended/Avatar";
import IconButton from "components/@extended/IconButton";
import Transitions from "components/@extended/Transitions";
import MainCard from "components/MainCard";
import useAuth from "hooks/useAuth";

// assets
import { LogoutOutlined } from "@ant-design/icons";
import { useGravityUser } from "contexts/GravityUserContext";
import ManageFlagMenuItem from "experimentation/management/components/ManageFlagMenuItem";

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();
  const { user } = useGravityUser();
  const { logout } = useAuth();
  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = theme.palette.mode === "dark" ? "grey.200" : "grey.300";

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : "transparent",
          borderRadius: 1,
          "&:hover": {
            bgcolor: theme.palette.mode === "dark" ? "secondary.light" : "secondary.lighter",
          },
          "&:focus-visible": {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2,
          },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar alt="profile user" src={user?.picture_url} size="xs" />
          <Typography variant="subtitle1">
            {user?.given_name} {user?.family_name}
          </Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down("md")]: {
                    maxWidth: 250,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Stack>
                    <MainCard elevation={0} border={false} content={false}>
                      <CardContent>
                        <Grid container justifyContent="space-between" alignItems="center">
                          <Grid item>
                            <Stack direction="row" spacing={1.25} alignItems="center">
                              <Avatar
                                alt="profile user"
                                src={user?.picture_url}
                                sx={{ width: 32, height: 32 }}
                              />
                              <Stack>
                                <Typography variant="h6">
                                  {user?.given_name} {user?.family_name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  {user.organization?.name}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Logout">
                              <IconButton
                                size="large"
                                sx={{ color: "text.primary" }}
                                onClick={handleLogout}
                              >
                                <LogoutOutlined />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </MainCard>
                    {user?.organization?.slug === "gravity" && (
                      <MenuList>
                        <ManageFlagMenuItem />
                      </MenuList>
                    )}
                  </Stack>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
