import { SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";
import { Box, Stack, TableCell } from "@mui/material";
import { flexRender, Header, RowData } from "@tanstack/react-table";

const GravityTableColumnHeader = <TData extends RowData>({
  header,
}: {
  header: Header<TData, unknown>;
}) => {
  return (
    <TableCell
      className="noselect"
      key={header.id}
      colSpan={header.colSpan}
      sx={{
        cursor: header.column.getCanSort() ? "pointer" : "default",
      }}
      onClick={header.column.getToggleSortingHandler()}
    >
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
        {header.column.getCanSort() && (
          <Box component={"span"} sx={{ ml: 5 }}>
            {header.column.getIsSorted() === "asc" ? (
              <SortAscendingOutlined style={{ fontSize: "1.4em" }} />
            ) : header.column.getIsSorted() === "desc" ? (
              <SortDescendingOutlined style={{ fontSize: "1.4em" }} />
            ) : null}
          </Box>
        )}
      </Stack>
    </TableCell>
  );
};

export default GravityTableColumnHeader;
