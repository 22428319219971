import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import TableNoResults from "components/TableNoResults";
import TableRowSkeleton from "components/TableRowSkeleton";
import { formatRFC3339 } from "date-fns";
import DownloadLogButton from "modules/site-operator/charging-stations/components/DownloadLogButton";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import {
  ChargingStationLogRequest,
  ChargingStationSummary,
  useCreateChargingStationLogRequestMutation,
  useGetChargingStationLogRequestsQuery,
} from "service/api-slice";
import { useModuleContext } from "types/context";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";

const summaryPlaceholder = "<summary>";

const CreateLogRequestForm = ({
  cs,
  txId,
  refetch,
}: {
  cs: ChargingStationSummary;
  txId?: string | undefined;
  refetch: () => void;
}) => {
  const defaultSummary = cs.name + ": " + summaryPlaceholder;
  const [note, setNote] = useState<string>("");
  const [createJiraIssue, setCreateJiraIssue] = useState<boolean>(true);
  const [summary, setSummary] = useState<string>(defaultSummary);
  const [createRequest, createRequestResult] = useCreateChargingStationLogRequestMutation();

  const submitRequest = (e: React.FormEvent): void => {
    if (!createJiraIssue) {
      createRequest({ csId: cs.id, note: note });
    } else {
      createRequest({
        csId: cs.id,
        note: note,
        create_jira_issue: {
          summary: summary,
          transaction_id: txId,
        },
      });
    }
  };

  useEffect(() => {
    if (createRequestResult.isSuccess) {
      setNote("");
      setSummary(defaultSummary);
      createRequestResult.reset();

      refetch();
    }
  }, [createRequestResult, defaultSummary, refetch, setNote, setSummary]);

  return (
    <Stack spacing={1} direction="column">
      <TextField
        id="summary"
        value={summary}
        fullWidth
        sx={{ backgroundColor: "white" }}
        onFocus={(e) => {
          if (summary === defaultSummary) {
            const start = summary.indexOf(summaryPlaceholder);
            e.currentTarget.setSelectionRange(start, start + summaryPlaceholder.length);
          }
        }}
        disabled={!createJiraIssue || createRequestResult.isLoading}
        onChange={(e) => {
          setSummary(e.currentTarget.value);
        }}
        variant="outlined"
      />
      <TextField
        id="note"
        sx={{ backgroundColor: "white" }}
        label={<FormattedMessage id="enter-note" />}
        multiline={true}
        minRows={2}
        fullWidth
        value={note}
        disabled={createRequestResult.isLoading}
        onChange={(e) => {
          setNote(e.currentTarget.value);
        }}
        variant="outlined"
      />
      <Stack direction="row" justifyContent="space-between">
        <FormControlLabel
          label={<FormattedMessage id="create-jira-issue" />}
          control={
            <Checkbox
              checked={createJiraIssue}
              onClick={() => {
                setCreateJiraIssue(!createJiraIssue);
              }}
            />
          }
        />
        <LoadingButton
          onClick={submitRequest}
          loading={createRequestResult.isLoading}
          type="submit"
          size="small"
          variant="contained"
        >
          <FormattedMessage id="create-log-request" />
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

const makeJiraIssueLink = (issue_key: string) => {
  return "https://gravitytechnologies.atlassian.net/browse/" + issue_key;
};

const renderChargingStation = (module: string, lr: ChargingStationLogRequest) => {
  return (
    <Link to={`/${module}/charging-stations/${lr.charging_station_id}`}>
      {lr.charging_station_name}
    </Link>
  );
};

const renderTransactionLink = (module: string, csId: string, txId: string) => {
  return `/${module}/charging-stations/${csId}/transactions/${txId}`;
};

const RenderTransaction = ({ log_request }: { log_request: ChargingStationLogRequest }) => {
  const { module } = useModuleContext();
  if (!log_request.transaction_id) {
    return <>-</>;
  }

  return (
    <Link
      to={renderTransactionLink(
        module,
        log_request.charging_station_id,
        log_request.transaction_id
      )}
    >
      <FormattedMessage id="link" />
    </Link>
  );
};

const renderLogRequestDownload = (log_request: ChargingStationLogRequest) => {
  if (log_request.log_link != null) {
    return (
      <DownloadLogButton
        chargingStationId={log_request.charging_station_id}
        logRequestId={log_request.id}
      />
    );
  }
  return "-";
};

const renderJiraIssue = (log_request: ChargingStationLogRequest) => {
  if (log_request.jira_issue_key == null) {
    return "-";
  }

  return (
    <a
      href={makeJiraIssueLink(log_request.jira_issue_key)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {log_request.jira_issue_key}
    </a>
  );
};

const renderLogRequestStatus = (log_request: ChargingStationLogRequest) => {
  var text = log_request.status;
  if (log_request.error_msg != null) {
    text = text + " -- " + log_request.error_msg;
  }
  return text;
};

const LogRequestRow = ({
  log_request,
  showChargingStation,
}: {
  log_request: ChargingStationLogRequest;
  showChargingStation: boolean;
}) => {
  const { module } = useModuleContext();
  const theme = useTheme();

  return (
    <>
      <TableRow key={log_request.id.toString()} sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>{log_request.id}</TableCell>
        {showChargingStation && <TableCell>{renderChargingStation(module, log_request)}</TableCell>}
        <TableCell>
          <RenderTransaction log_request={log_request} />
        </TableCell>
        <TableCell>{parseAndFormatDate(log_request.created_at)}</TableCell>
        <TableCell>{log_request.created_by.name}</TableCell>
        <TableCell>{parseAndFormatDate(log_request.modified_at)}</TableCell>
        <TableCell>{renderLogRequestStatus(log_request)}</TableCell>
        <TableCell>{renderJiraIssue(log_request)}</TableCell>
        <TableCell>{renderLogRequestDownload(log_request)}</TableCell>
      </TableRow>
      {log_request.note && (
        <TableRow key={`note-${log_request.id}`}>
          <TableCell colSpan={showChargingStation ? 9 : 8}>
            <Box
              sx={{
                p: 2,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 2,
                background: `${theme.palette.grey[50]}`,
              }}
            >
              <strong>
                <FormattedMessage id="note" />
              </strong>
              : {log_request.note}
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export const LogsTable = ({
  chargingStation,
  range,
  disableCreate = false,
  showChargingStation = false,
  txId,
}: {
  chargingStation?: ChargingStationSummary | undefined;
  range?: { start: Date; end: Date } | undefined;
  disableCreate?: boolean | undefined;
  showChargingStation?: boolean;
  txId?: string | undefined;
}) => {
  const theme = useTheme();
  const queryArgs = {
    csId: chargingStation ? chargingStation.id : undefined,
    include_charging_station: showChargingStation,
    range: range
      ? {
          start_time: formatRFC3339(range.start),
          end_time: formatRFC3339(range.end),
        }
      : undefined,
  };

  const { data, isLoading, refetch, isUninitialized } = useGetChargingStationLogRequestsQuery(
    queryArgs,
    {
      pollingInterval: 10 * 1000, // in msec
    }
  );

  const forceRefetchTransactions = useCallback(() => {
    if (!isUninitialized) {
      refetch();
    }
  }, [isUninitialized, refetch]);

  return (
    <Stack>
      {!disableCreate && chargingStation && (
        <Box
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Stack
            spacing={1}
            sx={{
              m: 1,
              p: 2,
              background: theme.palette.background.default,
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Typography variant="subtitle1">
              <FormattedMessage id="create-log-request" />
            </Typography>
            <CreateLogRequestForm
              cs={chargingStation}
              txId={txId}
              refetch={forceRefetchTransactions}
            />
          </Stack>
        </Box>
      )}
      <TableContainer>
        <Table sx={{ minWidth: 350 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="request-id" />
              </TableCell>
              {showChargingStation && (
                <TableCell>
                  <FormattedMessage id="charging-station" />
                </TableCell>
              )}
              <TableCell>
                <FormattedMessage id="charging-session-column-name" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="requested-at" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="requested-by" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="updated-at" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="status" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="jira-issue" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="download" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRowSkeleton
              isLoading={isLoading}
              columnCount={showChargingStation ? 9 : 8}
              rowCount={10}
            />
            {data?.data.requests?.map((row) => (
              <LogRequestRow
                showChargingStation={showChargingStation}
                key={row.id}
                log_request={row}
              />
            ))}
            {data?.data.requests?.length === 0 && (
              <TableNoResults columnCount={showChargingStation ? 9 : 8} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
