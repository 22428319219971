import { withAuthenticationRequired } from "@auth0/auth0-react";

const renderNothing = () => <></>;
export const AuthenticationGuard = ({ component }: any) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: renderNothing,
  });

  return <Component />;
};
