import { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// third-party
import ReactApexChart, { Props as ChartProps } from "react-apexcharts";

// project import
import useConfig from "hooks/useConfig";
import { useGetChargingStationsStatusQuery } from "service/api-slice";
import { useModuleContext } from "types/context";

// chart options
const pieChartOptions = {
  chart: {
    type: "donut",
    width: 100,
    height: 100,
  },
  labels: ["Available", "Occupied", "Offline", "Faulted", "Unknown"],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          total: {
            show: true,
            showAlways: true,
          },
        },
      },
    },
  },
  legend: {
    show: true,
    fontFamily: `'Roboto', sans-serif`,
    offsetX: 10,
    offsetY: 10,
    labels: {
      useSeriesColors: false,
    },
    markers: {
      width: 12,
      height: 12,
      radius: 5,
    },
    itemMargin: {
      horizontal: 25,
      vertical: 4,
    },
  },
  responsive: [
    {
      breakpoint: 450,
      chart: {
        width: 280,
        height: 280,
      },
      options: {
        legend: {
          show: false,
          position: "bottom",
        },
      },
    },
  ],
};

const ChargePointStatusChart = (props: { height: number | null }) => {
  const theme = useTheme();
  const { mode } = useConfig();
  const { filter } = useModuleContext();

  const { primary } = theme.palette.text;
  const line = theme.palette.divider;
  const grey200 = theme.palette.grey[200];
  const backColor = theme.palette.background.paper;

  const [options, setOptions] = useState<ChartProps>(pieChartOptions);

  const secondary = theme.palette.primary[700];
  const primaryMain = theme.palette.primary.main;
  const successDark = theme.palette.success.main;
  const error = theme.palette.error.main;
  const orangeDark = theme.palette.warning.main;
  const info = theme.palette.grey[500];

  const { data } = useGetChargingStationsStatusQuery(
    {
      siteId: filter.siteId,
    },
    {
      pollingInterval: 15_000,
      skipPollingIfUnfocused: true,
    }
  );
  const [series, setSeries] = useState([0, 0, 0, 0, 0]);

  useEffect(() => {
    if (data) {
      setSeries(data);
    }
  }, [data]);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [primaryMain, successDark, secondary, error, info],
      xaxis: {
        labels: {
          style: {
            colors: [primary, primary, primary, primary, primary, primary, primary],
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: [primary],
          },
        },
      },
      grid: {
        borderColor: line,
      },
      legend: {
        labels: {
          colors: "grey.500",
        },
      },
      stroke: {
        colors: [backColor],
      },
    }));
  }, [
    mode,
    primary,
    line,
    grey200,
    backColor,
    secondary,
    primaryMain,
    successDark,
    error,
    orangeDark,
    info,
  ]);

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height={props.height?.toString()}
      />
    </div>
  );
};

export default ChargePointStatusChart;
