import { DollarOutlined } from "@ant-design/icons";
import { Flags } from "experimentation/Flags";
import { FormattedMessage } from "react-intl";
import { NavItemType } from "types/menu";

export const PlaygroundMenu: NavItemType = {
  id: "playground",
  title: <FormattedMessage id="playground" />,
  type: "group",
  visible: Flags.Playground,
  children: [
    {
      id: "payment",
      title: <FormattedMessage id="payment" />,
      type: "item",
      url: "/playground/payment",
      icon: DollarOutlined,
    },
  ],
};
