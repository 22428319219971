import { parseISO } from "date-fns";
import { format as formatTz } from "date-fns-tz";

type FormatDateOptions = {
  format?: string;
  tz?: string;
};

export function parseAndFormatDate(date: string, options?: FormatDateOptions): string {
  const fmt = options?.format ?? "MM/dd/yyyy HH:mm:ss zzz";

  if (!options?.tz) {
    return formatTz(parseISO(date), fmt);
  }
  return formatTz(parseISO(date), fmt, { timeZone: options.tz });
}
