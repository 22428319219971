import {
  Alert,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  ToggleButton,
  styled,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  PaymentIntent,
  useSyncPaymentIntentMutation,
  useTestOnlyPresentPaymentMethodMutation,
} from "service/api-slice";
import { IsLiveEnvironment } from "utils/env";
import { useDispatch } from "react-redux";
import { openSnackbarError, openSnackbarSuccess } from "store/reducers/snackbar";
import { DownOutlined } from "@ant-design/icons";

import { CardReaderHook } from "./card-reader";

interface pollingOpState {
  status: "polling";
  pi: PaymentIntent;
}

interface successOpState {
  status: "success";
  pi: PaymentIntent;
}

export interface PaymentIntentSyncerHook {
  setPaymentIntent: (pi: PaymentIntent) => void;
  paymentIntent?: PaymentIntent | undefined;
  paymentIntentStatus?: string | undefined;

  reset: () => void;
}

// Manages polling of the payment intent
export const usePaymentIntentSyncer = (
  terminalState: string,
  refresh: () => void
): PaymentIntentSyncerHook => {
  const [state, setState] = useState<pollingOpState | successOpState | null>(null);
  const [syncPaymentIntent, syncPaymentIntentResult] = useSyncPaymentIntentMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state?.status === "polling") {
      const interval = setInterval(() => {
        syncPaymentIntent(state.pi.stripe_id);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [state, syncPaymentIntent]);

  useEffect(() => {
    if (!state) {
      return;
    }

    if (syncPaymentIntentResult.isSuccess) {
      if (
        state?.status === "polling" &&
        syncPaymentIntentResult.data.data.status === terminalState
      ) {
        setState({
          status: "success",
          pi: { ...state.pi, status: syncPaymentIntentResult.data.data.status },
        });
        dispatch(
          openSnackbarSuccess({
            message: "PaymentIntent reached " + terminalState,
          })
        );
        refresh();
      }
    }
  }, [dispatch, refresh, state, setState, syncPaymentIntentResult, terminalState]);

  const lastError = useMemo(() => {
    return syncPaymentIntentResult.error;
  }, [syncPaymentIntentResult]);

  useEffect(() => {
    if (lastError) {
      dispatch(
        openSnackbarError({
          message: "Failed syncing PaymentIntent: " + JSON.stringify(lastError),
        })
      );
    }
  }, [dispatch, lastError]);

  const hook = useMemo(() => {
    return {
      setPaymentIntent: (pi: PaymentIntent) => {
        setState({ status: "polling", pi: pi });
      },
      paymentIntent: state?.pi,
      paymentIntentStatus: syncPaymentIntentResult.data?.data?.status,
      reset: () => {
        setState(null);
      },
    };
  }, [setState, state, syncPaymentIntentResult]);

  return hook;
};

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 36,
  borderRadius: 5,
}));

export const PaymentStatus = ({
  cardReader,
  piSyncer,
}: {
  cardReader: CardReaderHook;
  piSyncer: PaymentIntentSyncerHook;
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const pi = piSyncer.paymentIntent;
  return (
    <>
      {pi?.status === "" && <BorderLinearProgress />}
      {cardReader.failure_message && (
        <Alert
          severity="error"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                cardReader.reset();
                piSyncer.reset();
              }}
            >
              <FormattedMessage id="reset-reader" />
            </Button>
          }
        >
          {cardReader.failure_message}
        </Alert>
      )}

      <ToggleButton
        value="show-details"
        selected={showDetails}
        onClick={() => setShowDetails(!showDetails)}
        fullWidth
      >
        <DownOutlined />
      </ToggleButton>
      {showDetails && (
        <>
          {cardReader.failure_details && <div>{cardReader.failure_details}</div>}
          <TableContainer>
            <Table>
              <TableBody>
                {pi && (
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="payment-intent-id" />
                    </TableCell>
                    <TableCell>{pi.id}</TableCell>
                  </TableRow>
                )}
                {pi && (
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="state" />
                    </TableCell>
                    <TableCell>{pi.status}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export const TestOnlyPresentPaymentButtons = ({ pi }: { pi: PaymentIntent | undefined }) => {
  const [presentPaymentMethod, presentPaymentMethodResult] =
    useTestOnlyPresentPaymentMethodMutation();

  if (IsLiveEnvironment() || !pi || !pi.stripe_id) {
    return <></>;
  }
  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        disabled={presentPaymentMethodResult.isLoading}
        onClick={() => presentPaymentMethod("acceptCharge")}
      >
        <FormattedMessage id="simulate-payment" />
      </Button>
      <Button
        variant="contained"
        color="secondary"
        disabled={presentPaymentMethodResult.isLoading}
        onClick={() => presentPaymentMethod("declineCharge")}
      >
        <FormattedMessage id="simulate-decline" />
      </Button>
    </>
  );
};
