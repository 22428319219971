import {
  Alert,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import TransactionVehicleInfo from "pages/transactions/components/TransactionVehicleInfo";
import { FormattedMessage } from "react-intl";
import { ChargingStation } from "service/api-slice";
import ChargingStationStatusIndicator from "./ChargingStationStatusIndicator";
import PumpStateChip from "./PumpStateChip";
import TemperaturesChip from "./TemperaturesChip";

const OccupiedChargingStationTableRow = ({
  chargingStation,
  onClick,
}: {
  chargingStation: ChargingStation;
  onClick: (cs: ChargingStation) => void;
}) => {
  const lastMeterValues = chargingStation.current_transaction?.last_meter_values;

  return (
    <TableRow hover={true} sx={{ cursor: "pointer" }} onClick={() => onClick(chargingStation)}>
      <TableCell>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Typography variant="h5">{chargingStation.name}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" justifyContent="flex-end">
              <ChargingStationStatusIndicator chargingStation={chargingStation} />
            </Box>
          </Grid>
          {chargingStation.current_transaction && (
            <Grid item xs={12}>
              <TransactionVehicleInfo transaction={chargingStation.current_transaction} />
            </Grid>
          )}
          <Grid item xs={6}>
            {lastMeterValues?.soc ? (
              <Typography variant="body1">
                {lastMeterValues?.time_remaining !== 600 ? (
                  <FormattedMessage
                    id="soc-time-remaining"
                    values={{
                      soc: lastMeterValues?.soc,
                      time: lastMeterValues?.time_remaining,
                    }}
                  />
                ) : (
                  <FormattedMessage id="soc-value" values={{ soc: lastMeterValues?.soc }} />
                )}
              </Typography>
            ) : (
              <Typography variant="body1">{"-"}</Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <TemperaturesChip tempN={lastMeterValues?.temp_n} tempP={lastMeterValues?.temp_p} />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {lastMeterValues?.power ? <>{lastMeterValues?.power / 1000}kW</> : <>{"-"}</>}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <PumpStateChip cooling={chargingStation.pump_state} />
          </Grid>
          {chargingStation.status?.errors?.map((error) => (
            <Grid item key={`${chargingStation.id}-error-${error.code}`} xs={12}>
              <Alert severity="warning">
                ERR{error.code} : {error.name} - {error.common_causes.join("; ")}
              </Alert>
            </Grid>
          ))}
        </Grid>
      </TableCell>
    </TableRow>
  );
};

const OccupiedChargingStationsMobile = ({
  chargingStations,
  onClick,
}: {
  chargingStations: ChargingStation[];
  onClick: (cs: ChargingStation) => void;
  siteId?: string | null;
}) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {chargingStations.map((chargingStation) => (
            <OccupiedChargingStationTableRow
              key={chargingStation.id}
              chargingStation={chargingStation}
              onClick={onClick}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OccupiedChargingStationsMobile;
