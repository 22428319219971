import Grid from "@mui/material/Grid";
import MainCard from "components/MainCard";
import EnergyUsedChart from "components/charts/EnergyUsed";
import TotalSessionsChart from "components/charts/TotalSessions";
import { useIntl } from "react-intl";

import { useNavigateWithModule } from "hooks/useNavigateWithModule";
import { ActiveTransactionsTable } from "pages/transactions/components/ActiveTransactionsTable";
import { TransactionsTable } from "pages/transactions/components/TransactionsTable";
import { useMemo } from "react";
import { Transaction, useGetFleetCurrentTransactionsQuery } from "service/api-slice";
import { useModuleContext } from "types/context";

const DefaultDashboard = () => {
  const { filter, module } = useModuleContext();
  const navigate = useNavigateWithModule(module);
  const sessionArgs = useMemo(() => {
    return {
      start: filter.timeRange.start.toISOString(),
      end: filter.timeRange.end.toISOString(),
      count: 25,
      page: 0,
      siteId: filter.siteId,
      new: true,
      module: module,
    };
  }, [filter, module]);
  const { data, isLoading, isError } = useGetFleetCurrentTransactionsQuery(undefined, {
    pollingInterval: 15_000,
  });

  const intl = useIntl();

  function navigateToTransaction(transaction: Transaction) {
    console.log(transaction);
    navigate(`charging-sessions/${transaction.charging_station.id}/transactions/${transaction.id}`);
  }

  return (
    <Grid container spacing={3}>
      {/* row 1 */}
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TotalSessionsChart height={160} sessionArgs={sessionArgs} />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <EnergyUsedChart height={160} sessionArgs={sessionArgs} />
      </Grid>

      {/* row 2 */}
      {data && data.data.length > 0 && (
        <Grid item xs={12} md={12} lg={12}>
          <MainCard content={false} title={intl.formatMessage({ id: "active-charging-sessions" })}>
            <ActiveTransactionsTable data={data} isLoading={isLoading} isError={isError} />
          </MainCard>
        </Grid>
      )}

      {/* row 3 */}
      <Grid item xs={12} md={12} lg={12}>
        <MainCard content={false} title={intl.formatMessage({ id: "charging-sessions" })}>
          <TransactionsTable
            startTime={filter.timeRange.start}
            endTime={filter.timeRange.end}
            onClick={navigateToTransaction}
          />
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default DefaultDashboard;
