import { DownOutlined, RightOutlined } from "@ant-design/icons";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import JsonViewer from "components/JsonViewer";
import TableNoResults from "components/TableNoResults";
import TablePagination from "components/pagination/table-pagination";
import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { GetOcppMessagesArg, OcppMessage, useGetOcppMessagesQuery } from "service/api-slice";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";

const columnHelper = createColumnHelper<OcppMessage>();

const columns = [
  columnHelper.display({
    id: "expander",
    header: () => null,
    cell: ({ row }) => {
      return row.getCanExpand() ? (
        <IconButton aria-label="expand row" size="small" onClick={row.getToggleExpandedHandler()}>
          {row.getIsExpanded() ? <DownOutlined /> : <RightOutlined />}
        </IconButton>
      ) : (
        <></>
      );
    },
  }),
  columnHelper.accessor("created_at", {
    cell: (i) => parseAndFormatDate(i.getValue()),
    header: () => <FormattedMessage id="message-time" />,
  }),
  columnHelper.accessor("unique_id", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="unique-id" />,
  }),
  columnHelper.accessor("message_type", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="message-type" />,
  }),
  columnHelper.accessor("origin", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="origin" />,
  }),
  columnHelper.accessor("action", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="action" />,
  }),
];

export const OcppMessagesTable = ({
  chargingStationdId,
  range,
}: {
  chargingStationdId: string;
  range?: { start: string; end: string } | undefined;
}) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });

  const [queryArgs, setQueryArgs] = useState<GetOcppMessagesArg>({
    cpId: chargingStationdId,
    count: pageSize,
    page: pageIndex,
    range: range,
  });

  const { data, isLoading, isError } = useGetOcppMessagesQuery(queryArgs);

  const pagination = useMemo(() => {
    setQueryArgs({
      page: pageIndex,
      count: pageSize,
      cpId: chargingStationdId,
      range: range,
    });
    return {
      pageIndex,
      pageSize,
    };
  }, [chargingStationdId, pageIndex, pageSize, range]);

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    pageCount: data?.meta.pagination.page_count ?? -1,
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand: (row) => row.original.payload,
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <TableContainer>
      <Table aria-label="OCPP Messages Table">
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableCell key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>
        <TableBody>
          {(isLoading || isError) &&
            table.getHeaderGroups().map((headerGroup) => {
              return [...Array(10).keys()].map((i) => (
                <TableRow key={i}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableCell key={`${i}-${header.id}`} colSpan={header.colSpan}>
                        <Skeleton />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ));
            })}
          {table.getRowModel().rows.map((row) => {
            return (
              <>
                <TableRow key={row.id} hover={true}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <TableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
                {row.getIsExpanded() && (
                  <TableRow>
                    <TableCell sx={{ p: 0 }} colSpan={row.getVisibleCells().length}>
                      <JsonViewer json={row.original.payload} />
                    </TableCell>
                  </TableRow>
                )}
              </>
            );
          })}
          {table.getRowModel().rows.length === 0 && (
            <TableNoResults columnCount={table.getAllLeafColumns().length} />
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell sx={{ p: 2 }} colSpan={table.getAllLeafColumns().length}>
              <TablePagination
                gotoPage={table.setPageIndex}
                totalPages={table.getPageCount()}
                setPageSize={table.setPageSize}
                pageIndex={pageIndex}
                pageSize={pageSize}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
