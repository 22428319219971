import MainCard from "components/MainCard";
import { useModuleContext } from "types/context";
import { Module } from "types/modules";
import CreateVehicleComponent from "./components/CreateVehicle";
import { VehicleTable } from "./components/VehicleTable";

const VehiclesList = () => {
  const { module } = useModuleContext();
  return (
    <>
      {module === Module.SiteOperator && (
        <MainCard sx={{ mb: 2 }}>
          <CreateVehicleComponent onCreated={(v) => console.log(v)} />
        </MainCard>
      )}
      <MainCard content={false}>
        <VehicleTable />
      </MainCard>
    </>
  );
};

export default VehiclesList;
