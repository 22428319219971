import { useEffect, useState } from "react";

const useUserMedia = (constraints: MediaStreamConstraints) => {
  const [stream, setStream] = useState<MediaStream | null>(null);

  useEffect(() => {
    const enableStream = async () => {
      try {
        const userStream = await navigator.mediaDevices.getUserMedia(constraints);
        setStream(userStream);
      } catch (err) {
        console.error(err);
      }
    };

    if (!stream) {
      enableStream();
    } else {
      return () => {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      };
    }
  }, [constraints, stream, setStream]);

  return stream;
};

export default useUserMedia;
