import { Table, TableBody, TableCell, TableFooter, TableRow } from "@mui/material";
import Link from "components/Link";
import UploadImage from "pages/transactions/components/UploadImage";
import { FormattedMessage } from "react-intl";
import { Vehicle } from "service/api-slice";
import { useModuleContext } from "types/context";
import { Module } from "types/modules";

const VehicleDetails = ({
  vehicle,
  chargingStationId,
  transactionId,
  onUploaded,
}: {
  vehicle?: Vehicle | null;
  chargingStationId: string;
  transactionId: string;
  onUploaded: () => void;
}) => {
  const { module } = useModuleContext();
  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell colSpan={2}>
            <Link to={"/vehicles/" + vehicle?.id}>
              {vehicle?.description ?? vehicle?.mac_address ?? "unknown>"}
            </Link>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <FormattedMessage id="owner" />
          </TableCell>
          <TableCell>{vehicle?.owner?.display_name ?? "-"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <FormattedMessage id="vin" />
          </TableCell>
          <TableCell>{vehicle?.vin ?? "-"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <FormattedMessage id="license-plate" />
          </TableCell>
          <TableCell>
            {[vehicle?.license_plate_state, vehicle?.license_plate]
              .filter((part) => part)
              .join(" ")
              .trim() || "-"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <FormattedMessage id="mac-address" />
          </TableCell>
          <TableCell>{vehicle?.mac_address ?? "-"}</TableCell>
        </TableRow>
      </TableBody>
      {vehicle && !vehicle.vin && module === Module.SiteOperator && (
        <TableFooter>
          <TableRow>
            <TableCell colSpan={2}>
              <UploadImage
                chargingStationId={chargingStationId}
                transactionId={transactionId}
                vehicleId={vehicle.id}
                onUploaded={onUploaded}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};

export default VehicleDetails;
