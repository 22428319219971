import { DeleteOutlined } from "@ant-design/icons";
import { LoadingButton } from "@mui/lab";
import {
  IconButton,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import MainCard from "components/MainCard";
import { SyntheticEvent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  useDeleteParkingScheduleOverrideMutation,
  useGetParkingScheduleOverrideQuery,
  useUpdateParkingScheduleOverrideMutation,
} from "service/api-slice";
import { format, parse } from "date-fns";
import { useDispatch } from "react-redux";
import { openSnackbarError, openSnackbarSuccess } from "store/reducers/snackbar";

const reformatDate = (date: string) => {
  const d = parse(date, "yyyy-MM-dd", new Date());
  return format(d, "MM/dd/yyyy");
};

const SpecificDates = ({ siteId }: { siteId: string }) => {
  const overrideQuery = useGetParkingScheduleOverrideQuery({
    site_id: siteId,
    // TODO(GRV-193): choose timebounds based on the current day, or let the user pick.
    start_date: "2024-01-01",
    end_date: "2024-12-24",
  });
  const [updateOverride, updateOverrideResult] = useUpdateParkingScheduleOverrideMutation();
  const [deleteOverride, deleteOverrideResult] = useDeleteParkingScheduleOverrideMutation();

  const dispatch = useDispatch();

  const [date, setDate] = useState<Date | null>();
  const [maxParked, setMaxParked] = useState<string>();
  const onAddClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (!date || !maxParked) {
      dispatch(
        openSnackbarError({
          message: "Both date and slots must be set.",
        })
      );
      return;
    }
    updateOverride({
      site_id: siteId,
      date: format(date, "yyyy-MM-dd"),
      max_parked: parseInt(maxParked),
    });
  };
  const onDeleteClick = (e: SyntheticEvent, date: string) => {
    e.stopPropagation();
    deleteOverride({ site_id: siteId, date: date });
  };

  useEffect(() => {
    if (deleteOverrideResult.isSuccess) {
      dispatch(
        openSnackbarSuccess({
          messagE: "Override deleted.",
        })
      );
      overrideQuery.refetch();
      deleteOverrideResult.reset();
    }
    if (deleteOverrideResult.isError) {
      dispatch(
        openSnackbarError({
          title: "Failed to delete override",
          message: JSON.stringify(deleteOverrideResult.error),
        })
      );
      overrideQuery.refetch();
      deleteOverrideResult.reset();
    }
  }, [dispatch, overrideQuery, deleteOverrideResult]);

  useEffect(() => {
    if (updateOverrideResult.isSuccess) {
      dispatch(
        openSnackbarSuccess({
          message: "Override updated.",
        })
      );
      overrideQuery.refetch();
      updateOverrideResult.reset();
    }
    if (updateOverrideResult.isError) {
      dispatch(
        openSnackbarError({
          title: "Failed to update override",
          message: JSON.stringify(updateOverrideResult.error),
        })
      );
      overrideQuery.refetch();
      updateOverrideResult.reset();
    }
  }, [dispatch, overrideQuery, updateOverrideResult]);

  return (
    <MainCard title={<FormattedMessage id="specific-dates" />} content={false}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="date" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="slots" />
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {overrideQuery.isSuccess &&
              overrideQuery.data.data.schedule?.map((date, index) => (
                <TableRow key={index}>
                  <TableCell>{reformatDate(date.date)}</TableCell>
                  <TableCell>{date.max_parked}</TableCell>
                  <TableCell>
                    <IconButton color="error" onClick={(e) => onDeleteClick(e, date.date)}>
                      <DeleteOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            <TableRow>
              <TableCell>
                <DatePicker onChange={setDate} />
              </TableCell>
              <TableCell>
                <OutlinedInput
                  onChange={(e) => setMaxParked(e.target.value)}
                  error={!/\d+/.test(maxParked ?? "")}
                />
              </TableCell>
              <TableCell>
                <LoadingButton
                  disabled={date === null || !/\d+/.test(maxParked ?? "")}
                  loading={updateOverrideResult.isLoading}
                  variant="contained"
                  onClick={onAddClick}
                >
                  <FormattedMessage id="add" />
                </LoadingButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default SpecificDates;
