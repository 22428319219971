import { RightOutlined } from "@ant-design/icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  styled,
  Table,
  TableBody,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import TableNoResults from "components/TableNoResults";
import { formatDuration, formatRFC3339, intervalToDuration, parseISO, subHours } from "date-fns";
import { ReactNode, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { OcppEvent, OcppEventType, useGetOcppConnectionsQuery } from "service/api-slice";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";
import OcppEventsTable from "./OcppEventsTable";
import TraceID from "./TraceID";

const ListItem = styled("li")(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const OcppConnectionsList = ({
  csId,
  range,
}: {
  csId: string;
  range: { start: Date; end: Date };
}) => {
  const { data, isLoading } = useGetOcppConnectionsQuery(
    {
      csId,
      range: {
        start: formatRFC3339(range.start),
        end: formatRFC3339(range.end),
      },
    },
    {
      pollingInterval: 10_000,
    }
  );

  const startedConnections = data?.data?.filter(
    (c: OcppEvent) => c.type === "http_connection_started"
  ) as OcppEventType<"http_connection_started">[];

  const endedConnections = data?.data?.filter(
    (c: OcppEvent) => c.type === "http_connection_ended"
  ) as OcppEventType<"http_connection_ended">[];

  return (
    <div>
      {isLoading && <FormattedMessage id="loading" />}
      {(!startedConnections || startedConnections.length === 0) && (
        <Table>
          <TableBody>
            <TableNoResults columnCount={1} />
          </TableBody>
        </Table>
      )}
      {startedConnections?.map((startedEvent) => {
        const startTime = parseISO(startedEvent.timestamp);
        const endedEvent = endedConnections?.find((ec) => ec.trace_id === startedEvent.trace_id);
        const endTime = endedEvent ? parseISO(endedEvent.timestamp) : undefined;

        return (
          <Accordion key={startedEvent.trace_id} slotProps={{ transition: { mountOnEnter: true } }}>
            <AccordionSummary expandIcon={<RightOutlined />}>
              <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Typography variant="h5">
                    {parseAndFormatDate(startedEvent.timestamp)}
                    {endedEvent && <> - {parseAndFormatDate(endedEvent.timestamp)}</>}
                  </Typography>

                  <DurationChip start={startTime} end={endTime} />
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    listStyle: "none",
                    p: 0,
                    m: 0,
                  }}
                  component="ul"
                >
                  <ListItem>
                    <LabeledChip label="endpoint" value={startedEvent.data?.url} />
                  </ListItem>
                  <ListItem>
                    <LabeledChip label="instance" value={startedEvent.data?.instance} />
                  </ListItem>
                  <ListItem>
                    <LabeledChip
                      label="trace_id"
                      value={<TraceID traceId={startedEvent.trace_id} />}
                    />
                  </ListItem>
                </Box>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <OcppEventsTable
                csId={csId}
                range={{
                  start: endTime ? subHours(endTime, 1) : subHours(new Date(), 1),
                  end: endTime ?? new Date(),
                }}
                traceId={startedEvent.trace_id}
                allowTailing={endedEvent === undefined}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

const DurationChip = ({ start, end }: { start: Date; end?: Date }) => {
  const zeroPad = (num: number) => String(num).padStart(2, "0");
  const [now, setNow] = useState(end ?? new Date());

  const duration = formatDuration(
    intervalToDuration({
      start: start,
      end: now,
    }),
    {
      format: ["days", "hours", "minutes", "seconds"],
      zero: true,
      delimiter: "",
      locale: {
        formatDistance: (_token, count) => {
          if (_token === "xDays") {
            return count > 0 ? `${count}d ` : "";
          }
          if (_token === "xHours" || _token === "xMinutes") {
            return `${zeroPad(count)}:`;
          }
          return zeroPad(count);
        },
      },
    }
  );

  // update every second
  useEffect(() => {
    if (end) return;
    const interval = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, [end]);

  return <LabeledChip value={duration} color={end ? "secondary" : "info"} />;
};

const LabeledChip = ({
  label,
  value,
  size = "small",
  color = "default",
}: {
  label?: string;
  size?: "small" | "medium";
  value: ReactNode;
  color?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
}) => {
  return (
    <Chip
      color={color}
      label={
        <>
          {label && <strong>{label}:</strong>}
          {value}
        </>
      }
      size={size}
      sx={{ fontFamily: "monospace" }}
    />
  );
};

export default OcppConnectionsList;
