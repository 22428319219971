import { createRoot } from "react-dom/client";
import { BrowserRouter, useNavigate } from "react-router-dom";

// third-party
import { Provider as ReduxProvider } from "react-redux";

// scroll bar
import "simplebar/src/simplebar.css";

// apex-chart
import "assets/third-party/apex-chart.css";
import "assets/third-party/react-table.css";

// skeleton
import "react-loading-skeleton/dist/skeleton.css";

// project import
import { ConfigProvider } from "contexts/ConfigContext";
import { store } from "store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { AppState, Auth0Context, Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import { sec } from "utils/security";

import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { PropsWithChildren, StrictMode } from "react";

import "main.css";

import "assets/css/main.css";

const container = document.getElementById("root");
const root = createRoot(container!);

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: PropsWithChildren<Auth0ProviderOptions>) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

root.render(
  <ReduxProvider store={store}>
    <ConfigProvider>
      <BrowserRouter>
        <Auth0ProviderWithRedirectCallback
          domain={process.env.REACT_APP_AUTH0_DOMAIN!!}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!!}
          redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URL!!}
          audience={process.env.REACT_APP_AUTH0_AUDIENCE!!}
          scope="admin read:charge_points manage:charge_points"
        >
          <Auth0Context.Consumer>
            {({ getAccessTokenSilently }) => {
              // expose the getAccessTokenSilently to non-react components, ie. RTK slices
              sec.setAccessTokenSilently(getAccessTokenSilently);
              return (
                <PrimeReactProvider>
                  <StrictMode>
                    <App />
                  </StrictMode>
                </PrimeReactProvider>
              );
            }}
          </Auth0Context.Consumer>
        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
    </ConfigProvider>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
