export function nearestDate(dates: Date[], target: Date): number {
  const targetTime = target.getTime();

  let nearest = Infinity;
  let winner = -1;

  dates.forEach((date, index) => {
    const time = date.getTime();
    const distance = Math.abs(time - targetTime);
    if (distance < nearest) {
      nearest = distance;
      winner = index;
    }
  });

  return winner;
}
