import { Badge, Chip } from "@mui/material";
import { getAuthorization, useIsInPreauthExperiment } from "components/payments/preauthorization";
import { ChargingStation } from "service/api-slice";

export const ChargingStationStatusIndicator = (props: { chargingStation: ChargingStation }) => {
  const isInPreauthExperiment = useIsInPreauthExperiment();

  type Color = "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";

  var chipColor: Color = "default";
  var badgeColor: Color = "default";
  var badgeContent: string = "";
  var showBadge = false;

  const status = chargingStationStatus(props.chargingStation);

  switch (status) {
    case "Available":
      chipColor = "primary";
      break;
    case "Occupied": {
      chipColor = "primary";
      const errorCount = props.chargingStation.status?.errors?.length;
      if (errorCount && errorCount > 0) {
        chipColor = "warning";
      }
      if (
        isInPreauthExperiment(props.chargingStation) &&
        !getAuthorization(props.chargingStation)
      ) {
        badgeColor = "warning";
        badgeContent = "!";
        showBadge = true;
      }
      break;
    }
    case "Charging":
      chipColor = "success";
      break;
    case "Faulted":
      chipColor = "error";
      break;
    case "Offline":
      chipColor = "warning";
      break;
    case "Unavailable":
      chipColor = "info";
      break;
    case "Disabled":
      chipColor = "default";
      badgeColor = "error";
      badgeContent = "x";
      showBadge = true;
      break;
  }

  return (
    <Badge color={badgeColor} badgeContent={badgeContent} invisible={!showBadge}>
      <Chip label={status} size="small" color={chipColor} />
    </Badge>
  );
};

export default ChargingStationStatusIndicator;

export function chargingStationStatus(cs: ChargingStation): string {
  var status = cs.current_transaction?.charging_state ?? cs.status?.status ?? "Unknown";

  if (cs.status?.disabled) {
    status = "Disabled";
  }

  return status;
}
