import { useTheme } from "@mui/material/styles";
import AnalyticsDataCard from "components/cards/statistics/AnalyticsDataCard";
import useConfig from "hooks/useConfig";
import { ReactNode, useMemo } from "react";
import ReactApexChart, { Props as ChartProps } from "react-apexcharts";
import {
  GetTransactionFailuresArgs,
  TransactionFailuresData,
  useGetTransactionFailuresQuery,
} from "service/api-slice";

// ==============================|| USER CARD CHART ||============================== //

const SessionFailuresByType = (props: {
  height: number | null;
  args: GetTransactionFailuresArgs;
  data?: TransactionFailuresData[];
  buttonText?: ReactNode;
  buttonClick?: () => void;
}) => {
  const theme = useTheme();
  const { mode } = useConfig();

  const { data } = useGetTransactionFailuresQuery(
    { ...props.args },
    {
      pollingInterval: 60_000,
      skipPollingIfUnfocused: true,
      skip: props.data !== undefined,
    }
  );

  const seriesData = useMemo(() => {
    return props.data ? props.data : data?.data;
  }, [props.data, data]);

  const count = useMemo(() => {
    if (!seriesData) {
      return 0;
    }

    return seriesData.map((f) => f.failure_count).reduce((acc, v) => acc + v);
  }, [seriesData]);

  const totalSessions = useMemo(() => {
    if (!seriesData) {
      return 0;
    }

    return seriesData.map((f) => f.total).reduce((acc, v) => acc + v);
  }, [seriesData]);

  const percentage = useMemo(() => {
    if (!data || totalSessions === 0 || count === 0) {
      return;
    }

    return parseFloat(((count / totalSessions) * 100).toFixed(2));
  }, [data, count, totalSessions]);

  const series = useMemo(() => {
    if (!seriesData) {
      return [
        {
          name: "Session Failures",
          data: [{ x: "0", y: 0 }],
        },
      ];
    }

    var tmpSeries: {
      name: string;
      data: {
        x: string;
        y: number;
      }[];
    }[] = [];

    tmpSeries.push({
      name: "Bad error codes",
      data: seriesData.map((f) => {
        return { x: f.date, y: f.bad_error_code_count };
      }),
    });
    tmpSeries.push({
      name: "Low power sessions",
      data: seriesData.map((f) => {
        return { x: f.date, y: f.low_power_count };
      }),
    });
    tmpSeries.push({
      name: "Short duration sessions",
      data: seriesData.map((f) => {
        return { x: f.date, y: f.short_duration_count };
      }),
    });
    return tmpSeries;
  }, [seriesData]);

  // chart options
  const areaChartOptions = useMemo<ChartProps>(() => {
    return {
      colors: [
        theme.palette.primary.main,
        theme.palette.primary[700],
        theme.palette.secondary.main,
      ],
      chart: {
        id: "failures-chart",
        height: 100,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: "numeric",
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        tooltip: {
          enabled: false,
        },
        labels: {
          show: true,
          formatter: (value: number) => {
            let date = new Date(value);
            return `${date.getMonth() + 1}/${date.getDate()}`;
          },
        },
      },
      yaxis: {
        show: false,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      tooltip: {
        theme: mode === "dark" ? "dark" : "light",
        x: {
          show: true,
        },
        y: {
          formatter: (value: number) => {
            return value.toFixed(0);
          },
        },
      },
      grid: {
        show: false,
      },
    };
  }, [mode, theme]);

  return (
    <AnalyticsDataCard
      title="session-failures-chart-label"
      count={`${count} Failures`}
      percentage={percentage}
      color="secondary"
      buttonClick={props.buttonClick}
      buttonText={props.buttonText}
    >
      <ReactApexChart
        options={areaChartOptions}
        series={series}
        type="bar"
        height={props?.height?.toString()}
      />
    </AnalyticsDataCard>
  );
};

export default SessionFailuresByType;
