import { DownloadOutlined } from "@ant-design/icons";
import { LoadingButton } from "@mui/lab";
import useApiDownload from "hooks/useDownload";
import { FormattedMessage } from "react-intl";

const DownloadTransactionsButton = (props: {
  startTime: Date;
  endTime: Date;
  siteId?: string | null;
  orgId?: string | null;
  vehicleId?: string | null;
  makeId?: string | null;
  modelId?: string | null;
  vpicId?: string | null;
}) => {
  const { download, isLoading, ref } = useApiDownload();
  const url =
    `transactions-download?start=${props.startTime.toISOString()}&end=${props.endTime.toISOString()}` +
    (props.orgId ? `&org_id=${props.orgId}` : "") +
    (props.vehicleId ? `&vehicle_id=${props.vehicleId}` : "") +
    (props.makeId ? `&make_id=${props.makeId}` : "") +
    (props.modelId ? `&model_id=${props.modelId}` : "") +
    (props.siteId ? `&site_id=${props.siteId}` : "") +
    (props.vpicId ? `&vpic_id=${props.vpicId}` : "");
  return (
    <>
      <LoadingButton
        onClick={() => download(url)}
        loading={isLoading}
        startIcon={<DownloadOutlined />}
      >
        <FormattedMessage id="download" />
      </LoadingButton>
      <a href="#download" style={{ display: "none" }} ref={ref}>
        download
      </a>
    </>
  );
};

export default DownloadTransactionsButton;
