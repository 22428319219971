import { ReactNode, createContext, useEffect, useRef, useState } from "react";
import { ConstantBackoff, Websocket, WebsocketBuilder } from "utils/ws";
import { AuthConfig } from "utils/ws/auth/authConfig";

export interface WebsocketContextProps {
  isConnected?: boolean;
  message?: any;
}

export const WebsocketContext = createContext<WebsocketContextProps>({});

export type WebsocketProviderProps = {
  url: string;
  children: ReactNode;
  authConfig?: AuthConfig;
};

export const WebsocketProvider = (props: WebsocketProviderProps) => {
  const [isConnected, setIsConnected] = useState(false);
  const [message, setMessage] = useState(null);

  const ws = useRef<Websocket | null>(null);

  useEffect(() => {
    console.log("setting up ws connectionn: " + props.url);
    const wsBuilder = new WebsocketBuilder(props.url)
      .onOpen((ws, ev) => setIsConnected(true))
      .onClose((ws, ev) => setIsConnected(false))
      .onMessage((ws, ev) => {
        setMessage(JSON.parse(ev.data));
      })
      .onError((ws, ev) => console.log(`error: ${ev}`))
      .onRetry((ws, ev) =>
        console.log(`retry attempt ${ev.detail.retries} in ${ev.detail.backoff}ms`)
      )
      .withBackoff(new ConstantBackoff(5000, /*maxRetries=*/ 10));

    if (props.authConfig) {
      wsBuilder.withAuthConfig(props.authConfig);
    }

    ws.current = wsBuilder.build();
    ws.current.connect();

    return () => {
      console.log(`closing connection to ${props.url}`);
      ws.current?.close();
    };
  }, [props.url, props.authConfig]);

  const context = { isConnected, message };

  return <WebsocketContext.Provider value={context}>{props.children}</WebsocketContext.Provider>;
};
