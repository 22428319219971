import { useTheme } from "@mui/material/styles";
import MainCard from "components/MainCard";
import { WebsocketContext } from "contexts/WebsocketContext";
import { parseISO } from "date-fns";
import { format } from "date-fns-tz";
import { useContext, useEffect, useState } from "react";
import ReactApexChart, { Props as ChartProps } from "react-apexcharts";
import { useGetTransactionChartDataQuery } from "service/api-slice";
import { Message, MeterValuesMessage } from "service/monitor/msg/Message";
import { nearestDate } from "utils/datetime/nearestDate";

export type DemandVsActualChartProps = {
  chargingStationId: string;
  transactionId: string;
  height?: number;
};

export const DemandVsActualChart = ({
  chargingStationId,
  transactionId,
  height = 320,
}: DemandVsActualChartProps) => {
  const theme = useTheme();
  const wsContext = useContext(WebsocketContext);
  const { data, isSuccess } = useGetTransactionChartDataQuery({
    cpId: chargingStationId,
    txId: transactionId,
  });
  const [series, setSeries] = useState<
    {
      name: string;
      data: { x: Date; y: number }[];
    }[]
  >([]);
  const [options, setOptions] = useState<ChartProps>({
    chart: {
      id: "demand-vs-actual-chart",
      type: "line",
      // group: groupId,
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: "demand-vs-actual-chart",
            columnDelimiter: ",",
            headerCategory: "Date",
            headerValue: "Temperature",
            dateFormatter(timestamp: number) {
              return format(timestamp, "yyyy-MM-dd HH:mm:ss zzz");
            },
          },
        },
      },
      animations: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      width: [2, 2, 2, 2],
      curve: "straight",
      dashArray: [2, 0, 2, 0],
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.primary.dark,
      theme.palette.secondary.main,
      theme.palette.secondary.dark,
    ],
    xaxis: {
      type: "datetime",
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: [
      {
        seriesName: "Current",
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: (value: any) => {
            return `${value} A`;
          },
        },
      },
      {
        seriesName: "Current",
        show: false,
        labels: {
          show: true,
          formatter: (value: any) => {
            return `${value} A`;
          },
        },
      },
      {
        seriesName: "Voltage",
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: (value: any) => {
            return `${value} V`;
          },
        },
      },
      {
        seriesName: "Voltage",
        show: false,
        labels: {
          show: true,
          formatter: (value: any) => {
            return `${value} V`;
          },
        },
      },
    ],
    grid: {
      show: true,
    },
  });

  useEffect(() => {
    if (data) {
      const dates = data?.dates.map((d) => parseISO(d));
      setSeries([
        {
          name: "Current Demand",
          data: data.current_demand.map((v, i) => ({ x: dates[i], y: v })),
        },
        {
          name: "Current",
          data: data.current.map((v, i) => ({ x: dates[i], y: v })),
        },
        {
          name: "Voltage Demand",
          data: data.voltage_demand.map((v, i) => ({ x: dates[i], y: v })),
        },
        {
          name: "Voltage",
          data: data.voltage.map((v, i) => ({ x: dates[i], y: v })),
        },
      ]);

      setOptions((prevState) => ({
        ...prevState,
        xaxis: {
          type: "datetime",
          labels: {
            formatter: (value: string, timestamp: number) => {
              let idx = nearestDate(dates, new Date(timestamp));
              if (!idx) {
                idx = data?.soc.findIndex((v) => v > 0);
              }
              if (idx) {
                let soc: number | undefined = data?.soc[idx];
                if (soc) {
                  return [format(timestamp, "h:mm a"), `${soc}%`];
                }
              }
              return [format(timestamp, "h:mm a")];
            },
          },
        },
      }));
    }
  }, [data]);

  useEffect(() => {
    if (!isSuccess) return;

    if (wsContext.message) {
      const msg = wsContext.message as Message<any>;
      switch (msg.type) {
        case "MeterValues":
          processMeterValues(msg as MeterValuesMessage);
          break;
      }
    }

    function processMeterValues(msg: MeterValuesMessage) {
      let current: number | undefined = undefined;
      let currentDemand: number | undefined = undefined;
      let voltage: number | undefined = undefined;
      let voltageDemand: number | undefined = undefined;

      msg.message.forEach((v) => {
        if (!v.value) return;
        switch (v.measurand) {
          case "Current.Import":
            current = v.value;
            break;
          case "Current.Demand":
            currentDemand = v.value;
            break;
          case "Voltage":
            voltage = v.value;
            break;
          case "Voltage.Demand":
            voltageDemand = v.value;
            break;
        }
      });

      if (current && currentDemand && voltage && voltageDemand) {
        setSeries((prevState) => {
          return [
            {
              name: "Current Demand",
              data: [...prevState[0].data, { x: parseISO(msg.timestamp), y: currentDemand! }],
            },
            {
              name: "Current",
              data: [...prevState[1].data, { x: parseISO(msg.timestamp), y: current! }],
            },
            {
              name: "Voltage Demand",
              data: [...prevState[2].data, { x: parseISO(msg.timestamp), y: voltageDemand! }],
            },
            {
              name: "Voltage",
              data: [...prevState[3].data, { x: parseISO(msg.timestamp), y: voltage! }],
            },
          ];
        });
      }
    }
  }, [wsContext.message, isSuccess]);

  return (
    <MainCard content={false} sx={{ height: "100%" }} title={"Demand vs. Actual"}>
      <ReactApexChart options={options} series={series} type="line" height={height} />
    </MainCard>
  );
};
