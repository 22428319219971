import { Chip, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { DetectionResult, LicensePlateEntity, VinEntity } from "service/api-slice";

export const VinDetection = ({ detection }: { detection?: DetectionResult<VinEntity> | null }) => {
  return (
    <Typography variant="h5">
      {detection?.entity?.make ? (
        <span>
          {detection.entity.year} {detection.entity.make} {detection.entity.model}
        </span>
      ) : (
        <em style={{ fontWeight: "lighter" }}>
          <FormattedMessage id="no-vin-detected" />
        </em>
      )}
    </Typography>
  );
};

export const LicensePlateDetection = ({
  detection,
}: {
  detection?: DetectionResult<LicensePlateEntity> | null;
}) => {
  return (
    <Typography variant="h5">
      {detection?.entity ? (
        <span>
          {detection.entity.tlc ? (
            <Chip label="NYC TLC" size="small" style={{ marginRight: "0.5rem" }} />
          ) : null}
          {detection.entity.state} {detection.entity.license_plate}
        </span>
      ) : (
        <em style={{ fontWeight: "lighter", color: "grey" }}>
          <FormattedMessage id="no-license-plate-detected" />
        </em>
      )}
    </Typography>
  );
};
