import { ChromeOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { NavItemType } from "types/menu";

export const AdminMenu: NavItemType = {
  id: "admin",
  title: <FormattedMessage id="admin" />,
  type: "group",
  children: [
    {
      id: "sites",
      title: <FormattedMessage id="sites" />,
      type: "item",
      url: "/admin/sites",
      icon: ChromeOutlined,
    },
    {
      id: "organizations",
      title: <FormattedMessage id="organizations" />,
      type: "item",
      url: "/admin/organizations",
      icon: ChromeOutlined,
    },
    {
      id: "users",
      title: <FormattedMessage id="users" />,
      type: "item",
      url: "/admin/users",
      icon: ChromeOutlined,
    },
  ],
};
