import { createBooleanFlag, createJsonFlag, createVariantFlag } from "./FeatureFlag";

type PreauthorizeChargingStation = {
  cs_ids: string[];
};

export const Flags = {
  CoolingV2: createBooleanFlag("cooling-v2", false),
  ChargingStationLogs: createBooleanFlag("charging-station-logs", false),
  EnableParking: createBooleanFlag("enable-parking", false),
  TxNotesTags: createBooleanFlag("tx-notes-tags", false),
  Language: createVariantFlag("language", "en", ["en", "es", "fr"]),
  VehicleImageUpload: createBooleanFlag("vehicle-image-upload", false),
  Playground: createBooleanFlag("playground", false),
  PreauthorizeChargingStation: createJsonFlag<PreauthorizeChargingStation>(
    "preauthorize-charging-station",
    { cs_ids: [] }
  ),
  EmployeeVerificationImages: createBooleanFlag("employee-verification-images", false),
} as const;
