import { Grid } from "@mui/material";
import { formatDuration, intervalToDuration } from "date-fns";
import { useMemo } from "react";
import {
  useGetChargingStationsUptimeQuery,
  useGetTransactionFailuresQuery,
  useGetTransactionStatsQuery,
} from "service/api-slice";
import { useModuleContext } from "types/context";
import { Module } from "types/modules";
import SingleMeterValue from "../../charging-stations/components/SingleMeterValue";

const TransactionsSummary = (props: {
  vehicleId?: string | null;
  makeId?: string | null;
  modelId?: string | null;
  vpicId?: string | null;
  chargingStationId?: string | null;
}) => {
  const { filter, module } = useModuleContext();
  const { data, isLoading } = useGetTransactionStatsQuery({
    start: filter.timeRange.start.toISOString(),
    end: filter.timeRange.end.toISOString(),
    orgId: filter.orgId,
    siteId: filter.siteId,
    chargingStationId: props.chargingStationId,
    vehicleId: props.vehicleId,
    makeId: props.makeId,
    modelId: props.modelId,
    vpicId: props.vpicId,
    module,
  });

  const { data: failures } = useGetTransactionFailuresQuery(
    {
      start: filter.timeRange.start.toISOString(),
      end: filter.timeRange.end.toISOString(),
      orgId: filter.orgId,
      siteId: filter.siteId,
      csId: props.chargingStationId,
      vehicleId: props.vehicleId,
      makeId: props.makeId,
      modelId: props.modelId,
      vpicId: props.vpicId,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    { skip: module !== Module.SiteOperator }
  );

  const { data: uptime } = useGetChargingStationsUptimeQuery(
    {
      startTime: filter.timeRange.start.toISOString(),
      endTime: filter.timeRange.end.toISOString(),
      orgId: filter.orgId,
      siteId: filter.siteId,
      csId: props.chargingStationId,
    },
    { skip: module !== Module.SiteOperator }
  );

  const uptimePercentage = useMemo(() => {
    if (!uptime) {
      return 1;
    }

    return (100 - (uptime.data.offline_duration / uptime.data.total_duration) * 100).toFixed(4);
  }, [uptime]);

  const failureRate = useMemo(() => {
    if (!failures) {
      return "-";
    }

    const count = failures?.data.reduce((acc, v) => acc + v.failure_count, 0);
    const total = failures?.data.reduce((acc, v) => acc + v.total, 0);

    if (total === 0) {
      return "-";
    }

    return `${((count / total) * 100).toFixed(2)}%`;
  }, [failures]);

  if (isLoading || !data?.data) {
    return <></>;
  }

  const values = data?.data;
  const totalSessions = values.total_sessions;
  const totalEnergy = values.total_energy / 1000;
  const totalCost = totalEnergy ? (totalEnergy * 0.59).toFixed(2) : 0.0;
  const avgSessionTime = intervalToDuration({ start: 0, end: values.average_duration * 1000 });
  const avgSessionEnergy = totalSessions > 0 ? totalEnergy / totalSessions : 0;
  const throughputUtilization = values.throughput_utilization;
  const timeUtilization = values.time_utilization;

  return (
    <>
      {module === Module.SiteOperator && (
        <Grid item xs={12} md={3} lg={3}>
          <SingleMeterValue
            title="total-cost"
            value={{
              value: `$${totalCost}`,
              caption: `${totalEnergy} kWh x $0.59/kWh`,
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} md={3} lg={3}>
        <SingleMeterValue title="total-sessions" value={{ value: `${totalSessions}` }} />
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <SingleMeterValue title="total-energy" value={{ value: `${totalEnergy} kWh` }} />
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <SingleMeterValue
          title="avg-energy-per-session"
          value={{ value: `${avgSessionEnergy.toFixed(3)} kWh` }}
        />
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <SingleMeterValue
          title="avg-session-time"
          value={{
            value: avgSessionTime?.seconds
              ? `${formatDuration(avgSessionTime, { format: ["hours", "minutes"] })}`
              : "-",
          }}
        />
      </Grid>
      {module === Module.SiteOperator && (
        <>
          <Grid item xs={12} md={3} lg={3}>
            <SingleMeterValue
              title="throughput_utilization"
              value={{
                value: `${throughputUtilization.toFixed(2)}%`,
                caption: `${timeUtilization.toFixed(2)}% time utilization`,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <SingleMeterValue
              title="failure-rate"
              value={{
                value: failureRate,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <SingleMeterValue
              title="uptime"
              value={{
                value: uptimePercentage ? `${uptimePercentage}%` : "-",
              }}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default TransactionsSummary;
