import { MoreOutlined } from "@ant-design/icons";
import {
  Autocomplete,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import MainCard from "components/MainCard";
import {
  FeatureFlag,
  isBooleanFeatureFlag,
  isVariantFeatureFlag,
} from "experimentation/FeatureFlag";
import { FeatureFlagContextProps, useFeatureFlags } from "experimentation/FeatureFlagProvider";
import { useEffect, useState } from "react";

const columnHelper = createColumnHelper<FeatureFlag>();
const columns = [
  columnHelper.accessor((row) => row.name + " " + row.definitionName, {
    id: "name",
    enableColumnFilter: true,
    header: () => "Flag",
    cell: (i) => {
      return (
        <>
          <Typography variant="body1">
            {i.row.original.definitionName}
            <br />
          </Typography>
          <Typography variant="body2">({i.row.original.name})</Typography>
        </>
      );
    },
  }),
  columnHelper.accessor("defaultValue", {
    header: () => "Default",
    cell: (i) =>
      `${
        !isBooleanFeatureFlag(i.row.original) && !isVariantFeatureFlag(i.row.original)
          ? JSON.stringify(i.getValue())
          : i.getValue()
      }`,
  }),
  columnHelper.accessor("serverValue", {
    header: () => "Server",
    cell: (i) =>
      `${
        i.getValue()
          ? !isBooleanFeatureFlag(i.row.original) && !isVariantFeatureFlag(i.row.original)
            ? JSON.stringify(i.getValue())
            : i.getValue()
          : "-"
      }`,
  }),
  columnHelper.accessor("overriddenValue", {
    header: () => "Override",
    cell: (i) => `${i.getValue() ?? "-"}`,
  }),
];

const FlagSwitch = (flag: FeatureFlag, experimentation: FeatureFlagContextProps) => {
  const enabled = experimentation.evaluateFeature(flag) === true;
  return (
    <Switch
      checked={enabled}
      onChange={(e) => {
        experimentation.overrideFeature(flag, e.target.checked);
      }}
    />
  );
};

const FlagSelectValue = (flag: FeatureFlag<string>) => {
  const experimentation = useFeatureFlags();
  return (
    <Autocomplete
      value={experimentation.evaluateFeature(flag)}
      disableClearable
      onChange={(event: any, newValue: string | null) => {
        experimentation.overrideFeature(flag, newValue!!);
      }}
      options={flag.values!!}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

const FeatureFlagList = ({ filter }: { filter?: string }) => {
  const experimentation = useFeatureFlags();

  const table = useReactTable({
    data: experimentation.featureFlags ?? [],
    columns,
    enableFilters: true,
    enableColumnFilters: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: "includesString",
  });

  useEffect(() => {
    table.setGlobalFilter(filter);
  }, [table, filter]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<string | undefined>(undefined);

  const handleClick = (event: React.MouseEvent<HTMLElement>, flag: FeatureFlag) => {
    setAnchorEl(event.currentTarget);
    setOpen(flag.name);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(undefined);
  };
  return (
    <MainCard content={false} sx={{ overflow: "hidden" }}>
      <TableContainer sx={{ height: { md: 400 }, width: { md: 650 } }}>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableCell>
                ))}
                <TableCell colSpan={2}></TableCell>
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
                <TableCell>
                  {isBooleanFeatureFlag(row.original) && FlagSwitch(row.original, experimentation)}
                  {isVariantFeatureFlag(row.original) &&
                    FlagSelectValue(row.original as FeatureFlag<string>)}
                </TableCell>
                <TableCell>
                  <IconButton
                    id={`${row.id}-overflow-button`}
                    onClick={(event) => {
                      handleClick(event, row.original);
                    }}
                  >
                    <MoreOutlined />
                  </IconButton>
                  <Menu
                    id={`${row.id}-overflow-menu`}
                    anchorEl={anchorEl}
                    open={open ? open === row.original.name : false}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => experimentation.overrideFeature(row.original, undefined)}
                    >
                      Reset
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default FeatureFlagList;
