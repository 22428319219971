import { Link } from "@mui/material";

const TraceID = ({ traceId }: { traceId: string }) => {
  return (
    <Link
      href={`https://console.cloud.google.com/logs/query;query=SEARCH%2528%22${traceId}%22%2529;project=${process.env.REACT_APP_GCP_PROJECT_ID}`}
      target="_blank"
      color="primary"
      rel="noreferrer"
    >
      {traceId}
    </Link>
  );
};

export default TraceID;
