import { useMediaQuery, useTheme } from "@mui/material";
import { ChargingStation } from "service/api-slice";
import OccupiedChargingStationsMobile from "./OccupiedChargingStationsMobile";
import OccupiedChargingStationsTable from "./OccupiedChargingStationsTable";

const OccupiedChargingStations = ({
  chargingStations,
  onClick,
}: {
  chargingStations: ChargingStation[];
  onClick: (cs: ChargingStation) => void;
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  if (isSmallScreen) {
    return <OccupiedChargingStationsMobile onClick={onClick} chargingStations={chargingStations} />;
  }

  return <OccupiedChargingStationsTable onClick={onClick} chargingStations={chargingStations} />;
};

export default OccupiedChargingStations;
