import PowerManagementPage from "modules/power-management/power/power-management";
import { RouteObject } from "react-router";
import { ModuleGuard } from "utils/route-guard/ModuleGuard";

export const PowerManagementRoutes: RouteObject = {
  element: <ModuleGuard modules={["power-management"]} />,
  path: "power-management",
  children: [
    {
      index: true,
      element: <PowerManagementPage />,
    },
  ],
};
