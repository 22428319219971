import { ReloadOutlined } from "@ant-design/icons";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { DetectionResult } from "service/api-slice";

const DetectionFormControl = ({
  name,
  showLabel = true,
  margin = "normal",
  required = false,
  detection,
  onSelectionChange,
  reload,
  loading = false,
}: {
  name: string;
  showLabel?: boolean;
  margin?: "dense" | "normal" | "none";
  required?: boolean;
  detection?: DetectionResult | null;
  onSelectionChange?: (value: string) => void;
  reload?: (value: string) => void;
  loading?: boolean;
}) => {
  const [detectedValue, setDetectedValue] = useState(
    detection?.corrected_text ?? detection?.text ?? ""
  );
  const isCorrected: boolean = !!detection?.corrected_text;

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(detectedValue);
    }
  }, [detectedValue, onSelectionChange]);

  return (
    <FormControl fullWidth margin={margin}>
      {showLabel && (
        <FormLabel>
          <FormattedMessage id={name} />
        </FormLabel>
      )}
      <OutlinedInput
        name={name}
        value={detectedValue}
        required={required}
        error={detection?.messages?.some((message) => message.level === "error")}
        onChange={(e) => setDetectedValue(e.target.value)}
        endAdornment={
          reload ? (
            <InputAdornment position="end">
              <IconButton disabled={loading} edge="end" onClick={() => reload(detectedValue)}>
                {loading ? <ProgressSpinner /> : <ReloadOutlined />}
              </IconButton>
            </InputAdornment>
          ) : null
        }
      />
      {isCorrected && (
        <FormHelperText>
          <FormattedMessage id="corrected-from" />: <strong>{detection?.text}</strong>
        </FormHelperText>
      )}
      {detection?.messages?.map((message) => (
        <FormHelperText key={message.message}>
          <span style={{ color: message.level === "error" ? "red" : "inherit" }}>
            {message.message}
          </span>
        </FormHelperText>
      ))}
    </FormControl>
  );
};

export default DetectionFormControl;
