import { DownloadOutlined } from "@ant-design/icons";
import { LoadingButton } from "@mui/lab";
import { FormattedMessage } from "react-intl";
import { useLazyGetChargingStationLogDownloadUrlQuery } from "service/api-slice";
import { useEffect } from "react";

const DownloadLogButton = (props: { chargingStationId: string; logRequestId: number }) => {
  const args = {
    csId: props.chargingStationId,
    lrId: props.logRequestId,
  };
  const [getUrlTrigger, getUrlResult] = useLazyGetChargingStationLogDownloadUrlQuery();

  useEffect(() => {
    if (getUrlResult.isSuccess) {
      window.location.href = getUrlResult.data?.data.url;
    }
  }, [getUrlResult]);

  return (
    <LoadingButton
      onClick={() => {
        getUrlTrigger(args);
      }}
      loading={getUrlResult.isLoading}
      startIcon={<DownloadOutlined />}
    >
      <FormattedMessage id="download" />
    </LoadingButton>
  );
};

export default DownloadLogButton;
