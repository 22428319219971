import { FileSearchOutlined } from "@ant-design/icons";
import { TableCell, TableRow, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FormattedMessage } from "react-intl";

const TableNoResults = ({ columnCount }: { columnCount: number }) => {
  return (
    <TableRow sx={{ height: 200 }}>
      <TableCell colSpan={columnCount} sx={{ textAlign: "center" }}>
        <Stack spacing={2}>
          <FileSearchOutlined style={{ fontSize: 25 }} />
          <Typography variant="h5">
            <FormattedMessage id="no-results" />
          </Typography>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default TableNoResults;
