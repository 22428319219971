import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import MainCard from "components/MainCard";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!!);

const PaymentStatus = (props: { clientSecret: string }) => {
  const stripe = useStripe();
  const [paymentIntentStatus, setPaymentIntentStatus] = useState<string>(
    "payment-intent-status-loading"
  );

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(props.clientSecret).then(({ paymentIntent }) => {
      if (!paymentIntent) {
        return;
      }

      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (paymentIntent.status) {
        case "requires_capture":
          setPaymentIntentStatus("payment-intent-status-success");
          break;
        default:
          console.error("Unexpected payment intent status: " + paymentIntent.status);
          setPaymentIntentStatus("payment-intent-status-error");
          break;
      }
    });
  }, [stripe, props.clientSecret]);

  return <FormattedMessage id={paymentIntentStatus} />;
};

export const PaymentStatusPage = () => {
  const [searchParams] = useSearchParams();
  const [clientSecret, setClientSecret] = useState<string>();

  useEffect(() => {
    if (searchParams && searchParams.get("payment_intent_client_secret")) {
      setClientSecret(searchParams.get("payment_intent_client_secret")!);
    }
  }, [searchParams]);

  return (
    <MainCard content={false}>
      {clientSecret && stripePromise && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: clientSecret,
            appearance: {
              theme: "stripe",
              variables: {
                fontFamily: `'Public Sans', sans-serif`,
              },
            },
          }}
        >
          <PaymentStatus clientSecret={clientSecret} />
        </Elements>
      )}
    </MainCard>
  );
};
