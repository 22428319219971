import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useAuthorizeChargingStationMutation } from "service/api-slice";
import { openSnackbarError, openSnackbarSuccess } from "store/reducers/snackbar";

const StartTransactionButton = ({
  chargingStationId,
  disabled = false,
  size = "medium",
}: {
  chargingStationId: string;
  disabled?: boolean;
  size?: "small" | "medium" | "large";
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [startTransaction, startTransactionResult] = useAuthorizeChargingStationMutation();

  const onClick = () => {
    startTransaction({ csId: chargingStationId });
  };

  useEffect(() => {
    if (startTransactionResult.isSuccess) {
      dispatch(
        openSnackbarSuccess({
          message: intl.formatMessage({ id: "request-sent" }),
        })
      );
    } else if (startTransactionResult.isError) {
      dispatch(
        openSnackbarError({
          title: intl.formatMessage({ id: "error-occured" }),
          message: (startTransactionResult.error as any).data.message,
        })
      );
    }
  }, [startTransactionResult, dispatch, intl]);

  return (
    <LoadingButton
      size={size}
      variant="outlined"
      color="primary"
      loading={startTransactionResult.isLoading}
      disabled={disabled}
      onClick={onClick}
    >
      <FormattedMessage id="start-transaction" />
    </LoadingButton>
  );
};

export default StartTransactionButton;
