import { Stack } from "@mui/system";
import { Outlet } from "react-router";

const GarageOperatorIndex = () => {
  return (
    <Stack rowGap={2}>
      <Outlet />
    </Stack>
  );
};

export default GarageOperatorIndex;
