import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Alert,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MainCard from "components/MainCard";
import EnergyUsedChart from "components/charts/EnergyUsed";
import SessionFailuresByType from "components/charts/SessionFailuresByType";
import TotalSessionsChart from "components/charts/TotalSessions";
import { PreauthorizePane, useIsInPreauthExperiment } from "components/payments/preauthorization";
import { WebsocketContext } from "contexts/WebsocketContext";
import { formatRFC3339 } from "date-fns";
import { useNavigateWithModule } from "hooks/useNavigateWithModule";
import { useNavigateWithQuery } from "hooks/useNavigateWithQuery";
import { TransactionsTable } from "pages/transactions/components/TransactionsTable";
import { SyntheticEvent, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import {
  ChargingStation,
  GetSessionsArgs,
  Transaction,
  useGetChargingStationQuery,
  useGetChargingStationsUptimeQuery,
} from "service/api-slice";
import { MonitorWebsocketProvider } from "service/monitor/monitor-websocket";
import { useModuleContext } from "types/context";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";
import { LogsTable } from "../maintenance/components/LogsTable";
import { FirmwareVersions } from "../maintenance/components/firmware-version";
import CurrentTransaction from "./components/CurrentTransaction";
import { OcppMessagesTable } from "./components/OcppMessagesTable";
import SingleMeterValue from "./components/SingleMeterValue";
import StartTransactionButton from "./components/StartTransactionButton";
import ConfigurationView from "./components/config/ConfigurationView";
import OcppConnectionsList from "./components/ocpp-events/OcppConnectionsList";

const ChargingStationPage = () => {
  const csId = useParams()["id"]!!;

  return (
    <MonitorWebsocketProvider chargingStationId={csId}>
      <RefreshingChargingStationPage csId={csId} />
    </MonitorWebsocketProvider>
  );
};

const RefreshingChargingStationPage = ({ csId }: { csId: string }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { filter, module } = useModuleContext();
  const { data, refetch } = useGetChargingStationQuery({
    csId: csId,
    expand_authorization: true,
    expand_cooling: true,
    expand_current_transaction: true,
  });
  const wsContext = useContext(WebsocketContext);
  const navigate = useNavigateWithQuery();

  const { data: uptime } = useGetChargingStationsUptimeQuery({
    startTime: filter.timeRange.start.toISOString(),
    endTime: filter.timeRange.end.toISOString(),
    orgId: filter.orgId,
    siteId: filter.siteId,
    csId: csId,
  });

  const uptimePercentage = useMemo(() => {
    if (!uptime) {
      return 1;
    }

    return (100 - (uptime.data.offline_duration / uptime.data.total_duration) * 100).toFixed(4);
  }, [uptime]);

  useEffect(() => {
    if (wsContext.message) {
      console.log(wsContext.message);
      switch (wsContext.message.type) {
        case "StatusChange":
          console.log("Triggering refetch...");
          refetch();
          break;
      }
    }
  }, [refetch, wsContext.message]);

  const sessionArgs: GetSessionsArgs = {
    start: filter.timeRange.start.toISOString(),
    end: filter.timeRange.end.toISOString(),
    count: 25,
    page: 0,
    cpId: csId,
    siteId: filter.siteId,
    orgId: filter.orgId,
    new: true,
  };

  const isInPreauthExperiment = useIsInPreauthExperiment();
  return (
    <Grid container spacing={2} alignItems={"stretch"}>
      {data?.data.status?.disabled && (
        <Grid item xs={12} md={12} lg={12}>
          <Alert severity="error" variant="filled">
            Disabled &mdash; {data?.data.status?.disabled_reason}
          </Alert>
        </Grid>
      )}
      {data?.data?.status &&
        data?.data?.status?.status !== "Available" &&
        data?.data?.current_transaction && (
          <Grid item xs={12} md={12} lg={12}>
            <CurrentTransaction cs={data?.data} tx={data?.data.current_transaction} />
          </Grid>
        )}
      {data?.data?.status?.status === "Occupied" &&
        !data?.data?.current_transaction &&
        !isInPreauthExperiment(data.data) && (
          <Grid item xs={12}>
            <MainCard title="Authorization">
              <StartTransactionButton
                disabled={
                  data?.data.status?.disabled || data?.data.authorization?.mac_address === ""
                }
                chargingStationId={csId}
              />
            </MainCard>
          </Grid>
        )}
      {data?.data && isInPreauthExperiment(data.data) && (
        <Grid item xs={12}>
          <MainCard>
            <PreauthorizePane cs={data.data} refetch={refetch} />
          </MainCard>
        </Grid>
      )}

      {data?.data && (
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <SingleMeterValue title="charging-station" value={{ value: `${data?.data.name}` }} />
            </Grid>
            <Grid item xs={6} md={4}>
              <SingleMeterValue
                title="status"
                value={{ value: data.data.status?.status || "Unknown" }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <SingleMeterValue title="uptime" value={{ value: `${uptimePercentage}%` }} />
            </Grid>
            <Grid item xs={12}>
              <ChargingStationInfoComponent cs={data.data} />
            </Grid>
          </Grid>
        </Grid>
      )}

      {!mobile && (
        <>
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ height: "100%" }}>
            <EnergyUsedChart height={"100%"} sessionArgs={sessionArgs} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TotalSessionsChart height={190} sessionArgs={sessionArgs} />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <SessionFailuresByType
              height={190}
              args={{
                start: filter.timeRange.start.toISOString(),
                end: filter.timeRange.end.toISOString(),
                siteId: filter.siteId,
                orgId: filter.orgId,
                csId: csId,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              }}
              buttonText={<FormattedMessage id="failure-details" />}
              buttonClick={() => {
                return navigate({
                  pathname: `/${module}/reports/failures`,
                  search: `?csId=${csId}`,
                });
              }}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12} md={12} lg={12}>
        {data?.data && <ChargingStationInfoTabs cs={data.data} />}
      </Grid>
    </Grid>
  );
};

const ChargingStationInfoComponent = ({ cs }: { cs: ChargingStation }) => {
  return (
    <MainCard height="100%" content={false}>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow hover={false}>
              <TableCell sx={{ minWidth: 100 }}>
                <FormattedMessage id="last-seen" />
              </TableCell>
              <TableCell>
                {cs.status?.last_seen && parseAndFormatDate(cs.status?.last_seen)}
              </TableCell>
            </TableRow>
            <TableRow hover={false}>
              <TableCell sx={{ minWidth: 100 }}>
                <FormattedMessage id="last-used" />
              </TableCell>
              <TableCell>
                {cs.status?.last_used && parseAndFormatDate(cs.status?.last_used)}
              </TableCell>
            </TableRow>
            <TableRow hover={false}>
              <TableCell>
                <FormattedMessage id="serial-number" />
              </TableCell>
              <TableCell>{cs.serial_number}</TableCell>
            </TableRow>
            <TableRow hover={false}>
              <TableCell>
                <FormattedMessage id="model" />
              </TableCell>
              <TableCell>{cs.model}</TableCell>
            </TableRow>
            <TableRow hover={false}>
              <TableCell>
                <FormattedMessage id="firmware" />
              </TableCell>
              <TableCell>
                {cs.firmware_version && <FirmwareVersions versions={cs.firmware_version} />}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

const ChargingStationInfoTabs = ({ cs }: { cs: ChargingStation }) => {
  const [selectedTab, setSelectedTab] = useState("transactions");
  const { module, filter } = useModuleContext();
  const navigate = useNavigateWithModule(module);

  const handleTabChanged = (event: SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  function navigateToTransaction(transaction: Transaction) {
    navigate(`charging-stations/${cs.id}/transactions/${transaction.id}`);
  }

  return (
    <MainCard content={false}>
      <TabContext value={selectedTab}>
        <TabList onChange={handleTabChanged} aria-label="Charge Point Data" variant="scrollable">
          <Tab value="transactions" label={<FormattedMessage id="transactions" />} />
          <Tab value="messages" label={<FormattedMessage id="messages" />} />
          <Tab value="ocpp-connections" label={<FormattedMessage id="ocpp-connections" />} />
          <Tab value="logs" label={<FormattedMessage id="logs" />} />
          <Tab value="configuration" label={<FormattedMessage id="configuration" />} />
        </TabList>
        <TabPanel value="transactions" sx={{ padding: 0 }}>
          <TransactionsTable
            chargingStationId={cs.id}
            siteId={filter.siteId}
            orgId={filter.orgId}
            startTime={filter.timeRange.start}
            endTime={filter.timeRange.end}
            onClick={function (transaction: Transaction): void {
              navigateToTransaction(transaction);
            }}
          />
        </TabPanel>
        <TabPanel value="messages" sx={{ padding: 0 }}>
          <OcppMessagesTable
            chargingStationdId={cs.id}
            range={{
              start: formatRFC3339(filter.timeRange.start),
              end: formatRFC3339(filter.timeRange.end),
            }}
          />
        </TabPanel>
        <TabPanel value="ocpp-connections" sx={{ padding: 0 }}>
          <OcppConnectionsList
            csId={cs.id}
            range={{ start: filter.timeRange.start, end: filter.timeRange.end }}
          />
        </TabPanel>
        <TabPanel value="logs" sx={{ padding: 0 }}>
          <LogsTable chargingStation={cs} />
        </TabPanel>
        <TabPanel value="configuration" sx={{ padding: 0 }}>
          <ConfigurationView csId={cs.id} />
        </TabPanel>
      </TabContext>
    </MainCard>
  );
};

export default ChargingStationPage;
