import { Dialog, DialogProps, useMediaQuery, useTheme } from "@mui/material";
import Camera from "components/camera/Camera";
import { memo } from "react";

const CameraDialog = ({
  open,
  setOpen,
  handleClose,
  onImageCaptured,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleClose: DialogProps["onClose"];
  onImageCaptured: (blob: Blob) => void;
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog open={open} fullScreen={fullScreen} onClose={handleClose}>
      <Camera onImageCaptured={onImageCaptured} close={() => setOpen(false)} />
    </Dialog>
  );
};

export default memo(CameraDialog);
