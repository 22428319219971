import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import useLocalStorage from "hooks/useLocalStorage";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  PaymentIntent,
  useGetCardReaderStatusQuery,
  useListReadersQuery,
  useResetCardReaderMutation,
} from "service/api-slice";
import { IsLiveEnvironment } from "utils/env";
import { openSnackbarError, openSnackbarSuccess } from "store/reducers/snackbar";
import { useDispatch } from "react-redux";

const stripeLocationId = () => {
  if (IsLiveEnvironment()) {
    // MP location ID
    return "tml_FTzekgev6469hx";
  }

  // Test environment location ID
  return "tml_FTz64QnN0CgGOx";
};

const virtualTestTerminalId = "tmr_FhZcHw24MkgOdW";

const defaultReaderId = () => {
  if (IsLiveEnvironment()) {
    // Terminal reader ID for reader in MP
    return "tmr_FUX2CwfUCFVOkx";
  }

  return virtualTestTerminalId;
};

export interface CardReaderHook {
  id: string;
  setReaderId: (id: string) => void;

  reset: () => void;

  failure_message?: string;
  failure_details?: string;
}

interface chooseReaderState {
  state: "chooseReader";
}

interface sendingOpState {
  state: "sendingOp";
}

interface pollingOpState {
  state: "pollingOp";
  stripe_id: string;
}

interface successState {
  state: "done";
}

interface failedState {
  state: "failed";

  // Human friendly error message
  failure_message: string;

  // Failure details
  failure_details: string;
}

interface resettingState {
  state: "resetting";
}

export const useCardReader = (onError?: (error: any) => void) => {
  const [readerId, setReaderId] = useLocalStorage("reader", defaultReaderId()); // Defaults to MP Reader 1
  const [state, setState] = useState<
    | chooseReaderState
    | sendingOpState
    | pollingOpState
    | successState
    | failedState
    | resettingState
  >({ state: "chooseReader" });
  const intl = useIntl();

  const [resetCardReader, resetCardReaderResult] = useResetCardReaderMutation();
  const statusQuery = useGetCardReaderStatusQuery(readerId, {
    pollingInterval: 1_000,
    skip: state.state !== "pollingOp",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (state.state !== "pollingOp") {
      return;
    }

    if (statusQuery.error) {
      // Transient error:
      dispatch(
        openSnackbarError({
          title: "Failed polling card reader:",
          message: JSON.stringify(statusQuery.error),
        })
      );
      return;
    }

    if (statusQuery.data?.data.action) {
      const action = statusQuery.data.data.action;
      if (action.status === "failed") {
        let message = "";
        if (action.failure_code === "card_declined") {
          message = intl.formatMessage({ id: "card-declined" });
        } else {
          message = intl.formatMessage({ id: "payment-intent-status-error" });
          console.debug("CardReader failed with unknown reader: ", action);
        }
        setState({
          state: "failed",
          failure_message: message,
          failure_details: "Card reader action: " + JSON.stringify(action),
        });
        if (onError) {
          onError(action.failure_message);
        }
      }

      if (action.status === "succeeded") {
        setState({ state: "done" });
      }
    }
  }, [dispatch, intl, onError, setState, state, statusQuery]);

  useEffect(() => {
    if (resetCardReaderResult.isSuccess) {
      dispatch(
        openSnackbarSuccess({
          message: "Card reader reset",
        })
      );
      setState({ state: "chooseReader" });
      resetCardReaderResult.reset();
    } else if (resetCardReaderResult.isError) {
      const msg = intl.formatMessage({ id: "payment-intent-status-error" });
      dispatch(openSnackbarError({ msg: msg }));
      setState({
        state: "failed",
        failure_message: msg,
        failure_details: JSON.stringify(resetCardReaderResult.error),
      });
      resetCardReaderResult.reset();
    }
  }, [dispatch, intl, resetCardReaderResult]);

  const memoCardReader = useMemo(() => {
    return {
      id: readerId,
      setReaderId: setReaderId,

      reset: () => {
        resetCardReader(readerId);
        setState({ state: "resetting" });
      },
      isResetting: state.state === "resetting",

      startOp: () => {
        setState({ state: "sendingOp" });
      },
      startPaymentIntent: (pi: PaymentIntent) => {
        setState({
          state: "pollingOp",
          stripe_id: pi.stripe_id,
        });
      },

      failure_message: state.state === "failed" ? state.failure_message : undefined,
      failure_details: state.state === "failed" ? state.failure_details : undefined,
    };
  }, [readerId, resetCardReader, setReaderId, setState, state]);

  return memoCardReader;
};

export const SubmitToReaderButton = ({
  disabled,
  onClick,
  cardReader,
}: {
  disabled: boolean;
  onClick: (readerID: string) => void;
  cardReader: CardReaderHook;
}) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [readerDropdownOpen, setReaderDropdownOpen] = useState(false);
  const { data: readers } = useListReadersQuery({
    // This filters out phones, which are not assigned to MP.
    location_id: stripeLocationId(),
  });

  const toggleReaderDropdown = () => {
    setReaderDropdownOpen((prevOpen) => !prevOpen);
  };

  const closeReaderDropdown = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setReaderDropdownOpen(false);
  };

  const onReaderSelect = (readerId: string) => {
    if (readers?.data) {
      cardReader.setReaderId(readerId);
    }
    setReaderDropdownOpen(false);
  };

  const readerLabel = useCallback(() => {
    if (readers?.data) {
      const reader = readers.data.find((reader) => reader.id === cardReader.id);
      if (reader) {
        return reader.label;
      }
    }
    return "Unknown";
  }, [readers, cardReader.id]);

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef} style={{ width: "100%" }} disableElevation>
        <Button
          variant="contained"
          disabled={disabled}
          onClick={() => {
            onClick(cardReader.id);
          }}
          sx={{ flexGrow: 1 }}
        >
          <FormattedMessage id="accept-payment-with-reader" values={{ reader: readerLabel() }} />
        </Button>
        <Button onClick={toggleReaderDropdown} disabled={disabled} size="small">
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={readerDropdownOpen}
        transition
        disablePortal
        anchorEl={anchorRef.current}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "center right",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={closeReaderDropdown}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {readers?.data.map((reader) => (
                    <MenuItem
                      key={reader.id}
                      selected={cardReader.id === reader.id}
                      onClick={() => {
                        onReaderSelect(reader.id);
                      }}
                    >
                      {reader.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
