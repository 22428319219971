import { ForwardRefExoticComponent, RefAttributes, forwardRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

/* eslint react/no-unstable-nested-components : 0 */

// material-ui
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project import
import { activeItem } from "store/reducers/menu";

// types
import Link from "components/Link";
import { useLocation } from "react-router-dom";
import { LinkTarget, NavItemType } from "types/menu";
import { RootStateProps } from "types/root";

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

interface Props {
  item: NavItemType;
  level: number;
}

const NavItem = ({ item, level }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen, openItem } = menu;

  let itemTarget: LinkTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps: {
    component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
    href?: string;
    target?: LinkTarget;
  } = {
    component: forwardRef(function listItemProps(props, ref) {
      return <Link {...props} to={item.url!} target={itemTarget} />;
    }),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const Icon = item.icon!;
  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: drawerOpen ? "1rem" : "1.25rem" }} />
  ) : (
    false
  );

  const isSelected = openItem.findIndex((id) => id === item.url) > -1;
  const { pathname } = useLocation();

  // active menu item on page load
  useEffect(() => {
    // if (pathname && item.url && pathname?.includes(item.url)) {
    //   dispatch(activeItem({ openItem: [item.id] }));
    // }
    if (pathname === item.url) {
      dispatch(activeItem({ openItem: [item.url] }));
    }

    // eslint-disable-next-line
  }, [pathname]);

  const textColor = theme.palette.mode === "dark" ? "grey.400" : "text.primary";
  const iconSelectedColor =
    theme.palette.mode === "dark" && drawerOpen ? "text.primary" : "primary.main";

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        pl: drawerOpen ? `${level * 28}px` : 1.5,
        py: !drawerOpen && level === 1 ? 1.25 : 1,
        ...(drawerOpen && {
          "&:hover": {
            bgcolor: theme.palette.mode === "dark" ? "divider" : "primary.lighter",
          },
          "&.Mui-selected": {
            bgcolor: theme.palette.mode === "dark" ? "divider" : "primary.lighter",
            borderRight: `2px solid ${theme.palette.primary.main}`,
            color: iconSelectedColor,
            "&:hover": {
              color: iconSelectedColor,
              bgcolor: theme.palette.mode === "dark" ? "divider" : "primary.lighter",
            },
          },
        }),
        ...(!drawerOpen && {
          "&:hover": {
            bgcolor: "transparent",
          },
          "&.Mui-selected": {
            "&:hover": {
              bgcolor: "transparent",
            },
            bgcolor: "transparent",
          },
        }),
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: isSelected ? iconSelectedColor : textColor,
            ...(!drawerOpen && {
              borderRadius: 1.5,
              width: 36,
              height: 36,
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                bgcolor: theme.palette.mode === "dark" ? "secondary.light" : "secondary.lighter",
              },
            }),
            ...(!drawerOpen &&
              isSelected && {
                bgcolor: theme.palette.mode === "dark" ? "primary.900" : "primary.lighter",
                "&:hover": {
                  bgcolor: theme.palette.mode === "dark" ? "primary.darker" : "primary.lighter",
                },
              }),
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && (
        <ListItemText
          primary={
            <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
              {item.title}
            </Typography>
          }
        />
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

export default NavItem;
