import { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// project import
import useConfig from "hooks/useConfig";

// third-party
import AnalyticsDataCard from "components/cards/statistics/AnalyticsDataCard";
import ReactApexChart, { Props as ChartProps } from "react-apexcharts";
import { GetSessionsArgs, useGetSessionSummaryQuery } from "service/api-slice";

// ==============================|| USER CARD CHART ||============================== //

const TotalSessionsChart = (props: { height: number | null; sessionArgs: GetSessionsArgs }) => {
  const theme = useTheme();
  const { mode } = useConfig();
  const { data } = useGetSessionSummaryQuery(
    {
      ...props.sessionArgs,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    {
      pollingInterval: 60_000,
      skipPollingIfUnfocused: true,
    }
  );

  const [count, setCount] = useState("");
  const [change, setChange] = useState<number | null>(null);

  const [series, setSeries] = useState([
    {
      name: "Sessions",
      data: [{ x: "", y: 0 }],
    },
  ]);

  useEffect(() => {
    if (data) {
      setSeries([
        {
          name: "Sessions",
          data: data.data.map((d) => ({
            // convert 24 hour time to 12 hour time
            x: `${d.hour_of_day % 12 || 12} ${d.hour_of_day < 12 ? "AM" : "PM"}`,
            y: d.count,
          })),
        },
      ]);
      setCount(`${data.totalSessions} Sessions`);
      setChange(data.totalChange);
    }
  }, [data]);

  // chart options
  const chartOptions = {
    chart: {
      id: "charging-sessions-by-hour-of-day-chart",
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "80%",
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      labels: {
        maxHeight: 35,
        show: true,
      },
    },
    yaxis: {
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      theme: mode === "dark" ? "dark" : "light",
      x: {
        show: true,
      },
      y: {
        formatter: (value: number) => {
          return value.toFixed(0);
        },
      },
    },
    grid: {
      show: false,
    },
  };

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState<ChartProps>(chartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [theme.palette.primary.main, theme.palette.primary[700]],
      tooltip: {
        theme: mode === "dark" ? "dark" : "light",
      },
    }));
  }, [mode, primary, secondary, line, theme]);

  return (
    <AnalyticsDataCard
      title="charging-sessions-chart-label"
      count={count}
      percentage={change}
      isLoss={change ? change < 0 : null}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={props?.height?.toString()}
      />
    </AnalyticsDataCard>
  );
};

export default TotalSessionsChart;
