import { Outlet } from "react-router";
import { useModuleContext } from "types/context";

const ChargingSessionsIndex = () => {
  const context = useModuleContext();

  return <Outlet context={context} />;
};

export default ChargingSessionsIndex;
