import { FlagOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useState } from "react";
import FeatureFlagList from "./FeatureFlagList";
import { useFeatureFlags } from "experimentation/FeatureFlagProvider";

const ManageFlagMenuItem = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [filter, setFilter] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const experimentation = useFeatureFlags();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const overflowOpen = Boolean(anchorEl);

  const handleOpenOverflow = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseOverflow = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Dialog open={dialogOpen} fullScreen={fullScreen}>
        <DialogTitle>
          <Stack direction="column" spacing={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography variant="h4">Manage Feature Flags</Typography>
              <IconButton id={`overflow-button`} onClick={handleOpenOverflow}>
                <MoreOutlined />
              </IconButton>
              <Menu
                id={`overflow-menu`}
                anchorEl={anchorEl}
                open={overflowOpen}
                onClose={handleCloseOverflow}
              >
                <MenuItem
                  onClick={() => {
                    experimentation.resetAllOverrides();
                    handleCloseOverflow();
                  }}
                >
                  Reset all
                </MenuItem>
              </Menu>
            </Stack>
            <Box sx={{ width: "100%" }}>
              <FormControl sx={{ width: "100%" }}>
                <OutlinedInput
                  size="small"
                  id="header-search"
                  startAdornment={
                    <InputAdornment position="start" sx={{ mr: -0.5 }}>
                      <SearchOutlined />
                    </InputAdornment>
                  }
                  aria-describedby="header-search-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                  value={filter}
                  placeholder=""
                />
              </FormControl>
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <FeatureFlagList filter={filter} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Done</Button>
        </DialogActions>
      </Dialog>
      <MenuItem onClick={handleDialogOpen}>
        <ListItemIcon>
          <FlagOutlined />
        </ListItemIcon>
        <ListItemText>Manage Feature Flags</ListItemText>
      </MenuItem>
    </>
  );
};

export default ManageFlagMenuItem;
