import { Typography } from "@mui/material";
import { ChargingStation } from "service/api-slice";

const ChargingStationName = ({ chargingStation }: { chargingStation: ChargingStation }) => {
  return (
    <>
      {chargingStation.name}
      <Typography
        variant="caption"
        sx={{
          color: "text.secondary",
          display: { xs: "none", sm: "block" },
        }}
      >
        {chargingStation.id}
      </Typography>
    </>
  );
};

export default ChargingStationName;
