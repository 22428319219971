import {
  Button,
  Dialog,
  DialogProps,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import CameraDialog from "components/camera/CameraDialog";
import VehicleImageCard from "modules/site-operator/vehicle-images/components/VehicleImageCard";
import { SyntheticEvent, useRef, useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useUploadVehicleImageMutation } from "service/api-slice";

const UploadImage = ({
  vehicleId,
  chargingStationId,
  transactionId,
  onUploaded,
}: {
  vehicleId: string;
  chargingStationId: string;
  transactionId: string;
  onUploaded: () => void;
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);

  const [uploadVehicleImage, uploadVehicleImageResult] = useUploadVehicleImageMutation();

  const form = useRef<HTMLFormElement>(null);

  const uploadImages = () => {
    const formData = new FormData(form.current!);
    uploadVehicleImage(formData);
  };

  const handleCapturedImage = useCallback(
    (blob: Blob) => {
      const formData = new FormData(form.current!);
      formData.set("image", blob);
      uploadVehicleImage(formData);
      setOpen(false);
    },
    [uploadVehicleImage, setOpen, form]
  );

  const handleClose: DialogProps["onClose"] = useCallback(
    (event: SyntheticEvent, reason) => {
      if (reason === "backdropClick") {
        event.stopPropagation();
        return;
      }
      setOpen(false);
    },
    [setOpen]
  );

  return (
    <>
      <CameraDialog
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        onImageCaptured={handleCapturedImage}
      />
      {uploadVehicleImageResult.isSuccess && (
        <Dialog
          open={uploadVehicleImageResult.isSuccess}
          fullScreen={fullScreen}
          onClose={() => uploadVehicleImageResult.reset()}
        >
          <VehicleImageCard
            image={uploadVehicleImageResult.data!.data[0]}
            onFinished={() => {
              uploadVehicleImageResult.reset();
              onUploaded();
            }}
          />
        </Dialog>
      )}
      <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
        {uploadVehicleImageResult.isLoading && <LinearProgress />}
        {!uploadVehicleImageResult.isLoading && (
          <>
            <Button
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                setOpen(true);
              }}
            >
              <FormattedMessage id="take-photo" />
            </Button>
            <form ref={form}>
              <input type="hidden" name="vehicle_id" value={vehicleId} />
              <input type="hidden" name="charging_station_id" value={chargingStationId} />
              <input type="hidden" name="transaction_id" value={transactionId} />
              <Button
                component="label"
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <FormattedMessage id="choose-photos" />
                <input
                  type="file"
                  name="image"
                  accept="image/jpeg"
                  multiple={true}
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      uploadImages();
                    }
                  }}
                  hidden
                />
              </Button>
            </form>
          </>
        )}
      </Stack>
    </>
  );
};

export default UploadImage;
