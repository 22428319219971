import { useCallback, useMemo } from "react";
import { NavigateOptions, To, useLocation, useNavigate } from "react-router-dom";

export function useNavigateWithQuery(): (to: To, options?: NavigateOptions) => void {
  const navigate = useNavigate();
  const { search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  return useCallback(
    (to: To, options?: NavigateOptions) => {
      if (typeof to === "string") {
        to += `?${query.toString()}`;
      } else if (typeof to === "object") {
        if (to.search) {
          const fromUrl = query;
          const passedIn = new URLSearchParams(to.search);
          for (const [key, value] of passedIn) {
            fromUrl.set(key, value);
          }
          to.search = fromUrl.toString();
        } else {
          to.search = search;
        }
      }
      navigate(to, options);
    },
    [query, search, navigate]
  );
}
