export interface FeatureFlagDefinition<V = string | boolean> {
  type: "feature_flag";
  name: string;
  defaultValue: V;
  values?: string[] | undefined;
}

export interface FeatureFlag<V = string | boolean | any> extends FeatureFlagDefinition<V> {
  definitionName: string;
  serverValue: V | undefined;
  overriddenValue: V | undefined;
}

export const createBooleanFlag = (
  name: string,
  defaultValue: boolean
): FeatureFlagDefinition<boolean> => {
  return { type: "feature_flag", name, defaultValue };
};

export const createVariantFlag = (
  name: string,
  defaultValue: string,
  values: string[]
): FeatureFlagDefinition<string> => {
  return { type: "feature_flag", name, defaultValue, values };
};

export function createJsonFlag<T>(name: string, defaultValue: T): FeatureFlagDefinition<T> {
  return { type: "feature_flag", name, defaultValue };
}

export const isBooleanFeatureFlag = (flag: FeatureFlag): flag is FeatureFlag<boolean> => {
  return typeof flag.defaultValue === "boolean";
};

export const isVariantFeatureFlag = (flag: FeatureFlag): flag is FeatureFlag<string> => {
  return typeof flag.defaultValue === "string";
};

export const isFeatureFlag = (obj: any): obj is FeatureFlag => {
  return "type" in obj && obj.type === "feature_flag";
};
