import { isBooleanFeatureFlag, isFeatureFlag } from "experimentation/FeatureFlag";
import { FeatureEvaluator } from "experimentation/FeatureFlagProvider";
import { NavItemType } from "types/menu";

export const isVisible = (item: NavItemType, evaluateFeature: FeatureEvaluator): boolean => {
  if (item.visible === undefined) return true;

  if (typeof item.visible === "boolean") {
    return item.visible;
  }
  if (isFeatureFlag(item.visible) && isBooleanFeatureFlag(item.visible)) {
    return evaluateFeature(item.visible);
  }

  if (typeof item.visible === "function") {
    return item.visible(evaluateFeature);
  }

  return false;
};
