import { Alert, AlertTitle, Button, Stack } from "@mui/material";
import Camera from "components/camera/Camera";
import { useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetTransactionQuery, useUploadVehicleImageMutation } from "service/api-slice";
import VehicleImageCard from "../vehicle-images/components/VehicleImageCard";

const RegisterVehicle = () => {
  const navigate = useNavigate();

  const urlParams = useParams();
  const csId = urlParams["csId"]!;
  const txId = urlParams["txId"]!;

  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect") ?? "/";

  const form = useRef<HTMLFormElement>(null);

  const { isSuccess, data } = useGetTransactionQuery({ csId, txId, expandVehicle: true });
  const [uploadVehicleImage, uploadVehicleImageResult] = useUploadVehicleImageMutation();

  const handleCapturedImage = (blob: Blob) => {
    const formData = new FormData(form.current!);
    formData.set("image", blob);
    uploadVehicleImage(formData);
  };

  return (
    <>
      {uploadVehicleImageResult.isUninitialized && (
        <Camera onImageCaptured={handleCapturedImage} close={() => navigate(redirect)} />
      )}
      {uploadVehicleImageResult.isSuccess && (
        <VehicleImageCard
          image={uploadVehicleImageResult.data.data[0]}
          onFinished={() => navigate(redirect)}
        />
      )}
      {uploadVehicleImageResult.isError && (
        <Stack sx={{ m: 2 }} spacing={2}>
          <Alert severity="error">
            <AlertTitle>Error uploading image</AlertTitle>
            {(uploadVehicleImageResult.error as any).data.message}
            <pre style={{ padding: 10 }}>
              {JSON.stringify(uploadVehicleImageResult.error, null, 2)}
            </pre>
          </Alert>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button variant="outlined" onClick={() => navigate(redirect)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => uploadVehicleImageResult.reset()}>
              Retry
            </Button>
          </Stack>
        </Stack>
      )}
      {isSuccess && (
        <form ref={form}>
          <input type="hidden" name="vehicle_id" value={data?.data.vehicle?.id} />
          <input type="hidden" name="charging_station_id" value={csId} />
          <input type="hidden" name="transaction_id" value={txId} />
        </form>
      )}
    </>
  );
};

export default RegisterVehicle;
