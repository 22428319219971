const EmptyRegex = /^\s*$/;

export function isBlank(text: string | null | undefined, ...emptyValues: string[]): boolean {
  return (
    text === null ||
    text === undefined ||
    emptyValues.includes(text) ||
    EmptyRegex.exec(text) !== null
  );
}

export function valueOrNull(text: string | null, ...emptyValues: string[]): string | null {
  return isBlank(text, ...emptyValues) ? null : text;
}
