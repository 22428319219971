import { useTheme } from "@mui/material/styles";
import MainCard from "components/MainCard";
import { WebsocketContext } from "contexts/WebsocketContext";
import { format } from "date-fns-tz";
import useConfig from "hooks/useConfig";
import { useContext, useEffect, useState } from "react";
import ReactApexChart, { Props as ChartProps } from "react-apexcharts";
import { useGetTransactionChartDataQuery } from "service/api-slice";
import { Message, MeterValuesMessage } from "service/monitor/msg/Message";

export type PowerUsedProps = {
  chargingStationId: string;
  transactionId: string;
  height?: number;
};

export const PowerUsed = ({ chargingStationId, transactionId, height = 260 }: PowerUsedProps) => {
  const theme = useTheme();
  const { mode } = useConfig();

  const wsContext = useContext(WebsocketContext);

  const { data, isLoading } = useGetTransactionChartDataQuery({
    cpId: chargingStationId,
    txId: transactionId,
  });

  useEffect(() => {
    if (isLoading) return;

    if (wsContext.message) {
      var msg = wsContext.message as Message<any>;
      switch (msg.type) {
        case "MeterValues":
          processMeterValues(msg as MeterValuesMessage);
          break;
      }
    }

    function processMeterValues(msg: MeterValuesMessage) {
      msg.message.forEach((v) => {
        if (!v.value) return;
        switch (v.measurand) {
          case "Power.Active.Import":
            setSeries((prevState) => {
              var updated = [
                {
                  name: "Power",
                  data: [...prevState[0].data, { x: v.timestamp, y: v.value }],
                },
              ];
              return updated;
            });
            break;
        }
      });
    }
  }, [wsContext.message, isLoading]);

  const chartOptions = {
    chart: {
      id: "power-used",
      type: "line",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stoke: {
      width: 0.5,
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      // categories: data?.dates,
      // title: {
      //   text: "State of Charge",
      //   offsetY: 50,
      // },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        formatter: (value: any) => {
          return format(value, "h:mm a");
        },
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      // title: {
      //   text: "Power",
      // },
      labels: {
        show: true,
        formatter: (value: any) => {
          return `${value / 1000} kW`;
        },
      },
    },
    tooltip: {
      theme: mode === "dark" ? "dark" : "light",
      formatter: (value: any) => {
        return `${value} kW`;
      },
    },
    grid: {
      show: true,
    },
  };

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [series, setSeries] = useState([
    {
      name: "Power",
      data: new Array<any>(),
    },
  ]);

  useEffect(() => {
    if (data) {
      setSeries([
        {
          name: "Power",
          data: data.power_used,
        },
      ]);
    }
  }, [data]);

  const [options, setOptions] = useState<ChartProps>(chartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [theme.palette.primary.main, theme.palette.primary[700]],
      tooltip: {
        theme: mode === "dark" ? "dark" : "light",
      },
    }));
  }, [mode, primary, secondary, line, theme, data]);

  return (
    <MainCard content={false} sx={{ height: "100%" }}>
      <ReactApexChart options={options} series={series} type="line" height={height ?? 260} />
    </MainCard>
  );
};
