import { AlertOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { NavItemType } from "types/menu";

export const PowerManagementMenu: NavItemType = {
  id: "power-management-menu",
  type: "group",
  url: "/power-management",
  title: <FormattedMessage id="power-management-menu" />,
  children: [
    {
      id: "dashboard",
      title: <FormattedMessage id="overview" />,
      type: "item",
      url: "/power-management",
      icon: AlertOutlined,
      breadcrumbs: true,
    },
  ],
};
