import useLocalStorage from "hooks/useLocalStorage";
import { createContext, useContext, useEffect, useMemo } from "react";
import { arraysEqual } from "utils/arrays";
import { FeatureFlag, FeatureFlagDefinition } from "./FeatureFlag";
import { Flags } from "./Flags";
import { useLaunchDarkly } from "./LaunchDarklyContext";
import isEqual from "lodash/isEqual";

export type FeatureEvaluator = <T = boolean | string>(flag: FeatureFlagDefinition<T>) => T;

export type FeatureFlagContextProps = {
  featureFlags: FeatureFlag[];
  overrideFeature: (flag: FeatureFlag, value: string | boolean | undefined) => void;
  resetAllOverrides: () => void;
  evaluateFeature: FeatureEvaluator;
};

export type FeatureFlagProviderProps = {
  children: React.ReactNode;
};

const FeatureFlagContext = createContext<FeatureFlagContextProps>({
  featureFlags: [],
  overrideFeature: () => {},
  resetAllOverrides: () => {},
  evaluateFeature: (flag) => flag.defaultValue,
});

const FeatureFlagProvider = ({ children }: FeatureFlagProviderProps) => {
  const ld = useLaunchDarkly();
  const [featureFlags, setFeatureFlags] = useLocalStorage<FeatureFlag[]>("featureFlags", []);

  useEffect(() => {
    let ldFlags = ld.allFlags();
    let madeChanges = false;
    let copy = featureFlags.slice();
    for (const k of Object.keys(Flags)) {
      const key = k as keyof typeof Flags;
      const flagDef = Flags[key];
      const flag = copy.find((f) => f.name === flagDef.name);
      if (flag === undefined) {
        console.log(`Feature flag ${flagDef.name} added.`);
        madeChanges = true;
        copy.push({
          ...flagDef,
          definitionName: key,
          serverValue: ldFlags[flagDef.name],
          overriddenValue: undefined,
        });
      } else {
        if (flag.definitionName !== key) {
          console.log(`Feature flag ${flag.name} definition changed.`);
          madeChanges = true;
          flag.definitionName = key;
        }
        const serverValue = ldFlags[flagDef.name];
        if (!isEqual(flag.serverValue, serverValue)) {
          console.log(
            `Feature flag ${flag.name} server value changed.`,
            flag.serverValue,
            serverValue
          );
          madeChanges = true;
          flag.serverValue = serverValue;
        }
        if (!arraysEqual(flag.values, flagDef.values)) {
          console.log(`Feature flag ${flag.name} values changed.`);
          madeChanges = true;
          flag.values = flagDef.values;
        }
        if (!isEqual(flag.defaultValue, flagDef.defaultValue)) {
          console.log(`Feature flag ${flag.name} default value changed.`);
          madeChanges = true;
          flag.defaultValue = flagDef.defaultValue;
        }
      }
    }
    const length = copy.length;
    copy = copy.filter((f) => {
      const key = f.definitionName as keyof typeof Flags;
      const flagDef = Flags[key];
      return flagDef !== undefined && flagDef.name === f.name;
    });
    if (length !== copy.length) {
      console.log("Features flags were removed.");
      madeChanges = true;
    }
    if (madeChanges) {
      console.log("Updating new FeatureFlags", featureFlags, copy);
      setFeatureFlags(copy);
    }
  }, [ld, featureFlags, setFeatureFlags]);

  const memoFlags = useMemo(() => {
    return {
      featureFlags: featureFlags,
      overrideFeature: function <T extends string | boolean | undefined>(
        flag: FeatureFlag<T>,
        value: T
      ): void {
        const copy = featureFlags.slice();
        const ff = copy.find((f) => f.name === flag.name);
        if (ff === undefined) {
          console.warn(`Feature flag ${flag.name} is not registered.`);
          return;
        }
        console.log("Overriding FeatureFlag", ff, value);
        ff.overriddenValue = value;
        setFeatureFlags(copy);
      },
      resetAllOverrides: () => {
        const copy = featureFlags.slice();
        for (const ff of copy) {
          ff.overriddenValue = undefined;
        }
        console.log("Resetting FeatureFlags");
        setFeatureFlags(copy);
      },
      evaluateFeature: function <T>(flag: FeatureFlagDefinition<T>): T {
        const ff = featureFlags.find((f) => f.name === flag.name);
        if (ff === undefined) {
          console.warn(`Feature flag ${flag.name} is not registered.`);
          return flag.defaultValue;
        }
        const overriddenValue = ff.overriddenValue;
        if (overriddenValue !== undefined) {
          return overriddenValue as T;
        }
        const serverValue = ff.serverValue;
        if (serverValue !== undefined) {
          return serverValue as T;
        }
        return flag.defaultValue;
      },
    };
  }, [featureFlags, setFeatureFlags]);

  return <FeatureFlagContext.Provider value={memoFlags}>{children}</FeatureFlagContext.Provider>;
};

export const useFeatureFlags = (context = FeatureFlagContext): FeatureFlagContextProps =>
  useContext(context);

export default FeatureFlagProvider;
