import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import TablePagination from "components/pagination/table-pagination";
import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { GetOrganizationArgs, Organization, useGetOrganizationsQuery } from "service/api-slice";

const columnHelper = createColumnHelper<Organization>();

const columns = [
  columnHelper.accessor("id", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="id" />,
  }),
  columnHelper.accessor("name", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="name" />,
  }),
];

export const OrganizationsTable = () => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  const [args, setOrganizationArgs] = useState<GetOrganizationArgs>({
    page: pageIndex,
    count: pageSize,
  });

  const { data, isLoading, isError } = useGetOrganizationsQuery(args);

  const pagination = useMemo(() => {
    setOrganizationArgs({
      page: pageIndex,
      count: pageSize,
    });
    return {
      pageIndex,
      pageSize,
    };
  }, [pageIndex, pageSize]);

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    pageCount: data?.meta?.pagination?.page_count ?? -1,
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableContainer>
      <Table sx={{ minWidth: 350 }} aria-label="Organizations Table">
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableCell key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>
        <TableBody>
          {(isLoading || isError) &&
            table.getHeaderGroups().map((headerGroup) => {
              return [...Array(10).keys()].map((i) => (
                <TableRow key={i}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableCell key={`${i}-${header.id}`} colSpan={header.colSpan}>
                        <Skeleton />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ));
            })}
          {table.getRowModel().rows.map((row) => {
            return (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell sx={{ p: 2 }} colSpan={table.getAllLeafColumns().length}>
              <TablePagination
                gotoPage={table.setPageIndex}
                totalPages={table.getPageCount()}
                setPageSize={table.setPageSize}
                pageIndex={pageIndex}
                pageSize={pageSize}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
