import { TableCell, TableRow } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { LineItem } from "service/api-slice";

const TaxRow = ({ item }: { item: LineItem }) => {
  const amount = (
    (item.tax_info ? item.quantity * item.rate * item.tax_info.rate : 0) / 100
  ).toFixed(2);
  return (
    <TableRow hover={false}>
      <TableCell>{item.tax_info?.description}</TableCell>
      <TableCell>{item.tax_info && item.tax_info.rate * 100}%</TableCell>
      <TableCell align="right">${amount}</TableCell>
    </TableRow>
  );
};

const ReceiptRow = ({ item }: { item: LineItem }) => {
  return (
    <TableRow hover={false}>
      <TableCell>{item.description}</TableCell>
      <TableCell>
        <FormattedMessage id="kwh-rate" values={{ kwh: item.quantity, rate: item.rate / 100 }} />
      </TableCell>
      <TableCell align="right">${((item.quantity * item.rate) / 100).toFixed(2)}</TableCell>
    </TableRow>
  );
};

export const ReceiptRowWithTax = ({ item }: { item: LineItem }) => {
  return (
    <>
      <ReceiptRow item={item} />
      {item.tax_info && <TaxRow item={item} />}
    </>
  );
};
