import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useStopTransactionMutation } from "service/api-slice";
import { openSnackbarError, openSnackbarSuccess } from "store/reducers/snackbar";

const StopTransactionButton = ({
  chargingStationId,
  transactionId,
  size,
}: {
  chargingStationId: string;
  transactionId: string;
  size?: "small" | "medium" | "large";
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [stopTransaction, stopTransactionResult] = useStopTransactionMutation();

  const onClick = () => {
    stopTransaction({ csId: chargingStationId, txId: transactionId });
  };

  useEffect(() => {
    if (stopTransactionResult.isSuccess) {
      dispatch(
        openSnackbarSuccess({
          message: intl.formatMessage({ id: "request-sent" }),
        })
      );
    } else if (stopTransactionResult.isError) {
      dispatch(
        openSnackbarError({
          title: intl.formatMessage({ id: "error-occured" }),
          message: (stopTransactionResult.error as any).data.message,
        })
      );
    }
  }, [stopTransactionResult, dispatch, intl]);

  return (
    <>
      <LoadingButton
        size={size}
        variant="outlined"
        color="primary"
        loading={stopTransactionResult.isLoading}
        onClick={onClick}
      >
        <FormattedMessage id="stop-transaction" />
      </LoadingButton>

      {/* {stopTransactionResult.isSuccess && (
        <Alert sx={{ mt: 2 }} severity="success">
          <AlertTitle><FormattedMessage id="request-sent" /></AlertTitle>
        </Alert>
      )}
      {stopTransactionResult.isError && (
        <Alert sx={{ mt: 2 }} severity="error">
          <AlertTitle><FormattedMessage id="error-occured" /></AlertTitle>
          {(stopTransactionResult.error as any).data.message}
        </Alert>
      )} */}
    </>
  );
};

export default StopTransactionButton;
