import { Backoff } from "./backoff";

/**
 * ConstantBackoff always returns the same value.
 */
export class ConstantBackoff implements Backoff {
  private readonly backoff: number;
  private readonly max: number;

  constructor(backoff: number, maxRetries?: number) {
    this.backoff = backoff;
    this.max = maxRetries ? maxRetries : Number.MAX_VALUE;
  }

  next(): number {
    return this.backoff;
  }

  reset = () => {
    // no-op
  };

  maxRetries = () => {
    return this.max;
  };
}
