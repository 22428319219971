import { useContext, useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// project import

// third-party
import MainCard from "components/MainCard";
import { WebsocketContext } from "contexts/WebsocketContext";
import { parseISO } from "date-fns";
import { format } from "date-fns-tz";
import ReactApexChart, { Props as ChartProps } from "react-apexcharts";
import { useGetTransactionChartDataQuery } from "service/api-slice";
import { Message, MeterValuesMessage } from "service/monitor/msg/Message";
import { useModuleContext } from "types/context";
import { Module } from "types/modules";

export type ChargeCurveProps = {
  chargingStationId: string;
  transactionId: string;
  height?: number;
};

export const ChargeCurve = ({
  chargingStationId,
  transactionId,
  height = 320,
}: ChargeCurveProps) => {
  const theme = useTheme();
  const { module } = useModuleContext();
  const wsContext = useContext(WebsocketContext);
  const { data, isSuccess } = useGetTransactionChartDataQuery({
    cpId: chargingStationId,
    txId: transactionId,
    module: module,
  });
  const [chartData, setChartData] = useState<{
    dates: Date[];
    series: { name: string; data: number[][] };
  }>({
    dates: [],
    series: {
      name: "Power",
      data: new Array<Array<number>>(),
    },
  });

  const [options, setOptions] = useState<ChartProps>({
    chart: {
      id: "charge-curve-chart",
      type: "line",
      toolbar: {
        show: module === Module.SiteOperator,
      },
      animations: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "straight",
    },
    colors: [theme.palette.primary.main, theme.palette.primary[700]],
    xaxis: {
      type: "numeric",
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        formatter: (value: any) => {
          return `${Math.floor(value)}%`;
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: true,
        formatter: (value: any) => {
          return `${value / 1000} kW`;
        },
      },
    },
    tooltip: {
      formatter: (value: any) => {
        return `${value} kW`;
      },
    },
    grid: {
      show: true,
    },
  });

  useEffect(() => {
    if (data) {
      const cleaned = data.charge_curve.filter((v) => v[0] > 0);
      const dates = data.dates
        .filter((_, i) => cleaned[i] && cleaned[i][0] > 0)
        .map((d) => parseISO(d));

      setChartData({
        dates: dates,
        series: {
          name: "Power",
          data: cleaned,
        },
      });

      setOptions((prevState) => ({
        ...prevState,
        xaxis: {
          ...prevState.xaxis,
          categories: dates,
          labels: {
            ...prevState.xaxis.labels,
            formatter: (soc: number) => {
              let idx = data?.soc.findIndex((v) => v === Math.floor(soc));
              if (idx) {
                const d = dates[idx];
                if (d) {
                  return [format(d, "h:mm a"), `${Math.floor(soc)}%`];
                }
              }
              return `${Math.floor(soc)}%`;
            },
          },
        },
      }));
    }
  }, [data]);

  useEffect(() => {
    if (!isSuccess) return;

    if (wsContext.message) {
      const msg = wsContext.message as Message<any>;
      switch (msg.type) {
        case "MeterValues":
          processMeterValues(msg as MeterValuesMessage);
          break;
      }
    }

    function processMeterValues(msg: MeterValuesMessage) {
      let power: number | undefined = undefined;
      let soc: number | undefined = undefined;

      msg.message.forEach((v) => {
        if (!v.value) return;
        switch (v.measurand) {
          case "SoC":
            soc = v.value;
            break;
          case "Power.Active.Import":
            power = v.value;
            break;
        }
      });

      if (power && soc && soc > 0) {
        setChartData((prevState) => {
          return {
            dates: [...prevState.dates, parseISO(msg.timestamp)],
            series: {
              ...prevState.series,
              data: [...prevState.series.data, [soc!, power!]],
            },
          };
        });
      }
    }
  }, [wsContext.message, isSuccess]);

  useEffect(() => {
    setOptions((prevState) => {
      return {
        ...prevState,
        xaxis: {
          ...prevState.xaxis,
          categories: chartData.dates,
          labels: {
            ...prevState.xaxis.labels,
            formatter: (soc: number) => {
              let idx = chartData.series.data.findIndex((v) => v[0] === Math.floor(soc));
              if (idx) {
                const d = chartData.dates[idx];
                if (d) {
                  return [format(d, "h:mm a"), `${Math.floor(soc)}%`];
                }
              }
              return `${Math.floor(soc)}%`;
            },
          },
        },
      };
    });
  }, [chartData]);

  return (
    <MainCard content={false} sx={{ height: "100%" }} title={"Charge Curve"}>
      <ReactApexChart options={options} series={[chartData.series]} type="line" height={height} />
    </MainCard>
  );
};
