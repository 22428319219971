import { Tooltip } from "@mui/material";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import { format } from "date-fns-tz";
import { ChargingStation } from "service/api-slice";

const ChargingStationLastUsed = (props: { chargingStation: ChargingStation }) => {
  if (!props.chargingStation.status?.last_used) {
    return <span>-</span>;
  }

  const lastUsed = parseISO(props.chargingStation.status.last_used);

  return (
    <Tooltip title={format(lastUsed, "MM/dd/yyyy HH:mm:ss zzz")}>
      <span>{formatDistanceToNowStrict(lastUsed, { addSuffix: true })}</span>
    </Tooltip>
  );
};

export default ChargingStationLastUsed;
