// project import
import Locales from "components/Locales";
import ThemeCustomization from "themes";
// import RTLLayout from 'components/RTLLayout';
import { useAuth0 } from "@auth0/auth0-react";
import Snackbar from "components/@extended/Snackbar";
import ScrollTop from "components/ScrollTop";
import { GravityUserProvider } from "contexts/GravityUserContext";
import FeatureFlagProvider from "experimentation/FeatureFlagProvider";
import LaunchDarklyProvider from "experimentation/LaunchDarklyContext";
import GravityRoutes from "routes";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const { isLoading, error, isAuthenticated, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return <div>Redirecting to login...</div>;
  }

  return (
    <GravityUserProvider>
      <LaunchDarklyProvider>
        <FeatureFlagProvider>
          <ThemeCustomization>
            {/* <RTLLayout> */}
            <Locales>
              <ScrollTop>
                <>
                  <GravityRoutes />
                  <Snackbar />
                </>
              </ScrollTop>
            </Locales>
            {/* </RTLLayout> */}
          </ThemeCustomization>
        </FeatureFlagProvider>
      </LaunchDarklyProvider>
    </GravityUserProvider>
  );
};

export default App;
