import { useSelector } from "react-redux";

// material-ui
import { Box, List, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project import
import NavCollapse from "./NavCollapse";
import NavItem from "./NavItem";

// types
import { useFeatureFlags } from "experimentation/FeatureFlagProvider";
import { useMemo } from "react";
import { NavItemType } from "types/menu";
import { RootStateProps } from "types/root";
import { isVisible } from "./isVisible";

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

interface Props {
  item: NavItemType;
}

const NavGroup = ({ item }: Props) => {
  const theme = useTheme();
  const { evaluateFeature } = useFeatureFlags();
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;

  const navCollapse = useMemo(
    () =>
      item.children
        ?.filter((item) => isVisible(item, evaluateFeature))
        .map((menuItem) => {
          switch (menuItem.type) {
            case "collapse":
              return <NavCollapse key={menuItem.id} menu={menuItem} level={1} />;
            case "item":
              return <NavItem key={menuItem.id} item={menuItem} level={1} />;
            default:
              return (
                <Typography key={menuItem.id} variant="h6" color="error" align="center">
                  Fix - Group Collapse or Items
                </Typography>
              );
          }
        }),
    [item, evaluateFeature]
  );

  return (
    <List
      subheader={
        item.title &&
        drawerOpen && (
          <Box sx={{ pl: 3, mb: 1.5 }}>
            <Typography
              variant="subtitle2"
              color={theme.palette.mode === "dark" ? "textSecondary" : "text.secondary"}
            >
              {item.title}
            </Typography>
            {item.caption && (
              <Typography variant="caption" color="secondary">
                {item.caption}
              </Typography>
            )}
          </Box>
        )
      }
      sx={{ mt: drawerOpen && item.title ? 1.5 : 0, py: 0, zIndex: 0 }}
    >
      {navCollapse}
    </List>
  );
};

export default NavGroup;
