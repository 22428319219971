import { Grid, MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  isEqual,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

const RangeShortCut = {
  Today: "range-short-cut-today",
  Yesterday: "range-short-cut-yesterday",
  ThisWeek: "range-short-cut-week",
  ThisMonth: "range-short-cut-month",
  LastMonth: "range-short-cut-last-month",
  YTD: "range-short-cut-ytd",
  Custom: "range-short-cut-custom",
} as const;

type RangeShortCutType = typeof RangeShortCut[keyof typeof RangeShortCut];

export type Selection = {
  start: Date;
  end: Date;
};

const DateRangePicker = ({
  selection,
  onSelectionChanged,
}: {
  selection: Selection;
  onSelectionChanged: (selection: Selection) => void;
}) => {
  const [custom, setCustom] = useState(false);

  const calculateRangeFromShortcut = (shortcut: RangeShortCutType): Selection => {
    const now = new Date();
    let newStart = selection.start;
    let newEnd = selection.end;

    switch (shortcut) {
      case RangeShortCut.Today:
        newStart = startOfDay(now);
        newEnd = endOfDay(now);
        break;
      case RangeShortCut.Yesterday:
        newStart = startOfDay(now);
        newStart.setDate(newStart.getDate() - 1);
        newEnd = endOfDay(newStart);
        break;
      case RangeShortCut.ThisWeek:
        newStart = startOfWeek(now);
        newEnd = endOfWeek(now);
        break;
      case RangeShortCut.ThisMonth:
        newStart = startOfMonth(now);
        newEnd = endOfMonth(now);
        break;
      case RangeShortCut.LastMonth:
        newStart = startOfMonth(now);
        newStart.setDate(0); // last day of previous month
        newEnd = newStart;
        newStart = startOfMonth(newEnd);
        break;
      case RangeShortCut.YTD:
        newStart = startOfYear(now);
        newEnd = endOfDay(now);
        break;
    }
    return { start: newStart, end: newEnd };
  };

  const calculateShortcutFromRange = (selection: Selection): RangeShortCutType => {
    const now = new Date();

    if (
      selection.start.getTime() === startOfDay(now).getTime() &&
      selection.end.getTime() === endOfDay(now).getTime()
    ) {
      return RangeShortCut.Today;
    }

    let newStart = startOfDay(now);
    newStart.setDate(newStart.getDate() - 1);
    let newEnd = endOfDay(newStart);
    if (
      selection.start.getTime() === newStart.getTime() &&
      selection.end.getTime() === newEnd.getTime()
    ) {
      return RangeShortCut.Yesterday;
    }

    if (
      selection.start.getTime() === startOfWeek(now).getTime() &&
      selection.end.getTime() === endOfWeek(now).getTime()
    ) {
      return RangeShortCut.ThisWeek;
    }

    if (
      selection.start.getTime() === startOfMonth(now).getTime() &&
      selection.end.getTime() === endOfMonth(now).getTime()
    ) {
      return RangeShortCut.ThisMonth;
    }

    newStart = startOfMonth(now);
    newStart.setDate(0); // last day of previous month
    newEnd = newStart;
    newStart = startOfMonth(newEnd);
    if (
      selection.start.getTime() === newStart.getTime() &&
      selection.end.getTime() === newEnd.getTime()
    ) {
      return RangeShortCut.LastMonth;
    }

    if (
      selection.start.getTime() === startOfYear(now).getTime() &&
      selection.end.getTime() === endOfDay(now).getTime()
    ) {
      return RangeShortCut.YTD;
    }

    return RangeShortCut.Custom;
  };

  return (
    <Grid container spacing={1} justifyContent={"space-between"}>
      <Grid item xs={3} md="auto">
        <TextField
          select
          fullWidth
          value={calculateShortcutFromRange(selection)}
          onChange={(e) => {
            if (e.target.value === RangeShortCut.Custom) {
              setCustom(true);
              return;
            }
            setCustom(false);
            onSelectionChanged(calculateRangeFromShortcut(e.target.value as RangeShortCutType));
          }}
        >
          {Object.values(RangeShortCut).map((option) => (
            <MenuItem key={option} value={option}>
              <FormattedMessage id={option} />
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs="auto">
        <DatePicker
          readOnly={!custom}
          sx={{ width: 150 }}
          value={selection.start}
          onChange={(value) => {
            if (value && !isEqual(value, selection.start)) {
              onSelectionChanged({ start: value, end: selection.end });
            }
          }}
        />
      </Grid>
      <Grid item xs="auto">
        <DatePicker
          readOnly={!custom}
          sx={{ width: 150 }}
          value={selection.end}
          onChange={(value) => {
            if (value && !isEqual(value, selection.end)) {
              onSelectionChanged({ start: selection.start, end: value });
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DateRangePicker;
