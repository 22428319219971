import { RouteObject } from "react-router";
import { ModuleGuard } from "utils/route-guard/ModuleGuard";
import GarageOperatorIndex from "./GarageOperatorIndex";
import GarageOperatorReservations from "./reports/GarageOperatorReservations";
import GarageOperatorScheduler from "./scheduler/GarageOperatorScheduler";

export const GarageOperatorRoutes: RouteObject = {
  element: <ModuleGuard modules={["garage-operator"]} />,
  path: "garage-operator",
  children: [
    {
      element: <GarageOperatorIndex />,
      children: [
        {
          index: true,
          path: "reservations",
          element: <GarageOperatorReservations />,
        },
        {
          path: "schedule",
          element: <GarageOperatorScheduler />,
        },
      ],
    },
  ],
};
