import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ChargingStation } from "service/api-slice";
import ChargingStationLastSeen from "./ChargingStationLastSeen";
import ChargingStationName from "./ChargingStationName";
import ChargingStationStatusIndicator from "./ChargingStationStatusIndicator";

const columnHelper = createColumnHelper<ChargingStation>();

const columns = [
  columnHelper.accessor("name", {
    cell: (i) => <ChargingStationName chargingStation={i.row.original} />,
    header: () => <FormattedMessage id="name" />,
    meta: { showOnMobile: true },
  }),
  columnHelper.accessor("model", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="model" />,
    meta: { showOnMobile: true },
  }),
  columnHelper.accessor("firmware_version", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="firmware-versions" />,
    meta: { showOnMobile: false },
  }),
  columnHelper.accessor("status", {
    cell: (i) => <ChargingStationStatusIndicator chargingStation={i.row.original} />,
    header: () => <FormattedMessage id="status" />,
    meta: { showOnMobile: true },
  }),
  columnHelper.accessor("status.last_seen", {
    cell: (i) => <ChargingStationLastSeen chargingStation={i.row.original} />,
    header: () => <FormattedMessage id="last-seen" />,
    meta: { showOnMobile: true },
  }),
];

const FaultedChargingStationsTable = ({
  chargingStations,
  onClick,
}: {
  chargingStations: ChargingStation[] | undefined;
  onClick: (cs: ChargingStation) => void;
}) => {
  const table = useReactTable({
    data: chargingStations ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
  });

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {table.getHeaderGroups()[0].headers.map((header) => (
              <TableCell key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <React.Fragment key={row.id}>
              <TableRow
                hover={true}
                style={{ cursor: "pointer" }}
                sx={row.original.status?.errors && { "& > *": { borderBottom: "unset" } }}
                onClick={() => onClick(row.original)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>
              {row.original.status?.errors &&
                row.original.status?.errors?.length > 0 &&
                row.original.status?.errors?.map((error, idx) => {
                  const count = row.original.status!!.errors!!.length;
                  return (
                    <TableRow
                      key={`${row.id}_err_${idx}_${error.code}`}
                      hover={false}
                      sx={idx < count - 1 ? { "& > *": { borderBottom: "unset" } } : undefined}
                    >
                      <TableCell
                        style={{ paddingBottom: 10, paddingTop: 0 }}
                        colSpan={row.getVisibleCells().length}
                      >
                        <Alert severity="error">
                          ERR{error.code} : {error.name} - {error.common_causes.join("; ")}
                        </Alert>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FaultedChargingStationsTable;
