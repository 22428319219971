import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { sec } from "utils/security";

const useApiDownload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const ref = useRef<HTMLAnchorElement>(null);

  const download = async (path: string) => {
    setIsLoading(true);
    const token = await sec.getAccessTokenSilently();
    const { data, headers } = await axios.get<Blob>(
      `${process.env.REACT_APP_API_BASE_URL}/${path}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const fileName = headers["content-disposition"]?.split("filename=")[1];
    setFileName(fileName);

    const contentType = headers["content-type"]?.toString();
    const props = contentType ? { type: contentType } : undefined;
    const downloadUrl = window.URL.createObjectURL(new Blob([data], props));
    setUrl(downloadUrl);
  };

  useEffect(() => {
    if (url) {
      ref.current?.setAttribute("href", url);
      if (fileName) {
        ref.current?.setAttribute("download", fileName);
      }
      ref.current?.click();
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    }
  }, [url, ref, fileName, setIsLoading]);

  return { download, isLoading, ref };
};

export default useApiDownload;
