import {
  Alert,
  AlertTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import TransactionVehicleInfo from "pages/transactions/components/TransactionVehicleInfo";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ChargingStation } from "service/api-slice";
import ChargingStationStatusIndicator from "./ChargingStationStatusIndicator";
import PumpStateChip from "./PumpStateChip";
import TemperaturesChip from "./TemperaturesChip";

const columnHelper = createColumnHelper<ChargingStation>();

const columns = [
  columnHelper.accessor("name", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="name" />,
  }),
  columnHelper.accessor((row) => row.current_transaction?.vehicle, {
    id: "vehicle",
    cell: (i) => {
      if (i.row.original.current_transaction) {
        return <TransactionVehicleInfo transaction={i.row.original.current_transaction} />;
      }
      return "-";
    },
    header: () => <FormattedMessage id="vehicle" />,
  }),
  columnHelper.accessor("current_transaction", {
    cell: (i) => i.getValue()?.charging_state ?? "-",
    header: () => "State",
  }),
  columnHelper.accessor((row) => row.current_transaction?.last_meter_values, {
    id: "time-remaining",
    cell: (i) => {
      const soc = i.getValue()?.soc;
      const time_remaining = i.getValue()?.time_remaining;
      if (soc) {
        return `${soc}% (${time_remaining} min remaining)`;
      }
      return "-";
    },
    header: () => <FormattedMessage id="soc" />,
  }),
  columnHelper.accessor((row) => row.current_transaction?.last_meter_values, {
    id: "power",
    cell: (i) => {
      const power = i.getValue()?.power;
      if (power && power > 0) {
        return `${power / 1000} kW`;
      }
      return "-";
    },
    header: () => <FormattedMessage id="power" />,
  }),
  columnHelper.accessor((row) => row.current_transaction?.last_meter_values, {
    id: "temp",
    cell: (i) => {
      const tempP = i.getValue()?.temp_p;
      const tempN = i.getValue()?.temp_n;
      return <TemperaturesChip tempN={tempN} tempP={tempP} />;
    },
    header: () => <FormattedMessage id="temperatures" />,
  }),
  columnHelper.accessor("pump_state", {
    cell: (i) => <PumpStateChip cooling={i.getValue()} />,
    header: () => <FormattedMessage id="cooling" />,
  }),
  columnHelper.accessor("status", {
    cell: (i) => <ChargingStationStatusIndicator chargingStation={i.row.original} />,
    header: () => <FormattedMessage id="status" />,
  }),
];

const OccupiedChargingStationsTable = ({
  chargingStations,
  onClick,
}: {
  chargingStations: ChargingStation[];
  onClick: (cs: ChargingStation) => void;
}) => {
  const table = useReactTable({
    data: chargingStations ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
  });

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {table.getHeaderGroups()[0].headers.map((header) => {
              return (
                <TableCell key={`${header.id}`} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <React.Fragment key={row.id}>
              <TableRow
                hover={true}
                onClick={() => onClick(row.original)}
                style={{ cursor: "pointer" }}
                sx={row.original.status?.errors && { "& > *": { borderBottom: "unset" } }}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell key={`${cell.id}-${cell.column.columnDef.header}`}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>

              {row.original.status?.errors &&
                row.original.status?.errors?.length > 0 &&
                row.original.status?.errors?.map((error, idx) => {
                  const count = row.original.status!!.errors!!.length;
                  return (
                    <TableRow
                      key={`${row.id}_err_${idx}_${error.code}`}
                      hover={false}
                      sx={idx < count - 1 ? { "& > *": { borderBottom: "unset" } } : undefined}
                    >
                      <TableCell
                        style={{ paddingBottom: 10, paddingTop: 0 }}
                        colSpan={row.getVisibleCells().length}
                      >
                        <AlertTitle>{error.name}</AlertTitle>
                        <Alert severity="warning">
                          ERR{error.code} : {error.name} - {error.common_causes.join("; ")}
                        </Alert>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OccupiedChargingStationsTable;
