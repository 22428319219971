import MainCard from "components/MainCard";
import { LogsTable } from "modules/site-operator/maintenance/components/LogsTable";
import { useModuleContext } from "types/context";

const LogRequestsPage = () => {
  const { filter } = useModuleContext();
  return (
    <MainCard content={false}>
      <LogsTable range={filter.timeRange} disableCreate={true} showChargingStation={true} />
    </MainCard>
  );
};

export default LogRequestsPage;
