import { useTheme } from "@mui/material/styles";
import AnalyticsDataCard from "components/cards/statistics/AnalyticsDataCard";
import { eachDayOfInterval } from "date-fns";
import useConfig from "hooks/useConfig";
import { useMemo } from "react";
import ReactApexChart, { Props as ChartProps } from "react-apexcharts";
import { Vehicle, useListVehiclesQuery } from "service/api-slice";
import { useModuleContext } from "types/context";

// ==============================|| USER CARD CHART ||============================== //

const NewVehicles = (props: { height: number | null; orgId?: string }) => {
  const theme = useTheme();
  const { mode } = useConfig();
  const { filter } = useModuleContext();

  const { data } = useListVehiclesQuery(
    {
      orgId: props.orgId,
      page: 0,
      count: 500,
      startTime: filter.timeRange.start.toISOString(),
      endTime: filter.timeRange.end.toISOString(),
    },
    {
      pollingInterval: 60_000,
      skipPollingIfUnfocused: true,
    }
  );

  const prevStart = useMemo(() => {
    return new Date(
      filter.timeRange.start.valueOf() -
        (filter.timeRange.end.valueOf() - filter.timeRange.start.valueOf())
    );
  }, [filter.timeRange]);

  const { data: pastRange } = useListVehiclesQuery(
    {
      orgId: props.orgId,
      page: 0,
      count: 500,
      startTime: prevStart.toISOString(),
      endTime: filter.timeRange.start.toISOString(),
    },
    {
      pollingInterval: 60_000,
      skipPollingIfUnfocused: true,
    }
  );

  const change = useMemo(() => {
    if (!data || !pastRange) {
      return;
    }

    var totalPast = pastRange.data.length;
    if (totalPast === 0) {
      totalPast = 1;
    }

    var result = parseFloat(
      (((data.data.length - pastRange.data.length) / totalPast) * 100).toFixed(0)
    );
    if (result === 0) {
      return;
    }

    return result;
  }, [data, pastRange]);

  const totalVehicles = useMemo(() => {
    if (!data) {
      return 0;
    }

    return data.data.length;
  }, [data]);

  const series = useMemo(() => {
    if (!data) {
      return [
        {
          name: "New Vehicles",
          type: "bar",
          data: [{ x: "0", y: 0 }],
        },
      ];
    }

    const dict = data.data.reduce((acc: Map<string, number>, v: Vehicle) => {
      const date = new Date(v.created_at).toDateString();
      if (!acc.get(date)) {
        acc.set(date, 0);
      }
      acc.set(date, acc.get(date)! + 1);
      return acc;
    }, new Map<string, number>());

    const seriesData = eachDayOfInterval({
      start: filter.timeRange.start,
      end: filter.timeRange.end,
    }).map((d) => ({
      x: d.toDateString(),
      y: dict.get(d.toDateString()) ?? 0,
    }));

    return [
      {
        name: "New Vehicles",
        data: seriesData,
      },
    ];
  }, [data, filter.timeRange]);

  // chart options
  const areaChartOptions = useMemo<ChartProps>(() => {
    return {
      colors: [
        theme.palette.primary.main,
        theme.palette.primary[700],
        theme.palette.secondary.main,
      ],
      chart: {
        id: "new-vehicles-chart",
        height: 100,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: "numeric",
        tickPlacement: ["on"],
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        tooltip: {
          enabled: false,
        },
        labels: {
          show: true,
          formatter: (value: string) => {
            let date = new Date(value);
            return `${date.getMonth() + 1}/${date.getDate()}`;
          },
        },
      },
      yaxis: {
        show: false,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      tooltip: {
        theme: mode === "dark" ? "dark" : "light",
        x: {
          show: true,
        },
        y: {
          formatter: (value: number) => {
            return value.toFixed(0);
          },
        },
      },
      grid: {
        show: false,
      },
    };
  }, [mode, theme]);

  return (
    <AnalyticsDataCard
      title="new-vehicles-chart-label"
      count={`${totalVehicles} New Vehicles`}
      percentage={change}
      isLoss={change ? change < 0 : null}
    >
      <ReactApexChart
        options={areaChartOptions}
        series={series}
        type="bar"
        height={props.height?.toString()}
      />
    </AnalyticsDataCard>
  );
};

export default NewVehicles;
