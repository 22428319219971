// material-ui
import {
  Button,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";

// project import
import MainCard from "components/MainCard";
import { Link as RouterLink } from "react-router-dom";

import { useGetChargePointsQuery } from "../../../service/api-slice";
// import { forwardRef } from "react";

const ChargePointsPage = () => {
  const { data, isLoading, isError } = useGetChargePointsQuery();

  return (
    <>
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        {data?.data &&
          data.data.map((cp) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={cp.id}>
              <MainCard title={cp.serial_number} content={false}>
                <Box>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow hover={false}>
                          <TableCell>S/N</TableCell>
                          <TableCell>{cp.serial_number}</TableCell>
                        </TableRow>
                        <TableRow hover={false}>
                          <TableCell>Model</TableCell>
                          <TableCell>{cp.model}</TableCell>
                        </TableRow>
                        <TableRow hover={false}>
                          <TableCell>Connectors</TableCell>
                          <TableCell>1</TableCell>
                        </TableRow>
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell colSpan={2} align="right">
                            <Button component={RouterLink} to={cp.id}>
                              Details
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Box>
              </MainCard>
            </Grid>
          ))}

        {(isLoading || isError) &&
          [...Array(5).keys()].map((i) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={4} key={i}>
                <MainCard title={<Skeleton />}>
                  <Skeleton />
                  <Skeleton />
                </MainCard>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default ChargePointsPage;
