import { Chip } from "@mui/material";
import { PumpState } from "modules/site-operator/pumps/records";
import { FormattedMessage, useIntl } from "react-intl";

const PumpStateChip = ({ cooling }: { cooling?: PumpState | undefined | null }) => {
  const intl = useIntl();

  const pumpStation = cooling?.pump_station_id.split("-")[1];
  const pumpId = cooling?.pump_id;
  const isRunning = cooling?.running;
  const isDisabled = cooling?.disabled;

  if (cooling) {
    return (
      <>
        {isDisabled && (
          <Chip
            label={`${pumpStation} ${pumpId} ${intl.formatMessage({ id: "pump-disabled" })}`}
            size="small"
            color="error"
          />
        )}
        {!isDisabled && (
          <span>
            {pumpStation} {pumpId}&nbsp; [
            {isRunning ? (
              <strong>
                <FormattedMessage id="pump-running" />
              </strong>
            ) : (
              <FormattedMessage id="pump-off" />
            )}
            ]
          </span>
        )}
      </>
    );
  }
  return <span>{"-"}</span>;
};

export default PumpStateChip;
