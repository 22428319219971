import { CalendarOutlined, ScheduleOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { NavItemType } from "types/menu";

export const GarageOperatorMenu: NavItemType = {
  id: "garage-operator-menu",
  type: "group",
  url: "/garage-operator",
  title: <FormattedMessage id="garage-operator-menu" />,
  breadcrumbs: true,
  children: [
    {
      id: "schedule",
      title: <FormattedMessage id="schedule" />,
      type: "item",
      url: "/garage-operator/schedule",
      icon: CalendarOutlined,
      breadcrumbs: true,
    },
    {
      id: "reservations",
      title: <FormattedMessage id="reservations" />,
      type: "item",
      url: "/garage-operator/reservations",
      icon: ScheduleOutlined,
      breadcrumbs: true,
    },
  ],
};
