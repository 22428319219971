import { useSelector } from "react-redux";

// material-ui
import { Box, Typography } from "@mui/material";

// types
import { RootStateProps } from "types/root";

// project import
import { useModules } from "contexts/GravityUserContext";
import { useFeatureFlags } from "experimentation/FeatureFlagProvider";
import menuItem from "menu-items";
import { useMemo } from "react";
import NavGroup from "./NavGroup";
import { isVisible } from "./isVisible";

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { evaluateFeature } = useFeatureFlags();
  const allowedModules = useModules();

  const navItems = useMemo(
    () =>
      menuItem.items
        .filter((item) => allowedModules.includes(item.id))
        .flatMap((item) => item.items)
        .filter((item) => isVisible(item, evaluateFeature))
        .map((item) => {
          switch (item.type) {
            case "group":
              return <NavGroup key={item.id} item={item} />;
            default:
              return (
                <Typography key={item.id} variant="h6" color="error" align="center">
                  Fix - Navigation Group
                </Typography>
              );
          }
        }),
    [allowedModules, evaluateFeature]
  );

  const { drawerOpen } = menu;

  return <Box sx={{ pt: drawerOpen ? 2 : 0, "& > ul:first-of-type": { mt: 0 } }}>{navItems}</Box>;
};

export default Navigation;
