import { Alert, AlertTitle, Button, Grid, TextField, Typography } from "@mui/material";
import StateSelector, { State } from "components/StateSelector";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  Vehicle,
  VehicleOwner,
  useCreateVehicleMutation,
  useListVehiclesQuery,
} from "service/api-slice";
import OwnerSelector from "./OwnerSelector";

const CreateVehicleComponent = ({ onCreated }: { onCreated: (v: Vehicle) => void }) => {
  const [vin, setVin] = useState<string>();
  const [color, setColor] = useState<string>();
  const [state, setState] = useState<State | null>();
  const [owner, setOwner] = useState<VehicleOwner | null>();
  const [licensePlate, setLicensePlate] = useState<string>();

  const [createVehicle, createVehicleResult] = useCreateVehicleMutation();

  const { refetch } = useListVehiclesQuery({ count: 50, page: 0 });

  const submit = () => {
    console.log(vin, color, state, licensePlate);
    if (!vin || vin.length === 0) {
      return;
    }

    createVehicle({
      vin: vin.toUpperCase(),
      color: color?.toUpperCase(),
      license_plate_state: state?.value,
      license_plate: licensePlate?.toUpperCase(),
      owner_id: owner!!.id,
    });
  };

  useEffect(() => {
    if (createVehicleResult.isSuccess) {
      setVin(undefined);
      setColor(undefined);
      setState(undefined);
      setLicensePlate(undefined);
      setOwner(undefined);
      onCreated(createVehicleResult.data.data);
      refetch();
    } else if (createVehicleResult.isError) {
      console.log(createVehicleResult.error);
    }
  }, [createVehicleResult, refetch, onCreated]);

  return (
    <Grid container spacing={2}>
      <Grid item alignSelf="center" xs={12} md={1.2} lg={1.2}>
        <Typography variant="h5">
          <FormattedMessage id="add-vehicle" />
        </Typography>
      </Grid>
      <Grid item xs={12} md={2.2} lg={2.2}>
        <TextField
          id="vin"
          inputProps={{ style: { textTransform: "uppercase" } }}
          label={<FormattedMessage id="vin" />}
          value={vin}
          onChange={(e) => setVin(e.target.value)}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={2.5} lg={2.5}>
        <OwnerSelector
          onOwnerChange={(owner) => setOwner(owner)}
          label={<FormattedMessage id="owner" />}
        />
      </Grid>
      <Grid item xs={12} md={1} lg={1}>
        <TextField
          id="color"
          value={color}
          inputProps={{ style: { textTransform: "uppercase" } }}
          onChange={(e) => setColor(e.target.value)}
          label={<FormattedMessage id="color" />}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={2} lg={2}>
        <StateSelector
          onStateChange={(state) => setState(state)}
          label={<FormattedMessage id="state" />}
        />
      </Grid>
      <Grid item xs={12} md={2} lg={2}>
        <TextField
          id="license-plate"
          label={<FormattedMessage id="license-plate" />}
          value={licensePlate}
          inputProps={{ style: { textTransform: "uppercase" } }}
          onChange={(e) => setLicensePlate(e.target.value)}
          variant="outlined"
          disabled={createVehicleResult.isLoading}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={1} lg={1} alignSelf="center">
        <Button variant="outlined" fullWidth onClick={submit}>
          <FormattedMessage id="add" />
        </Button>
      </Grid>

      {createVehicleResult.isError && (
        <Grid item xs={12} md={12} lg={12} alignSelf="center">
          <Alert sx={{ mt: 2 }} severity="error">
            <AlertTitle>An error occured</AlertTitle>
            {(createVehicleResult.error!! as any).data.message}
          </Alert>
        </Grid>
      )}

      {createVehicleResult.isSuccess && (
        <Grid item xs={12} md={12} lg={12} alignSelf="center">
          <Alert sx={{ mt: 2 }} severity="success">
            <AlertTitle>
              <FormattedMessage id="vehicle-created" />
            </AlertTitle>
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default CreateVehicleComponent;
