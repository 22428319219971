import { Button, Grid, OutlinedInput, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import { Fragment, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  useGetParkingScheduleDefaultQuery,
  useUpdateParkingScheduleDefaultMutation,
} from "service/api-slice";
import { useDispatch } from "react-redux";
import { openSnackbarError, openSnackbarSuccess } from "store/reducers/snackbar";

interface weekDayDefaultHook {
  dayOfWeek: string;
  text: string | undefined;
  setText: (text: string) => void;
  isError: boolean;
  maxParked?: number;
}

const useWeekDayDefault = (dayOfWeek: string): weekDayDefaultHook => {
  const [text, setText] = useState<string>();
  const state = useMemo(() => {
    const isError = text === undefined || text?.length === 0 || !/\d+/.test(text ?? "");
    return {
      dayOfWeek: dayOfWeek,
      text: text,
      setText: setText,
      isError: isError,
      maxParked: !isError ? parseInt(text ?? "") : undefined,
    };
  }, [dayOfWeek, text, setText]);

  return state;
};

const useWeekDayDefaults = () => {
  const [isInitialized, setIsInitialzed] = useState<boolean>(false);

  const sunday = useWeekDayDefault("sunday");
  const monday = useWeekDayDefault("monday");
  const tuesday = useWeekDayDefault("tuesday");
  const wednesday = useWeekDayDefault("wednesday");
  const thursday = useWeekDayDefault("thursday");
  const friday = useWeekDayDefault("friday");
  const saturday = useWeekDayDefault("saturday");

  const state = useMemo(() => {
    const allDays = [sunday, monday, tuesday, wednesday, thursday, friday, saturday];
    if (!isInitialized) {
      return {
        isInitialized: false,
        initialize: (init: Map<string, number>) => {
          sunday.setText(init.get("sunday")?.toString() ?? "");
          monday.setText(init.get("monday")?.toString() ?? "");
          tuesday.setText(init.get("tuesday")?.toString() ?? "");
          wednesday.setText(init.get("wednesday")?.toString() ?? "");
          thursday.setText(init.get("thursday")?.toString() ?? "");
          friday.setText(init.get("friday")?.toString() ?? "");
          saturday.setText(init.get("saturday")?.toString() ?? "");
          setIsInitialzed(true);
        },
      };
    }
    return {
      isInitialized: true,

      sunday: sunday,
      monday: monday,
      tuesday: tuesday,
      wednesday: wednesday,
      thursday: thursday,
      friday: friday,
      saturday: saturday,

      isError: allDays.some((d) => d.isError),
      allDays: allDays,
    };
  }, [
    isInitialized,
    setIsInitialzed,
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
  ]);

  return state;
};

const DefaultWeekday = ({ state }: { state?: weekDayDefaultHook }) => {
  if (!state) {
    return <></>;
  }
  return (
    <Fragment>
      <Grid item xs={6}>
        <FormattedMessage id={state.dayOfWeek} />
      </Grid>
      <Grid item xs={6}>
        <OutlinedInput
          error={state.isError}
          defaultValue={state.text}
          onChange={(e) => state.setText(e.target.value)}
        />
      </Grid>
    </Fragment>
  );
};

const DefaultWeekdays = ({ siteId }: { siteId: string }) => {
  const defaultQuery = useGetParkingScheduleDefaultQuery({ site_id: siteId });
  const [updateDefault, updateDefaultResult] = useUpdateParkingScheduleDefaultMutation();
  const defaults = useWeekDayDefaults();

  const dispatch = useDispatch();

  useEffect(() => {
    if (updateDefaultResult.isSuccess) {
      dispatch(
        openSnackbarSuccess({
          message: "Default updated.",
        })
      );
      updateDefaultResult.reset();
    }
    if (updateDefaultResult.isError) {
      dispatch(
        openSnackbarError({
          title: "Failed to update default",
          message: JSON.stringify(updateDefaultResult.error),
        })
      );
      updateDefaultResult.reset();
    }
  }, [dispatch, updateDefaultResult]);

  useEffect(() => {
    if (defaultQuery.isError) {
      dispatch(
        openSnackbarError({
          title: "Failed to read default",
          message: JSON.stringify(defaultQuery.error),
        })
      );
    }
    if (defaultQuery.isSuccess && defaults.initialize) {
      const d = new Map(defaultQuery.data?.data.schedule?.map((i) => [i.day, i.max_parked]));
      defaults.initialize(d);
    }
  }, [dispatch, defaultQuery, defaults]);

  const onSaveClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (!defaults.allDays) {
      return;
    }
    updateDefault({
      site_id: siteId,
      schedule: defaults.allDays.map((d) => ({
        day: d.dayOfWeek,
        max_parked: d.maxParked ?? 0,
      })),
    });
  };

  return (
    <MainCard title={<FormattedMessage id="default-schedule" />} content={true}>
      <Grid container spacing={1}>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">
            <FormattedMessage id="slots-per-day" />
          </Typography>
        </Grid>
        <DefaultWeekday state={defaults.sunday} />
        <DefaultWeekday state={defaults.monday} />
        <DefaultWeekday state={defaults.tuesday} />
        <DefaultWeekday state={defaults.wednesday} />
        <DefaultWeekday state={defaults.thursday} />
        <DefaultWeekday state={defaults.friday} />
        <DefaultWeekday state={defaults.saturday} />
        <Grid item xs={12}>
          <Button
            variant="contained"
            disabled={defaults.isError}
            color="primary"
            onClick={onSaveClick}
          >
            <FormattedMessage id="save" />
          </Button>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default DefaultWeekdays;
