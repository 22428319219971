import { useOutletContext } from "react-router";
import { ModuleType } from "./modules";

export type Context = {
  filter: Filter;
  module: ModuleType;
};

export type Filter = {
  timeRange: { start: Date; end: Date };
  tags?: string[];
  siteId?: string;
  orgId?: string;
};

export function useModuleContext() {
  return useOutletContext<Context>();
}
