// material-ui
import { Box, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";

// project import
import Search from "./Search";
// import Message from './Message';
import Profile from "./Profile";
// import Notification from './Notification';
import MobileSection from "./MobileSection";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <>
      {!matchesXs && <Search />}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}
      {/* <Notification /> */}
      {/* <Message /> */}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
