import { LoadingButton } from "@mui/lab";
import { TableCell, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSendEmailReceiptMutation } from "service/api-slice";
import { dispatch } from "store";
import { openSnackbarError, openSnackbarSuccess } from "store/reducers/snackbar";
import isValidEmail from "utils/email";

const SendReceipt = ({ paymentIntentId }: { paymentIntentId: string }) => {
  const intl = useIntl();

  const [sendEmailReceipt, sendEmailReceiptResult] = useSendEmailReceiptMutation();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const sendReceipt = () => {
    if (!validate()) {
      return;
    }
    sendEmailReceipt({
      paymentIntentId: paymentIntentId,
      email: email,
    });
  };

  const validate = () => {
    const valid = isValidEmail(email);
    setEmailError(!valid);
    return valid;
  };

  useEffect(() => {
    if (sendEmailReceiptResult.isSuccess) {
      dispatch(
        openSnackbarSuccess({
          message: intl.formatMessage({ id: "receipt-sent" }),
        })
      );
    } else if (sendEmailReceiptResult.isError) {
      console.error(sendEmailReceiptResult.error);
      dispatch(
        openSnackbarError({
          message: (sendEmailReceiptResult.error as any).data.message,
          title: intl.formatMessage({ id: "error-occured" }),
        })
      );
    }
  }, [sendEmailReceiptResult, intl]);

  return (
    <TableRow hover={false}>
      <TableCell sx={{ verticalAlign: "top" }}>
        <TextField
          size="small"
          fullWidth
          value={email}
          onChange={(e) => {
            setEmailError(false);
            setEmail(e.target.value);
          }}
          label={<FormattedMessage id="email" />}
          error={emailError}
          {...(emailError ? { helperText: <FormattedMessage id="enter-valid-email" /> } : {})}
        />
      </TableCell>
      <TableCell sx={{ verticalAlign: "top" }}>
        <LoadingButton
          variant="contained"
          size="small"
          fullWidth
          disabled={emailError || email === ""}
          loading={sendEmailReceiptResult.isLoading}
          onClick={sendReceipt}
        >
          <FormattedMessage id="send-receipt" />
        </LoadingButton>
      </TableCell>
    </TableRow>
  );
};

export default SendReceipt;
