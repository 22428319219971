import { RouteObject } from "react-router";
import { ModuleGuard } from "utils/route-guard/ModuleGuard";
import { PaymentPage } from "./payment";
import { PaymentChargingStationsPage } from "./payment/list";
import { PaymentChargingStationPage } from "./payment/payment";
import { PaymentStatusPage } from "modules/playground/payment/status";

export const PlaygroundRoutes: RouteObject = {
  element: <ModuleGuard modules={["playground"]} />,
  path: "/playground",
  children: [
    {
      path: "payment",
      element: <PaymentPage />,
      children: [
        {
          index: true,
          element: <PaymentChargingStationsPage />,
        },
        {
          path: ":charging_station_id",
          element: <PaymentChargingStationPage />,
        },
        {
          path: ":charging_station_id/complete",
          element: <PaymentStatusPage />,
        },
      ],
    },
  ],
};
