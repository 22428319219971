import DefaultDashboard from "modules/fleet-operator/dashboards/default";
import { RouteObject } from "react-router";
import { ModuleGuard } from "utils/route-guard/ModuleGuard";
import VehicleDetails from "../../components/vehicles/VehicleDetails";
import VehiclesIndex from "../../components/vehicles/VehiclesIndex";
import VehiclesList from "../../components/vehicles/VehiclesList";
import FleetOperatorIndex from "./FleetOperatorIndex";
import { ChargingSessionDetailPage } from "./charging-sessions/ChargingSessionDetailPage";
import ChargingSessionsIndex from "./charging-sessions/ChargingSessionsIndex";
import ChargingSessionsList from "./charging-sessions/ChargingSessionsList";

export const FleetOperatorRoutes: RouteObject = {
  element: <ModuleGuard modules={["fleet-operator"]} />,
  path: "fleet-operator",
  children: [
    {
      element: <FleetOperatorIndex />,
      children: [
        {
          index: true,
          element: <DefaultDashboard />,
        },
        {
          path: "charging-sessions",
          element: <ChargingSessionsIndex />,
          children: [
            {
              index: true,
              element: <ChargingSessionsList />,
            },
            {
              path: ":csId/transactions/:txId",
              element: <ChargingSessionDetailPage />,
            },
          ],
        },
        {
          path: "vehicles",
          element: <VehiclesIndex />,
          children: [
            {
              index: true,
              element: <VehiclesList />,
            },
            {
              path: ":id",
              element: <VehicleDetails />,
            },
          ],
        },
      ],
    },
  ],
};
