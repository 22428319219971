import { SxProps, TableCell, TableRow } from "@mui/material";
import { flexRender, Row, RowData } from "@tanstack/react-table";
import { useCallback } from "react";

export type RowClickHandler<TData extends RowData> = (item: TData) => void;
export type RowClickHandlerProvider<TData extends RowData> = (
  item: TData
) => RowClickHandler<TData> | null;

const GravityTableRow = <TData extends RowData>({
  row,
  sx,
  rowClickHandlerProvider,
  children,
}: {
  row: Row<TData>;
  sx?: (item: TData) => SxProps | undefined;
  rowClickHandlerProvider?: RowClickHandlerProvider<TData>;
  children?: React.ReactNode;
}) => {
  const onRowClickHandler = rowClickHandlerProvider
    ? rowClickHandlerProvider(row.original)
    : undefined;
  const onClick = useCallback(() => {
    if (onRowClickHandler) {
      onRowClickHandler(row.original);
    }
  }, [row, onRowClickHandler]);
  return (
    <TableRow
      hover={true}
      sx={{
        ...(sx ? sx(row.original) : {}),
        cursor: onRowClickHandler ? "pointer" : "default",
      }}
      onClick={onClick}
    >
      {row.getVisibleCells().map((cell) => {
        if (!cell.column.getIsVisible()) {
          return null;
        }
        return (
          <TableCell key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default GravityTableRow;
