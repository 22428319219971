import {
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import MainCard from "components/MainCard";

import { GetSessionsArgs, Session, useGetRecentSessionsQuery } from "service/api-slice";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import { endOfDay, startOfYear } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";

const now = new Date();
const defaultStartTime = startOfYear(now);
const defaultEndTime = endOfDay(now);

const columnHelper = createColumnHelper<Session>();

const columns = [
  columnHelper.accessor("charge_point_id", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="charge_point" />,
  }),
  columnHelper.accessor("start_time", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="start_time" />,
  }),
  columnHelper.accessor("meter_start", {
    cell: (i) => `${i.getValue() / 1000.0} kWh`,
    header: () => <FormattedMessage id="meter_start" />,
  }),
  columnHelper.accessor("end_time", {
    cell: (i) => i.getValue()?.toString() ?? "-",
    header: () => <FormattedMessage id="end_time" />,
  }),
  columnHelper.accessor("meter_end", {
    cell: (i) => (i.getValue() == null ? "-" : `${i.getValue() / 1000.0} kWh`),
    header: () => <FormattedMessage id="meter_end" />,
  }),
  columnHelper.display({
    id: "total_power",
    cell: (i) =>
      i.getValue() == null
        ? "-"
        : `${
            ((i.row.getValue("meter_end") as number) - (i.row.getValue("meter_start") as number)) /
            1000.0
          } kWh`,
    header: () => <FormattedMessage id="total_power" />,
  }),
  columnHelper.accessor("end_reason", {
    cell: (i) => i.getValue()?.toString() ?? "-",
    header: () => <FormattedMessage id="end_reason" />,
  }),
];

export const SessionsTable = (props: { cpId?: string | undefined }) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });

  const [args, setSessionArgs] = useState<GetSessionsArgs>({
    start: defaultStartTime.toISOString(),
    end: defaultEndTime.toISOString(),
    siteId: "10",
    page: pageIndex,
    count: pageSize,
    cpId: props.cpId,
  });

  const { data, isLoading, isError } = useGetRecentSessionsQuery(args);

  const pagination = useMemo(() => {
    setSessionArgs({
      start: defaultStartTime.toISOString(),
      end: defaultEndTime.toISOString(),
      siteId: "10",
      page: pageIndex,
      count: pageSize,
      cpId: props.cpId,
    });
    return {
      pageIndex,
      pageSize,
    };
  }, [props.cpId, pageIndex, pageSize]);

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    pageCount: data?.meta.pagination.page_count ?? -1,
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (props.cpId) {
      table.getColumn("charge_point_id")?.toggleVisibility(false);
    }
  });

  return (
    <TableContainer>
      <Table sx={{ minWidth: 350 }} aria-label="Charger Sessions Table">
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableCell key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>
        <TableBody>
          {(isLoading || isError) &&
            table.getHeaderGroups().map((headerGroup) => {
              return [...Array(10).keys()].map((i) => (
                <TableRow key={i}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableCell key={`${i}-${header.id}`} colSpan={header.colSpan}>
                        <Skeleton />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ));
            })}
          {table.getRowModel().rows.map((row) => {
            return (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell sx={{ p: 2 }} colSpan={table.getAllLeafColumns().length}>
              <TablePagination
                gotoPage={table.setPageIndex}
                totalPages={table.getPageCount()}
                setPageSize={table.setPageSize}
                pageIndex={pageIndex}
                pageSize={pageSize}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const SessionsPage = () => {
  return (
    <MainCard content={false}>
      <SessionsTable />
    </MainCard>
  );
};

export default SessionsPage;

interface TablePaginationProps {
  gotoPage: (value: number) => void;
  setPageSize: (value: number) => void;
  pageIndex: number;
  pageSize: number;
  totalPages: number;
}

export const TablePagination = ({
  gotoPage,
  totalPages,
  setPageSize,
  pageSize,
  pageIndex,
}: TablePaginationProps) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    gotoPage(value - 1);
  };

  const handleChange = (event: SelectChangeEvent<number>) => {
    setPageSize(+event.target.value);
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between" sx={{ width: "auto" }}>
      <Grid item>
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="caption" color="secondary">
              <FormattedMessage id="rows-per-page" />
            </Typography>
            <FormControl sx={{ m: 1 }}>
              <Select
                id="demo-controlled-open-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={pageSize}
                onChange={handleChange}
                size="small"
                sx={{ "& .MuiSelect-select": { py: 0.75, px: 1.25 } }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Typography variant="caption" color="secondary">
            <FormattedMessage id="go-to-page" />
          </Typography>
          <TextField
            size="small"
            type="number"
            // @ts-ignore
            value={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) : 0;
              gotoPage(page - 1);
            }}
            sx={{
              "& .MuiOutlinedInput-input": { py: 0.75, px: 1.25, width: 36 },
            }}
          />
        </Stack>
      </Grid>
      <Grid item sx={{ mt: { xs: 2, sm: 0 } }}>
        <Pagination
          count={totalPages}
          page={pageIndex + 1}
          onChange={handleChangePagination}
          color="primary"
          variant="combined"
          showFirstButton
          showLastButton
        />
      </Grid>
    </Grid>
  );
};
