import MainCard from "components/MainCard";
import { OrganizationsTable } from "./components/OrganizationsTable";

export const OrganizationListPage = () => {
  return (
    <MainCard content={false}>
      <OrganizationsTable />
    </MainCard>
  );
};
