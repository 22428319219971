import { useMe } from "contexts/GravityUserContext";
import { LDClient, LDMultiKindContext, initialize } from "launchdarkly-js-client-sdk";
import { createContext, useContext, useMemo, useState } from "react";

interface LaunchDarklyContextProps {
  client: LDClient;
}

const LaunchDarklyContext = createContext<LaunchDarklyContextProps>({
  client: {} as LDClient,
});

export type LaunchDarklyProviderProps = {
  children: React.ReactNode;
};

const client = initialize(process.env.REACT_APP_LD_KEY as string, {
  kind: "user",
  anonymous: true,
});

const LaunchDarklyProvider = ({ children }: LaunchDarklyProviderProps) => {
  const user = useMe();
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);

  let context: LDMultiKindContext = useMemo(() => {
    return {
      kind: "multi",
      user: {
        kind: "user",
        key: user.id,
        name: user.given_name + " " + user.family_name,
        email: user.email,
      },
      organization: {
        kind: "organization",
        key: user.organization.id,
        name: user.organization.name,
      },
    };
  }, [user]);

  const memoClient = useMemo(() => {
    client
      .waitForInitialization()
      .then(() => {
        client
          .identify(context)
          .then(() => {
            setLoading(false);
          })
          .catch((e) => {
            console.error("LD failed to set context", e);
            setFailed(true);
          });
      })
      .catch((e) => {
        console.error("LD failed to initialize", e);
        setFailed(true);
      });
    return {
      client,
    };
  }, [context]);

  return (
    <LaunchDarklyContext.Provider value={memoClient}>
      {!loading && children}
      {failed && <div>Failed to initialize LaunchDarkly</div>}
    </LaunchDarklyContext.Provider>
  );
};

export const useLaunchDarkly = () => {
  return useContext(LaunchDarklyContext).client;
};

export default LaunchDarklyProvider;
