import { Grid, MenuItem, Stack, TextField } from "@mui/material";
import MainCard from "components/MainCard";
import ChargingStationFaults from "components/charts/ChargingStationFaults";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useGetChargingStationsQuery } from "service/api-slice";
import { useModuleContext } from "types/context";
import { ChargingStationFaultsTable } from "./components/ChargingStationFaultsTable";

const ChargingStationFaultsPage = () => {
  const intl = useIntl();
  const { filter } = useModuleContext();

  const [csId, setCsId] = useState<string>("all");
  const { data: chargingStations } = useGetChargingStationsQuery({
    siteId: filter.siteId,
  });

  const args = useMemo(() => {
    return {
      siteId: filter.siteId,
      orgId: filter.orgId,
      startTime: filter.timeRange.start.toISOString(),
      endTime: filter.timeRange.end.toISOString(),
      csId: csId === "all" ? undefined : csId,
    };
  }, [filter, csId]);

  return (
    <>
      <Stack rowGap={2}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item container xs lg spacing={1} justifyContent={"flex-end"}>
            <Grid item xs={6} md="auto">
              <TextField
                select
                fullWidth
                value={chargingStations && csId ? csId : "all"}
                onChange={(e) => setCsId(e.target.value)}
              >
                <MenuItem key="all" value="all">
                  <FormattedMessage id="all-charging-stations" />
                </MenuItem>
                {chargingStations &&
                  chargingStations.data?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
      <Grid item xs={12} md={12} lg={12}>
        <ChargingStationFaults args={args} chargingStations={chargingStations?.data} />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <MainCard content={false} title={intl.formatMessage({ id: "charging-station-faults" })}>
          <ChargingStationFaultsTable args={args} />
        </MainCard>
      </Grid>
    </>
  );
};

export default ChargingStationFaultsPage;
