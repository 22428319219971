import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Grid,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import MainCard from "components/MainCard";
import { WebsocketContext } from "contexts/WebsocketContext";
import { add, endOfDay, intervalToDuration, parseISO, startOfDay } from "date-fns";
import { Feature } from "experimentation/Feature";
import { Flags } from "experimentation/Flags";
import { ChargeCurve } from "modules/site-operator/charging-stations/components/ChargeCurve";
import { DemandVsActualChart } from "modules/site-operator/charging-stations/components/DemandChart";
import { MeterValuesTable } from "modules/site-operator/charging-stations/components/MeterValuesTable";
import OcppEventsTable from "modules/site-operator/charging-stations/components/ocpp-events/OcppEventsTable";
import { TemperatureChart } from "modules/site-operator/charging-stations/components/TemperatureChart";
import { LogsTable } from "modules/site-operator/maintenance/components/LogsTable";
import { PaymentDialog } from "modules/site-operator/transactions/components/PaymentDialog";
import { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import {
  ChargingStation,
  ChargingStationSummary,
  Invoice,
  Transaction,
  useGetChargingStationQuery,
  useGetTransactionQuery,
} from "service/api-slice";
import { MonitorWebsocketProvider } from "service/monitor/monitor-websocket";
import CurrentTransaction from "../../modules/site-operator/charging-stations/components/CurrentTransaction";
import { TransactionSummaryTables } from "./components/TransactionSummaryTables";

export const TransactionDetailPage = () => {
  const csId = useParams()["csId"]!!;
  const txId = useParams()["txId"]!!;

  const { data: cs, refetch: refetchChargingStation } = useGetChargingStationQuery({
    csId: csId,
    expand_authorization: true,
    expand_cooling: true,
    expand_current_transaction: true,
  });

  const { data: txn, refetch } = useGetTransactionQuery({
    txId: txId,
    csId: csId,
    expandInvoices: true,
    expandVehicle: true,
  });

  const refetchAll = useCallback(() => {
    refetchChargingStation();
    refetch();
  }, [refetch, refetchChargingStation]);

  useEffect(() => {
    if (cs?.data && !cs.data.current_transaction && txn?.data && !txn.data.end_time) {
      const timeout = setInterval(() => {
        refetchAll();
      }, 1_000);
      return () => clearInterval(timeout);
    }
  }, [cs, txn, refetchAll]);

  if (
    cs?.data?.current_transaction &&
    cs?.data?.status?.status !== "Available" &&
    txn?.data.id === cs.data.current_transaction.id
  ) {
    return (
      <MonitorWebsocketProvider chargingStationId={cs.data.id}>
        <ActiveTransactionDetailPage cs={cs.data} txn={txn.data} refetch={refetchAll} />
      </MonitorWebsocketProvider>
    );
  }

  if (txn?.data?.end_time) {
    return <FinishedTransactionDetailPage txn={txn.data} refetch={refetch} />;
  }

  return null;
};

const ActiveTransactionDetailPage = ({
  cs,
  txn,
  refetch,
}: {
  cs: ChargingStation;
  txn: Transaction;
  refetch: () => void;
}) => {
  const wsContext = useContext(WebsocketContext);

  useEffect(() => {
    if (wsContext.message) {
      switch (wsContext.message.type) {
        case "StatusChange":
          refetch();
          break;
      }
    }
  }, [refetch, wsContext.message]);

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      <Grid item xs={12} md={12} lg={12}>
        <CurrentTransaction cs={cs} tx={txn} />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <InfoTabs cs={txn.charging_station} tx={txn} />
      </Grid>
    </Grid>
  );
};

const FinishedTransactionDetailPage = ({
  txn,
  refetch,
}: {
  txn: Transaction;
  refetch: () => void;
}) => {
  const [txParams, setTxParams] = useState<{ startTime: Date; endTime: Date; vehicleId: string }>();
  const [invoice, setInvoice] = useState<Invoice>();

  const [chargingTime, setChargingTime] = useState<Duration>();
  const [openPaymentDialog, setOpenPaymentDialog] = useState<boolean>(false);

  useEffect(() => {
    setChargingTime(
      intervalToDuration({
        start: parseISO(txn.start_time),
        end: parseISO(txn.end_time),
      })
    );

    if (!txn.vehicle) {
      return;
    }

    setTxParams({
      startTime: startOfDay(parseISO(txn.start_time)),
      endTime: endOfDay(parseISO(txn.end_time)),
      vehicleId: txn.vehicle.id,
    });
  }, [txn, setChargingTime, setTxParams]);

  function closeAcceptPayment() {
    setOpenPaymentDialog(false);
    refetch();
    setInvoice(undefined);
  }

  useEffect(() => {
    if (!txn.invoices || txn.invoices.length === 0) {
      const interval = setInterval(() => {
        refetch();
      }, 1_000);
      return () => clearInterval(interval);
    }
  }, [txn, refetch]);

  return (
    <Grid container spacing={2}>
      {invoice && txParams && (
        <PaymentDialog
          invoiceId={invoice.id}
          txParams={txParams}
          open={openPaymentDialog}
          handleClose={closeAcceptPayment}
        />
      )}
      <Grid item xs={12} md={12} lg={6}>
        <MainCard title={<FormattedMessage id="receipts" />} content={false} sx={{ mb: 2 }}>
          <TableContainer>
            <Table size="small">
              <TableBody>
                {txn.invoices?.map((invoice) => (
                  <TableRow hover={false} key={invoice.id}>
                    <TableCell>${invoice.total / 100}</TableCell>
                    <TableCell>{invoice.status}</TableCell>
                    <TableCell>
                      {invoice.status !== "paid" && (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            setInvoice(invoice);
                            setOpenPaymentDialog(true);
                          }}
                        >
                          <FormattedMessage id="accept-payment" />
                        </Button>
                      )}
                      {invoice.status === "paid" && (
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => {
                            setInvoice(invoice);
                            setOpenPaymentDialog(true);
                          }}
                        >
                          <FormattedMessage id="view-payment" />
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </MainCard>
        <TransactionSummaryTables txn={txn} chargingTime={chargingTime} refetch={refetch} />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <Stack spacing={2}>
          <ChargeCurve chargingStationId={txn.charging_station.id} transactionId={txn.id} />
          <DemandVsActualChart chargingStationId={txn.charging_station.id} transactionId={txn.id} />
          <TemperatureChart chargingStationId={txn.charging_station.id} transactionId={txn.id} />
          <Feature flag={Flags.TxNotesTags}>
            <MainCard title={<FormattedMessage id="notes-tags" />} content={false}>
              <Autocomplete
                multiple
                fullWidth
                sx={{ border: "none", "& fieldset": { border: "none" } }}
                id="tags-outlined"
                options={["Manhattan", "Brooklyn", "Queens", "Bronx", "Staten Island"]}
                getOptionLabel={(option) => option}
                defaultValue={["Manhattan"]}
                filterSelectedOptions
                renderInput={(params) => <TextField {...params} placeholder="Tags" size="small" />}
              />
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Notes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover={false} sx={{ "& > *": { borderBottom: "unset" } }}>
                      <TableCell>Tuesday by John Lotito</TableCell>
                    </TableRow>
                    <TableRow hover={false}>
                      <TableCell>
                        <em>Hello generj sjlsj ajlkda</em>
                      </TableCell>
                    </TableRow>
                    <TableRow hover={false} sx={{ "& > *": { borderBottom: "unset" } }}>
                      <TableCell>Friday by Stafford</TableCell>
                    </TableRow>
                    <TableRow hover={false}>
                      <TableCell>
                        <em>Hello generj sjlsj ajlkda</em>
                      </TableCell>
                    </TableRow>
                    <TableRow hover={false}>
                      <TableCell align="right">
                        <TextField fullWidth placeholder="Add Note" multiline />
                        <Button size="small" sx={{ mt: 2 }}>
                          Add
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </MainCard>
          </Feature>
        </Stack>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <InfoTabs cs={txn.charging_station} tx={txn} />
      </Grid>
    </Grid>
  );
};

const InfoTabs = ({ cs, tx }: { cs: ChargingStationSummary; tx: Transaction }) => {
  const [selectedTab, setSelectedTab] = useState("meter-readings");

  const handleTabChanged = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const logStart = parseISO(tx.start_time);
  const endTime = tx.end_time ? parseISO(tx.end_time) : new Date();
  // Show logs collected up to two hours later.
  const logEnd = add(endTime, { hours: 2 });
  return (
    <MainCard content={false}>
      <TabContext value={selectedTab}>
        <TabList onChange={handleTabChanged} aria-label="Transaction Data">
          <Tab
            value="meter-readings"
            key="meter-readings"
            label={<FormattedMessage id="meter-reading" />}
          />
          <Tab value="messages" key="messages" label={<FormattedMessage id="messages" />} />
          <Tab value="logs" key="logs" label={<FormattedMessage id="logs" />} />
        </TabList>
        <TabPanel value="meter-readings" sx={{ padding: 0 }}>
          <MeterValuesTable chargingStationdId={cs.id} transactionId={tx.id} />
        </TabPanel>
        <TabPanel value="messages" sx={{ padding: 0 }}>
          <OcppEventsTable
            csId={cs.id}
            range={{
              start: parseISO(tx.start_time),
              end: tx.end_time ? parseISO(tx.end_time) : new Date(),
            }}
            allowTailing={!tx.end_time}
          />
          {/* <OcppMessagesTable
            chargingStationdId={cs.id}
            range={{ start: tx.start_time, end: tx.end_time }}
          /> */}
        </TabPanel>
        <TabPanel value="logs" sx={{ padding: 0 }}>
          <LogsTable chargingStation={cs} txId={tx.id} range={{ start: logStart, end: logEnd }} />
        </TabPanel>
      </TabContext>
    </MainCard>
  );
};
