import { SearchOutlined } from "@ant-design/icons";
import { InputBase, styled, Toolbar } from "@mui/material";
import { alpha, Box } from "@mui/system";
import { RowData, Table } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import ColumnChooser from "./ColumnChooser";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.grey[500], 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.grey[500], 0.25),
  },
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
}));

export type ToolbarProps = {
  showFilter?: boolean;
  showColumnSelector?: boolean;
};

const GravityTableToolbar = <TData extends RowData>({
  toolbar,
  table,
}: {
  toolbar?: ToolbarProps | false;
  table: Table<TData>;
}) => {
  const [filter, setFilter] = useState("");

  useEffect(() => {
    table.setGlobalFilter(() => {
      return filter;
    });
  }, [table, filter]);

  if (!toolbar) {
    return null;
  }

  return (
    <Toolbar>
      {toolbar.showFilter && (
        <Search>
          <SearchIconWrapper>
            <SearchOutlined />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            value={filter}
            inputProps={{ "aria-label": "search" }}
            onChange={(value) => setFilter(value.target.value)}
          />
        </Search>
      )}
      <Box sx={{ flexGrow: 1 }} />
      <Box>
        <ColumnChooser table={table} />
      </Box>
    </Toolbar>
  );
};

export default GravityTableToolbar;
