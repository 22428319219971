import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

type QueryParamType = string | string[] | number | number[] | boolean | boolean[];

const DELIMETER = ",";

export function useQueryParamsState<T = QueryParamType>(
  queryParamName: string,
  defaultValue: T,
  showDefaultValue: boolean = false
): readonly [searchParamsState: T, setSearchParamsState: (newState: T) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const acquiredSearchParam = searchParams.get(queryParamName);

  let searchParamsState: T = defaultValue;
  if (acquiredSearchParam) {
    if (Array.isArray(defaultValue)) {
      const values = acquiredSearchParam.split(DELIMETER);
      if (typeof defaultValue[0] === "number") {
        searchParamsState = values.map(Number) as T;
      } else if (typeof defaultValue[0] === "boolean") {
        searchParamsState = values.map((value) => value === "true") as T;
      } else {
        searchParamsState = values as T;
      }
    } else if (typeof defaultValue === "number") {
      searchParamsState = Number(acquiredSearchParam) as T;
    } else if (typeof defaultValue === "boolean") {
      searchParamsState = (acquiredSearchParam === "true") as T;
    } else {
      searchParamsState = acquiredSearchParam as T;
    }
  }

  const setSearchParamsState = (newState: T) => {
    if (newState === defaultValue && !showDefaultValue) {
      searchParams.delete(queryParamName);
      setSearchParams(searchParams);
      return;
    }
    setSearchParams((previousSearchParams) => {
      const next = new URLSearchParams(previousSearchParams);
      if (Array.isArray(newState)) {
        next.set(queryParamName, newState.join(DELIMETER));
      } else {
        let value = newState as number | string | boolean;
        next.set(queryParamName, value.toString());
      }
      return next;
    });
  };
  return [searchParamsState, setSearchParamsState];
}

export const useSearchParamGetter = (paramName: string, searchParams: URLSearchParams) => {
  return useMemo(() => {
    return searchParams.get(paramName) || "all";
  }, [searchParams, paramName]);
};

export const useSearchParamSetter = (
  paramName: string,
  searchParams: URLSearchParams,
  setSearchParams: (params: URLSearchParams) => void
) => {
  return useCallback(
    (newState) => {
      if (newState === "all") {
        searchParams.delete(paramName);
      } else {
        searchParams.set(paramName, newState);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, paramName]
  );
};
