import { useCallback } from "react";
import { NavigateOptions } from "react-router";
import { ModuleType } from "types/modules";
import { useNavigateWithQuery } from "./useNavigateWithQuery";

export function useNavigateWithModule(module: ModuleType) {
  const navigate = useNavigateWithQuery();

  return useCallback(
    (path: string, navigateOptions?: NavigateOptions) => {
      navigate(`/${module}/${path}`, navigateOptions);
    },
    [navigate, module]
  );
}
