import { LoadingButton } from "@mui/lab";
import { Alert, Box, Stack } from "@mui/material";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import MainCard from "components/MainCard";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { useGetChargingStationQuery, usePreauthChargingStationMutation } from "service/api-slice";
import { useModuleContext } from "types/context";
import { IsLiveEnvironment } from "utils/env";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!!);

const PaymentForm = (props: { chargingStationId: string; clientSecret: string }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { module } = useModuleContext();
  const { data } = useGetChargingStationQuery({
    csId: props.chargingStationId,
    expand_authorization: true,
  });
  const [processing, setProcessing] = useState<boolean>(false);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setProcessing(true);

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_STRIPE_REDIRECT_BASE_URL}/${module}/payment/${props.chargingStationId}/complete`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setProcessing(false);
    }
  };

  return (
    <Box component="form" onSubmit={onSubmit}>
      <Stack rowGap={2} padding={2}>
        <FormattedMessage
          id="authorize-payment-for-charging-station"
          values={{ chargingStation: data?.data.name }}
        />
        {!IsLiveEnvironment() && (
          <Alert severity="info">
            <FormattedMessage id="test-only-credit-card" />
          </Alert>
        )}
        <PaymentElement options={{ layout: "tabs" }} />
        <LoadingButton
          loading={!stripe || processing}
          variant="contained"
          color="primary"
          type="submit"
        >
          <FormattedMessage id="authorize" />
        </LoadingButton>
        {errorMessage && <div>{errorMessage}</div>}
      </Stack>
    </Box>
  );
};

export const PaymentChargingStationPage = () => {
  const csId = useParams()["charging_station_id"]!!;
  const [preauthChargingStation, preauthChargingStationResult] =
    usePreauthChargingStationMutation();

  useEffect(() => {
    preauthChargingStation({ csId: csId });
  }, [csId, preauthChargingStation]);

  const getClientSecret = () => {
    if (preauthChargingStationResult?.data) {
      return preauthChargingStationResult.data.data.payment_intent_client_secret;
    }
  };

  return (
    <MainCard content={false}>
      {getClientSecret() && stripePromise && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: getClientSecret(),
            appearance: {
              theme: "stripe",
              variables: {
                fontFamily: `'Public Sans', sans-serif`,
              },
            },
          }}
        >
          <PaymentForm chargingStationId={csId} clientSecret={getClientSecret()!} />
        </Elements>
      )}
    </MainCard>
  );
};
