import { MuiTelInput } from "mui-tel-input";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
} from "@mui/material";
import { StaticTimePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { useCreateParkingSessionMutation } from "service/api-slice";
import { useModuleContext } from "types/context";
import { FormattedMessage } from "react-intl";
import { Feature } from "experimentation/Feature";
import { Flags } from "experimentation/Flags";

const ReserveParkingButton = ({ vehicleId }: { vehicleId: string }) => {
  const makeInitialDepartureTime = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1);
  };
  const { filter } = useModuleContext();
  const [alertText, setAlertText] = useState("");
  const [expandForm, setExpandForm] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [endTime, setEndTime] = useState<Date | null>(makeInitialDepartureTime());
  const [createSession, createSessionResult] = useCreateParkingSessionMutation();
  const onCancel = () => {
    setAlertText("");
    setExpandForm(false);
  };
  const onReserveParking = () => {
    if (!filter.siteId) {
      setAlertText("No site selected");
      return;
    }
    if (!endTime) {
      setAlertText("No end time selected");
      return;
    }
    setAlertText("");
    createSession({
      site_id: filter.siteId,
      vehicle_id: vehicleId,
      phone_number: phoneNumber,
      start_time: new Date().toISOString(),
      end_time: endTime.toISOString(),
    });
  };
  useEffect(() => {
    if (createSessionResult.isError) {
      setAlertText("Failed to reserve parking:" + JSON.stringify(createSessionResult.error));
    } else if (createSessionResult.isSuccess) {
      setAlertText("");
      setExpandForm(false);
    }
  }, [createSessionResult]);
  if (filter.siteId === undefined) {
    return null;
  }

  return (
    <Feature flag={Flags.EnableParking}>
      <Dialog open={expandForm} onClose={onCancel}>
        <DialogTitle>Reserve Parking</DialogTitle>
        <DialogContent>
          <form>
            <FormControl disabled={createSessionResult.isLoading} margin="normal">
              {alertText !== "" && <Alert severity="error">{alertText}</Alert>}
              <FormLabel>
                <FormattedMessage id="phone-number" />
              </FormLabel>
              <MuiTelInput
                value={phoneNumber}
                onChange={setPhoneNumber}
                defaultCountry="US"
                forceCallingCode
              />
              <FormLabel>
                <FormattedMessage id="expected-pickup" />
              </FormLabel>
              <StaticTimePicker
                view="hours"
                value={endTime}
                onAccept={(value) => setEndTime(value)}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={createSessionResult.isLoading}
            onClick={onReserveParking}
          >
            <FormattedMessage id="reserve-parking" />
          </LoadingButton>
          <LoadingButton variant="outlined" onClick={onCancel}>
            <FormattedMessage id="cancel" />
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <LoadingButton
        variant="contained"
        onClick={() => {
          setExpandForm(true);
        }}
      >
        <FormattedMessage id="reserve-parking" />
      </LoadingButton>
    </Feature>
  );
};

export default ReserveParkingButton;
