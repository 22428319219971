import { Alert, AlertTitle, Stack } from "@mui/material";
import AnalyticsDataCard from "components/cards/statistics/AnalyticsDataCard";
import { formatRFC3339 } from "date-fns";
import { FormattedMessage } from "react-intl";
import { useGetSalesTaxQuery } from "service/api-slice";
import { useModuleContext } from "types/context";

const SalesTaxReport = () => {
  const { filter } = useModuleContext();
  const { data, isLoading, isError } = useGetSalesTaxQuery({
    start_time: formatRFC3339(filter.timeRange.start),
    end_time: formatRFC3339(filter.timeRange.end),
  });

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const formatCents = (cents: number | undefined): string => {
    if (!isLoading && !isError && cents) {
      return currencyFormatter.format(cents / 100);
    }
    return "-";
  };

  return (
    <Stack spacing={1}>
      {isError && (
        <Alert severity="error">
          <AlertTitle>
            <strong>
              <FormattedMessage id="error-occured" />
            </strong>
          </AlertTitle>
        </Alert>
      )}
      {filter.orgId && (
        <Alert severity="warning">
          <AlertTitle>
            <FormattedMessage id="warning-org-is-ignored" />
          </AlertTitle>
        </Alert>
      )}
      {filter.tags && filter.tags.length > 0 && (
        <Alert severity="warning">
          <AlertTitle>
            <FormattedMessage id="warning-site-is-ignored" />
          </AlertTitle>
        </Alert>
      )}
      <Stack direction="row" spacing={2}>
        <AnalyticsDataCard title="gross-sales" count={formatCents(data?.data.gross_sales)} />
        <AnalyticsDataCard
          title="sales-tax-collected"
          count={formatCents(data?.data.sales_tax_collected)}
        />
      </Stack>
    </Stack>
  );
};

export default SalesTaxReport;
