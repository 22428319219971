import { Autocomplete, TextField } from "@mui/material";
import { ReactNode, useState } from "react";

export interface State {
  name: string;
  value: string;
}

const usStates: State[] = [
  { name: "Alabama", value: "AL" },
  { name: "Alaska", value: "AK" },
  { name: "Arizona", value: "AZ" },
  { name: "Arkansas", value: "AR" },
  { name: "California", value: "CA" },
  { name: "Colorado", value: "CO" },
  { name: "Connecticut", value: "CT" },
  { name: "Delaware", value: "DE" },
  { name: "Florida", value: "FL" },
  { name: "Georgia", value: "GA" },
  { name: "Hawaii", value: "HI" },
  { name: "Idaho", value: "ID" },
  { name: "Illinois", value: "IL" },
  { name: "Indiana", value: "IN" },
  { name: "Iowa", value: "IA" },
  { name: "Kansas", value: "KS" },
  { name: "Kentucky", value: "KY" },
  { name: "Louisiana", value: "LA" },
  { name: "Maine", value: "ME" },
  { name: "Maryland", value: "MD" },
  { name: "Massachusetts", value: "MA" },
  { name: "Michigan", value: "MI" },
  { name: "Minnesota", value: "MN" },
  { name: "Mississippi", value: "MS" },
  { name: "Missouri", value: "MO" },
  { name: "Montana", value: "MT" },
  { name: "Nebraska", value: "NE" },
  { name: "Nevada", value: "NV" },
  { name: "New Hampshire", value: "NH" },
  { name: "New Jersey", value: "NJ" },
  { name: "New Mexico", value: "NM" },
  { name: "New York", value: "NY" },
  { name: "North Carolina", value: "NC" },
  { name: "North Dakota", value: "ND" },
  { name: "Ohio", value: "OH" },
  { name: "Oklahoma", value: "OK" },
  { name: "Oregon", value: "OR" },
  { name: "Pennsylvania", value: "PA" },
  { name: "Rhode Island", value: "RI" },
  { name: "South Carolina", value: "SC" },
  { name: "South Dakota", value: "SD" },
  { name: "Tennessee", value: "TN" },
  { name: "Texas", value: "TX" },
  { name: "Utah", value: "UT" },
  { name: "Vermont", value: "VT" },
  { name: "Virginia", value: "VA" },
  { name: "Washington", value: "WA" },
  { name: "West Virginia", value: "WV" },
  { name: "Wisconsin", value: "WI" },
  { name: "Wyoming", value: "WY" },
];

const StateSelector = ({
  defaultValue,
  label,
  onStateChange,
  required = false,
  fullWidth = true,
}: {
  fullWidth?: boolean;
  label?: ReactNode;
  required?: boolean;
  defaultValue?: string;
  onStateChange?: (selectedState: State | null) => void;
}) => {
  const defaultSelection = defaultValue
    ? usStates.find((state) => state.value === defaultValue) ?? null
    : null;
  const [selectedState, setSelectedState] = useState<State | null>(defaultSelection);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: State | null) => {
    setSelectedState(newValue);
    if (onStateChange) {
      onStateChange(newValue);
    }
  };

  return (
    <>
      <Autocomplete
        fullWidth={fullWidth}
        sx={{ minWidth: 150 }}
        value={selectedState}
        onChange={handleChange}
        options={usStates}
        getOptionLabel={(option) => `${option.value} - ${option.name}`}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" required={required} label={label} />
        )}
      />
      <input type="hidden" name="state" value={selectedState?.value} required={required} />
    </>
  );
};

export default StateSelector;
