import { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// project import
import useConfig from "hooks/useConfig";

// third-party
import AnalyticsDataCard from "components/cards/statistics/AnalyticsDataCard";
import ReactApexChart, { Props as ChartProps } from "react-apexcharts";
import { GetSessionsArgs, useGetEnergyUsedQuery } from "service/api-slice";

// ==============================|| USER CARD CHART ||============================== //

const EnergyUsedChart = (props: {
  height: number | string | null;
  sessionArgs: GetSessionsArgs;
}) => {
  const theme = useTheme();
  const { mode } = useConfig();

  const { data } = useGetEnergyUsedQuery(props.sessionArgs, {
    pollingInterval: 60_000,
    skipPollingIfUnfocused: true,
  });

  const [count, setCount] = useState("");
  const [change, setChange] = useState<number | null>(null);

  const [series, setSeries] = useState([
    {
      name: "Energy Used",
      data: [0],
    },
  ]);

  useEffect(() => {
    if (data) {
      setSeries([
        {
          name: "Energy Used",
          data: data.data,
        },
      ]);
      setCount(`${data.totalEnergyUsed / 1000.0} kWh`);
      setChange(data.totalChange);
    }
  }, [data]);

  // chart options
  const areaChartOptions = {
    chart: {
      id: "new-stack-chart",
      sparkline: {
        enabled: true,
      },
      height: 100,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    tooltip: {
      theme: mode === "dark" ? "dark" : "light",
      x: {
        show: false,
      },
      y: {
        formatter: function (value: any) {
          return `${value / 1000} kWh`;
        },
      },
    },
    grid: {
      show: false,
    },
  };

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState<ChartProps>(areaChartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [theme.palette.primary.main, theme.palette.primary[700]],
      tooltip: {
        theme: mode === "dark" ? "dark" : "light",
      },
    }));
  }, [mode, primary, secondary, line, theme]);

  return (
    <AnalyticsDataCard
      title="energy-used-chart-label"
      count={count}
      percentage={change}
      isLoss={change ? change < 0 : null}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={props?.height?.toString()}
      />
    </AnalyticsDataCard>
  );
};

export default EnergyUsedChart;
