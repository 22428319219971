import { Grid } from "@mui/material";
import MainCard from "components/MainCard";
import { useNavigateWithModule } from "hooks/useNavigateWithModule";
import ReserveParkingButton from "modules/site-operator/parking/components/ReserveParkingButton";
import DownloadTransactionsButton from "modules/site-operator/reports/components/DownloadTransactionsButton";
import TransactionsSummary from "modules/site-operator/transactions/components/TransactionsSummary";
import Error404 from "pages/maintenance/404";
import { TransactionsTable } from "pages/transactions/components/TransactionsTable";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { Transaction, useGetVehicleQuery } from "service/api-slice";
import { useModuleContext } from "types/context";
import { Module } from "types/modules";
import { VehicleInfoContainer } from "./components/VehicleSummary";

const VehicleDetails = () => {
  const vehicleId = useParams()["id"]!!;
  const { module, filter } = useModuleContext();
  const navigate = useNavigateWithModule(module);
  const { data, isError } = useGetVehicleQuery({
    vehicleId,
    module,
  });

  function navigateToTransaction(transaction: Transaction) {
    if (module === Module.SiteOperator) {
      navigate(
        `charging-stations/${transaction.charging_station.id}/transactions/${transaction.id}`
      );
    } else if (module === Module.FleetOperator) {
      navigate(
        `charging-sessions/${transaction.charging_station.id}/transactions/${transaction.id}`
      );
    }
  }

  return isError ? (
    <Error404 />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        {data?.data && <VehicleInfoContainer vehicle={data?.data} />}
      </Grid>
      <TransactionsSummary vehicleId={vehicleId} />
      {module === Module.SiteOperator && <ReserveParkingButton vehicleId={vehicleId} />}
      <Grid item xs={12} md={12} lg={12}>
        <MainCard
          title={<FormattedMessage id="charging-sessions" />}
          content={false}
          secondary={
            module === Module.SiteOperator && (
              <DownloadTransactionsButton
                siteId={filter.siteId}
                orgId={filter.orgId}
                startTime={filter.timeRange.start}
                endTime={filter.timeRange.end}
                vehicleId={vehicleId}
              />
            )
          }
        >
          <TransactionsTable
            siteId={filter.siteId}
            orgId={filter.orgId}
            vehicleId={vehicleId}
            startTime={filter.timeRange.start}
            endTime={filter.timeRange.end}
            onClick={navigateToTransaction}
          />
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default VehicleDetails;
