import { CameraFilled, CloseOutlined } from "@ant-design/icons";
import { Container, Fab } from "@mui/material";
import useUserMedia from "hooks/media/useUserMedia";
import { useRef } from "react";

const Camera = ({
  onImageCaptured,
  close,
}: {
  onImageCaptured: (Blob: Blob) => void;
  close: () => void;
}) => {
  const mediaStream = useUserMedia({
    video: {
      advanced: [{ facingMode: "environment" }],
    },
    audio: false,
  });

  const videoRef = useRef<HTMLVideoElement | null>(null);

  if (mediaStream && videoRef.current) {
    videoRef.current.srcObject = mediaStream;
  }

  return (
    <>
      <video
        ref={videoRef}
        autoPlay
        style={{
          position: "fixed",
          right: 0,
          bottom: 0,
          minWidth: "100%",
          minHeight: "100%",
          width: "auto",
          height: "auto",
        }}
        playsInline
        onCanPlay={() => {
          const video = videoRef.current;
          if (video) {
            video.play();
          }
        }}
      />

      {/* Close Button */}
      <Fab style={{ position: "fixed", top: 10, right: 10 }} size="small" onClick={close}>
        <CloseOutlined style={{ fontSize: "200%" }} />
      </Fab>

      {/* Capture Image Button */}
      <Container
        sx={{
          position: "fixed",
          bottom: 25,
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Fab
          onClick={(e) => {
            const canvas = document.createElement("canvas");
            const video = videoRef.current!;
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext("2d")?.drawImage(video, 0, 0, canvas.width, canvas.height);
            canvas.toBlob(
              (blob) => {
                onImageCaptured(blob!);
              },
              "image/jpeg",
              1
            );
          }}
        >
          <CameraFilled style={{ fontSize: "200%" }} color="primary" />
        </Fab>
      </Container>
    </>
  );
};

export default Camera;
