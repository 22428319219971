export function arraysEqual(a: string[] | undefined, b: string[] | undefined) {
  if (a === b) return true;
  if (a === undefined || b === undefined) return false;
  if (a.length !== b.length) return false;

  const aCopy = a.slice().sort();
  const bCopy = b.slice().sort();

  for (var i = 0; i < aCopy.length; ++i) {
    if (aCopy[i] !== bCopy[i]) return false;
  }
  return true;
}
