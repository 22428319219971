import MainCard from "components/MainCard";
import { SitesTable } from "./components/SitesTable";

export const SitesListPage = () => {
  return (
    <MainCard content={false}>
      <SitesTable />
    </MainCard>
  );
};
