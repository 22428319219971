import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import DateRangePicker from "components/DateRangePicker";
import { endOfDay, startOfDay } from "date-fns";
import { useQueryParamsState } from "hooks/useQueryParamsState";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Outlet } from "react-router";
import { useGetOrganizationsQuery, useGetSitesQuery } from "service/api-slice";
import { Module } from "types/modules";

const now = new Date();
const defaultStartTime = startOfDay(now);
const defaultEndTime = endOfDay(now);

export const PaymentPage = () => {
  const sites = useGetSitesQuery();
  const orgs = useGetOrganizationsQuery({ page: 0, count: 500 });

  const [siteId, setSiteId] = useQueryParamsState("site", "all");
  const [orgId, setOrgId] = useQueryParamsState("org", "all");
  const [range, setRange] = useQueryParamsState("range", [
    defaultStartTime.getTime(),
    defaultEndTime.getTime(),
  ]);

  const context = useMemo(() => {
    const start = range[0];
    const end = range[1];

    return {
      module: Module.Playground,
      filter: {
        timeRange: {
          start: new Date(start),
          end: new Date(end),
        },
        tags: sites?.data?.data?.map((site) => site.name) ?? [],
        orgId: orgId === "all" ? undefined : orgId,
        siteId: siteId === "all" ? undefined : siteId,
      },
    };
  }, [orgId, range, sites, siteId]);

  return (
    <Stack rowGap={2}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h4">
            <FormattedMessage id="overview" />
          </Typography>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={1}>
            <TextField
              select
              value={orgs?.isSuccess && orgId ? orgId : "all"}
              onChange={(e) => setOrgId(e.target.value)}
            >
              <MenuItem key="all" value="all">
                <FormattedMessage id="all-orgs" />
              </MenuItem>
              {orgs.isSuccess &&
                orgs?.data?.data?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              select
              value={sites.isSuccess && siteId ? siteId : "all"}
              onChange={(e) => setSiteId(e.target.value)}
            >
              <MenuItem key="all" value="all">
                <FormattedMessage id="all-sites" />
              </MenuItem>
              {sites.isSuccess &&
                sites?.data?.data?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
            <DateRangePicker
              selection={{
                start: context.filter.timeRange.start,
                end: context.filter.timeRange.end,
              }}
              onSelectionChanged={({ start, end }) => {
                setRange([start.getTime(), end.getTime()]);
              }}
            />
          </Stack>
        </Grid>
      </Grid>
      <Outlet context={context} />
    </Stack>
  );
};
