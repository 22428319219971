import { Chip, Link, Stack, TableCell, TableRow } from "@mui/material";
import { useMemo } from "react";
import { PaymentIntent } from "service/api-slice";
import { IsLiveEnvironment } from "utils/env";
import SendReceipt from "./SendReceipt";

const PaymentIntentRow = ({
  stripeId,
  intents,
}: {
  stripeId: string;
  intents: PaymentIntent[];
}) => {
  return (
    <>
      <TableRow hover={false}>
        <TableCell colSpan={2}>
          <Link
            href={`https://dashboard.stripe.com/${
              IsLiveEnvironment() ? "" : "test/"
            }payments/${stripeId}`}
            target="_blank"
          >
            {stripeId}
          </Link>
        </TableCell>
      </TableRow>
      {intents.map((intent) => (
        <TableRow hover={false} key={intent.id}>
          <TableCell>{intent.id}</TableCell>
          <TableCell sx={{ alignContent: "left" }}>
            <Stack direction="row" spacing={1}>
              <Chip
                label={intent.status}
                variant="filled"
                size="small"
                color={intent.status === "succeeded" ? "success" : "default"}
              />
            </Stack>
          </TableCell>
        </TableRow>
      ))}
      {intents.some((intent) => intent.status === "succeeded") && (
        <SendReceipt paymentIntentId={stripeId} />
      )}
    </>
  );
};

export const PaymentIntentRows = ({ intents }: { intents: PaymentIntent[] }) => {
  const stripePiToIntents = useMemo(() => {
    const intentsMap = new Map<string, PaymentIntent[]>();
    intents.forEach((intent) => {
      if (!intentsMap.get(intent.stripe_id)) {
        intentsMap.set(intent.stripe_id, []);
      }
      intentsMap.get(intent.stripe_id)!.push(intent);
    });
    return intentsMap;
  }, [intents]);

  return (
    <>
      {[...stripePiToIntents.entries()].map(([stripeId, intents]) => (
        <PaymentIntentRow key={stripeId} stripeId={stripeId} intents={intents} />
      ))}
    </>
  );
};
