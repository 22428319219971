import {
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Column,
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  RowData,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import TableNoResults from "components/TableNoResults";
import TableRowSkeleton from "components/TableRowSkeleton";
import useMobile from "hooks/useMobile";
import { useEffect, useState } from "react";
import GravityTableColumnHeader from "./GravityTableColumnHeader";
import GravityTableRow, { RowClickHandlerProvider } from "./GravityTableRow";
import GravityTableToolbar, { ToolbarProps } from "./GravityTableToolbar";
import TablePagination from "components/pagination/table-pagination";

const GravityTable = <TData extends RowData>({
  columns,
  data,
  isLoading = false,
  toolbar = { showFilter: true, showColumnSelector: true },
  rowSx,
  rowClickHandlerProvider,
  pageCount,
  pagination,
  setPagination,
}: {
  columns: ColumnDef<TData, any>[];
  data?: TData[];
  isLoading?: boolean;
  toolbar?: ToolbarProps | false;
  rowSx?: (item: TData) => SxProps | undefined;
  rowClickHandlerProvider?: RowClickHandlerProvider<TData>;
  pageCount?: number;
  pagination?: PaginationState;
  setPagination?: OnChangeFn<PaginationState>;
}) => {
  const mobile = useMobile();
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  const table = useReactTable({
    data: data ?? [],
    columns,
    state: {
      columnVisibility,
      pagination,
    },
    pageCount: pageCount ?? -1,
    manualPagination: pagination !== undefined,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
  });

  useEffect(() => {
    const columns = table.getAllLeafColumns();
    const h = columns.reduce<VisibilityState>((r: VisibilityState, c: Column<TData, any>) => {
      let visible = !c.columnDef.meta?.hidden;
      if (mobile && visible) {
        const showOnMobile = c.columnDef.meta?.showOnMobile ?? true;
        visible = visible && showOnMobile;
      }
      r[c.id] = visible;
      return r;
    }, {});
    setColumnVisibility(h);
  }, [table, mobile, setColumnVisibility]);

  return (
    <Stack>
      <GravityTableToolbar table={table} toolbar={toolbar} />
      <TableContainer>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    if (!header.column.getIsVisible()) {
                      return null;
                    }
                    return <GravityTableColumnHeader key={header.id} header={header} />;
                  })}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody>
            {!isLoading &&
              table.getRowModel().rows.map((row) => {
                return (
                  <GravityTableRow
                    key={row.id}
                    row={row}
                    sx={rowSx}
                    rowClickHandlerProvider={rowClickHandlerProvider}
                  />
                );
              })}
            {isLoading && (
              <TableRowSkeleton
                isLoading={isLoading}
                columnCount={table.getVisibleLeafColumns().length}
                rowCount={10}
              />
            )}
            {!isLoading && table.getRowModel().rows.length === 0 && (
              <TableNoResults columnCount={table.getAllLeafColumns().length} />
            )}
          </TableBody>
          {pagination && (
            <TableFooter>
              <TableRow>
                <TableCell sx={{ p: 2 }} colSpan={table.getAllLeafColumns().length}>
                  <TablePagination
                    gotoPage={table.setPageIndex}
                    totalPages={table.getPageCount()}
                    setPageSize={table.setPageSize}
                    pageIndex={pagination.pageIndex}
                    pageSize={pagination.pageSize}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default GravityTable;
