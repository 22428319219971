import { TabPanel } from "@mui/lab";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import MainCard from "components/MainCard";
import { FormattedMessage } from "react-intl";
import { Invoice, Transaction } from "service/api-slice";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";
import { ReceiptRowWithTax } from "./ReceiptRows";

const TransactionAndReceipt = ({
  invoice,
  transaction,
}: {
  invoice: Invoice;
  transaction: Transaction;
}) => {
  return (
    <>
      <TableRow hover={false}>
        <TableCell colSpan={3} sx={{ fontWeight: "bold" }}>
          {transaction && (
            <FormattedMessage
              id="transaction-title"
              values={{
                name: transaction.charging_station.name,
                date: parseAndFormatDate(transaction.start_time),
                invoiceStatus: invoice.status,
              }}
            />
          )}
        </TableCell>
      </TableRow>
      {invoice.line_items.map((item, i) => (
        <ReceiptRowWithTax key={`${invoice.id}-${i}`} item={item} />
      ))}
    </>
  );
};

export const ReceiptsTabPanel = ({
  transactions,
  invoices,
  tableSize = "medium",
  tabName,
}: {
  transactions: Transaction[];
  invoices: Invoice[];
  tableSize?: "small" | "medium";
  tabName: string;
}) => {
  return (
    <TabPanel value={tabName} sx={{ padding: 0 }}>
      <MainCard content={false}>
        <TableContainer>
          <Table size={tableSize}>
            <TableHead>
              <TableRow hover={false}>
                <TableCell colSpan={2}>
                  <FormattedMessage id="description" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="amount" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map((invoice) => {
                const transaction = transactions.find(
                  (t) =>
                    t.invoices &&
                    t.invoices.length > 0 &&
                    t.invoices.some((i) => i.id === invoice.id)
                );
                if (!transaction) {
                  return null;
                }
                return (
                  <TransactionAndReceipt
                    key={invoice.id}
                    transaction={transaction}
                    invoice={invoice}
                  />
                );
              })}
            </TableBody>
            <TableHead>
              <TableRow hover={false}>
                <TableCell colSpan={2}>
                  <FormattedMessage id="unpaid-total" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage
                    id="dollars"
                    values={{
                      amount: (
                        invoices
                          .filter((invoice) => invoice.status === "pending")
                          .reduce((acc, invoice) => acc + invoice.total, 0) / 100
                      ).toFixed(2),
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow hover={false}>
                <TableCell colSpan={2}>
                  <FormattedMessage id="total" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage
                    id="dollars"
                    values={{
                      amount: (
                        invoices.reduce((acc, invoice) => acc + invoice.total, 0) / 100
                      ).toFixed(2),
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </MainCard>
    </TabPanel>
  );
};
