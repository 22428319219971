import { CarOutlined, DashboardOutlined, ProfileOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { NavItemType } from "types/menu";

export const FleetOperatorMenu: NavItemType = {
  id: "fleet-operator-menu",
  type: "group",
  url: "/fleet-operator",
  title: <FormattedMessage id="fleet-operator-menu" />,
  breadcrumbs: true,
  children: [
    {
      id: "dashboard",
      title: <FormattedMessage id="overview" />,
      type: "item",
      url: "/fleet-operator",
      icon: DashboardOutlined,
      breadcrumbs: false,
    },
    {
      id: "charging-sessions",
      title: <FormattedMessage id="sessions" />,
      type: "item",
      url: "/fleet-operator/charging-sessions",
      icon: ProfileOutlined,
      breadcrumbs: true,
    },
    {
      id: "vehicles",
      title: <FormattedMessage id="vehicles" />,
      type: "item",
      url: "/fleet-operator/vehicles",
      icon: CarOutlined,
      breadcrumbs: true,
    },
  ],
};
