import { useTheme } from "@mui/material/styles";
import AnalyticsDataCard from "components/cards/statistics/AnalyticsDataCard";
import useConfig from "hooks/useConfig";
import { useNavigateWithModule } from "hooks/useNavigateWithModule";
import { useMemo } from "react";
import ReactApexChart, { Props as ChartProps } from "react-apexcharts";
import { FormattedMessage } from "react-intl";
import { GetTransactionFailuresArgs, useGetTransactionFailuresQuery } from "service/api-slice";
import { useModuleContext } from "types/context";

// ==============================|| USER CARD CHART ||============================== //

const SessionFailures = (props: { height: number | null; args: GetTransactionFailuresArgs }) => {
  const theme = useTheme();
  const { mode } = useConfig();
  const { module } = useModuleContext();
  const navigate = useNavigateWithModule(module);

  const { data } = useGetTransactionFailuresQuery(props.args, {
    pollingInterval: 60_000,
    skipPollingIfUnfocused: true,
  });

  const count = useMemo(() => {
    if (!data) {
      return 0;
    }
    return data.data.reduce((acc, v) => acc + v.failure_count, 0);
  }, [data]);

  const totalSessions = useMemo(() => {
    if (!data) {
      return 0;
    }
    return data.data.reduce((acc, v) => acc + v.total, 0);
  }, [data]);

  const percentage = useMemo(() => {
    if (!data || totalSessions === 0 || count === 0) {
      return;
    }

    return parseFloat(((count / totalSessions) * 100).toFixed(2));
  }, [data, count, totalSessions]);

  const series = useMemo(() => {
    if (!data) {
      return [
        {
          name: "Session Failures",
          type: "bar",
          data: [{ x: "0", y: 0 }],
        },
      ];
    }

    return [
      {
        name: "Daily failures",
        type: "bar",
        data: data.data.map((f) => {
          return { x: f.date, y: f.failure_count };
        }),
      },
      {
        name: "Daily failure rate",
        type: "line",
        data: data.data.map((f) => {
          return { x: f.date, y: f.total ? (f.failure_count / f.total) * 100 : 0 };
        }),
      },
    ];
  }, [data]);

  // chart options
  const areaChartOptions = useMemo<ChartProps>(() => {
    return {
      colors: [
        theme.palette.primary.main,
        theme.palette.primary[700],
        theme.palette.secondary.main,
      ],
      stroke: {
        show: [false, true],
        width: [0, 2],
      },
      chart: {
        id: "failures-chart",
        height: 100,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: "numeric",
        tickPlacement: ["on", "on"],
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        tooltip: {
          enabled: false,
        },
        labels: {
          show: true,
          formatter: (value: number) => {
            let date = new Date(value);
            return `${date.getMonth() + 1}/${date.getDate()}`;
          },
        },
      },
      yaxis: [
        {
          show: false,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        {
          show: false,
          opposite: true,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
      ],
      tooltip: {
        theme: mode === "dark" ? "dark" : "light",
        x: {
          show: true,
        },
        y: [
          {
            formatter: (value: number) => {
              return value.toFixed(0);
            },
          },
          {
            formatter: (value: number) => {
              return `${value.toFixed(0)}%`;
            },
          },
        ],
      },
      grid: {
        show: false,
      },
    };
  }, [mode, theme]);

  return (
    <AnalyticsDataCard
      title="session-failures-chart-label"
      count={`${count} Failures`}
      percentage={percentage}
      color="secondary"
      buttonClick={() => {
        return navigate("reports/failures");
      }}
      buttonText={<FormattedMessage id="failure-details" />}
    >
      <ReactApexChart
        options={areaChartOptions}
        series={series}
        type="line"
        height={props.height?.toString()}
      />
    </AnalyticsDataCard>
  );
};

export default SessionFailures;
