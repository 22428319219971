import { useAuth0 } from "@auth0/auth0-react";
// import { useContext } from 'react';

// auth provider
// import AuthContext from 'contexts/FirebaseContext';

// ==============================|| AUTH HOOKS ||============================== //

const useAuth = () => {
  // const context = useContext(AuthContext);
  const context = useAuth0();

  if (!context) throw new Error("context must be use inside provider");

  return context;
};

export default useAuth;
