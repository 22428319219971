// types
import { DefaultConfigProps } from "types/config";

export const drawerWidth = 260;

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
  defaultPath: "/",
  fontFamily: `'Public Sans', sans-serif`,
  i18n: "en",

  miniDrawer: false,

  // false for full with, true for fixed width of main content
  container: false,

  mode: "light",
  presetColor: "gravity",
  themeDirection: "ltr",
};

export default config;
