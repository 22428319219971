// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import snackbar from "./snackbar";

import { apiSlice } from "service/api-slice";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export default reducers;
