import { SaveOutlined } from "@ant-design/icons";
import MainCard from "components/MainCard";
import VehicleName from "modules/site-operator/charging-stations/components/VehicleName";
import { LoadingButton } from "@mui/lab";
import Alert from "@mui/material/Alert";
import {
  Dialog,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { QRCode } from "react-qrcode-logo";
import { useParams } from "react-router";
import {
  ParkingSession,
  useGetParkingSessionQuery,
  useUpdateParkingSessionMutation,
} from "service/api-slice";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";

const ActionButton = ({
  text,
  hidden,
  loading,
  onClick,
}: {
  text: string;
  hidden: boolean;
  loading: boolean;
  onClick: () => void;
}) => {
  if (hidden) {
    return <></>;
  }
  return (
    <LoadingButton
      variant="contained"
      color="primary"
      loading={loading}
      onClick={(e) => {
        onClick();
        e.stopPropagation();
      }}
    >
      <FormattedMessage id={`${text}`} />
    </LoadingButton>
  );
};

const ActionButtonWithPromptDialog = ({
  text,
  hidden,
  loading,
  onClose,
}: {
  text: string;
  hidden: boolean;
  loading: boolean;
  onClose: (s: string) => void;
}) => {
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const onActionButtonClick = useCallback(() => setOpen(true), [setOpen]);
  const onDialogClosed = useCallback(() => setOpen(false), [setOpen]);
  const onSaveClick = useCallback(() => {
    setOpen(false);
    onClose(value);
  }, [onClose, setOpen, value]);

  return (
    <>
      <ActionButton
        text={text}
        hidden={hidden}
        loading={loading || open}
        onClick={onActionButtonClick}
      />
      <Dialog open={open} onClose={onDialogClosed}>
        <form>
          <TextField autoFocus type="text" onChange={(e) => setValue(e.target.value)} />
          <LoadingButton
            type="submit"
            loading={loading}
            onClick={onSaveClick}
            color="primary"
            loadingPosition="start"
            startIcon={<SaveOutlined />}
            variant="outlined"
          >
            <FormattedMessage id={text} />
          </LoadingButton>
        </form>
      </Dialog>
    </>
  );
};

const ParkingActions = ({ session, refetch }: { session: ParkingSession; refetch: () => void }) => {
  const [updateRequest, updateResult] = useUpdateParkingSessionMutation();
  const [qrCode, setQRCode] = useState<string | null>(null);
  const showQRCode = useCallback(() => {
    setQRCode(session.id);
  }, [session.id, setQRCode]);
  const onCloseQRCode = useCallback(() => {
    setQRCode(null);
  }, [setQRCode]);

  const onClickStartParking = useCallback(() => {
    updateRequest({
      session_id: session.id,
      state: "Parking",
      state_time: new Date().toISOString(),
    });
  }, [session.id, updateRequest]);
  const onClickFinishParking = useCallback(() => {
    updateRequest({
      session_id: session.id,
      state: "Parked",
      state_time: new Date().toISOString(),
    });
  }, [session.id, updateRequest]);
  const onClickDepart = useCallback(() => {
    updateRequest({
      session_id: session.id,
      state: "Departed",
      state_time: new Date().toISOString(),
    });
  }, [session.id, updateRequest]);
  const finishSetKeyLocation = useCallback(
    (v: string) => {
      updateRequest({
        session_id: session.id,
        key_location: v,
      });
    },
    [session.id, updateRequest]
  );
  const finishSetVehicleLocation = useCallback(
    (v: string) => {
      updateRequest({
        session_id: session.id,
        vehicle_location: v,
      });
    },
    [session.id, updateRequest]
  );

  useEffect(() => {
    if (updateResult.isSuccess) {
      refetch();
      updateResult.reset();
    }
  }, [refetch, updateResult]);

  const onCloseErrorAlert = useCallback(() => {
    refetch();
    updateResult.reset();
  }, [refetch, updateResult]);

  return (
    <Stack direction="row">
      {updateResult.isError && (
        <Alert severity="error" variant="filled" onClose={onCloseErrorAlert}>
          Failed to update the parking session.
        </Alert>
      )}
      <ActionButton
        hidden={session.state !== "Charging"}
        loading={updateResult.isLoading || updateResult.isError}
        text="start-parking"
        onClick={onClickStartParking}
      />
      <ActionButton
        hidden={false}
        loading={updateResult.isLoading || updateResult.isError}
        text="show-qr-code"
        onClick={showQRCode}
      />
      <Dialog open={qrCode !== null} onClose={onCloseQRCode}>
        <QRCode value={qrCode ?? ""} size={128} />
      </Dialog>
      <ActionButton
        hidden={session.state !== "Parking"}
        loading={updateResult.isLoading || updateResult.isError}
        text={"finish-parking"}
        onClick={onClickFinishParking}
      />
      <ActionButtonWithPromptDialog
        hidden={session.state !== "Parked"}
        loading={updateResult.isLoading || updateResult.isError}
        text={"set-key-location"}
        onClose={finishSetKeyLocation}
      />
      <ActionButtonWithPromptDialog
        hidden={session.state !== "Parked"}
        loading={updateResult.isLoading || updateResult.isError}
        text={"set-vehicle-location"}
        onClose={finishSetVehicleLocation}
      />
      <ActionButton
        hidden={session.state !== "Parked"}
        loading={updateResult.isLoading || updateResult.isError}
        text={"check-out"}
        onClick={onClickDepart}
      />
    </Stack>
  );
};

const AttributeRow = ({ desc_id, val }: { desc_id: string; val: string | null | undefined }) => {
  return (
    <TableRow hover={false}>
      <TableCell>
        <FormattedMessage id={desc_id} />
      </TableCell>
      <TableCell>{val ?? "-"}</TableCell>
    </TableRow>
  );
};

const ParkingSessionState = ({ session }: { session: ParkingSession }) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          <AttributeRow
            desc_id={session.state.toLowerCase()}
            val={parseAndFormatDate(session.state_time)}
          />
          {session.state !== "Departed" && (
            <AttributeRow desc_id="expected-pickup" val={parseAndFormatDate(session.end_time)} />
          )}
          <AttributeRow desc_id="vehicle" val={session.vehicle_location} />
          <AttributeRow desc_id="key" val={session.key_location} />
          <AttributeRow desc_id="phone-number" val={session.phone_number} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const ParkingSessionPage = () => {
  const sessionId = useParams()["session_id"]!!;
  const { data, refetch } = useGetParkingSessionQuery({ session_id: sessionId });

  return (
    <MainCard>
      {data?.data?.session && (
        <>
          <Typography variant="h3">
            <VehicleName vehicle={data.data.session.vehicle} />
          </Typography>
          <ParkingActions session={data.data.session} refetch={refetch} />
          <ParkingSessionState session={data.data.session} />
        </>
      )}
    </MainCard>
  );
};

export default ParkingSessionPage;
