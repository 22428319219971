import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import TableNoResults from "components/TableNoResults";
import { TablePagination } from "components/pagination/table-pagination";
import { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router";
import { ChargingStationFaultData, useGetChargingStationFaultsQuery } from "service/api-slice";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";

const columnHelper = createColumnHelper<ChargingStationFaultData>();

const columns = [
  columnHelper.accessor("charging_station_name", {
    id: "charging_station_name",
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="charging-station" />,
  }),
  columnHelper.accessor("start_time", {
    id: "start_time",
    cell: (i) => parseAndFormatDate(i.getValue()),
    header: () => <FormattedMessage id="start_time" />,
  }),
  columnHelper.accessor("end_time", {
    id: "end_time",
    cell: (i) => (i.getValue() ? parseAndFormatDate(i.getValue()) : "-"),
    header: () => <FormattedMessage id="end_time" />,
  }),
  columnHelper.accessor("transaction_id", {
    id: "transaction_id",
    cell: (i) => i.getValue() ?? "-",
    header: () => <FormattedMessage id="transaction" />,
  }),
  columnHelper.accessor("error_codes", {
    id: "error-codes",
    cell: (i) => i.getValue().join(", "),
    header: () => <FormattedMessage id="error-codes" />,
  }),
];

export const ChargingStationFaultsTable = (props: {
  args: { startTime: string; endTime: string; siteId?: string; orgId?: string };
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const navigateToTransaction = useCallback(
    (fault: ChargingStationFaultData) => {
      navigate(
        `charging-stations/${fault.charging_station_id}/transactions/${fault.transaction_id}`
      );
    },
    [navigate]
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });

  const pagination = useMemo(() => {
    return {
      pageIndex,
      pageSize,
    };
  }, [pageIndex, pageSize]);

  const { data, isLoading, isError } = useGetChargingStationFaultsQuery({
    ...props.args,
    page: pageIndex,
    count: pageSize,
  });

  const table = useReactTable({
    data: data?.data ?? [],
    columns: columns,
    pageCount: -1,
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableContainer>
      <Table sx={{ minWidth: 350 }} aria-label="Charger Sessions Table">
        {!isSmallScreen && (
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableCell key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableHead>
        )}
        <TableBody>
          {(isLoading || isError) &&
            table.getHeaderGroups().map((headerGroup) => {
              return [...Array(10).keys()].map((i) => (
                <TableRow key={i}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableCell key={`${i}-${header.id}`} colSpan={header.colSpan}>
                        <Skeleton />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ));
            })}
          {table.getRowModel().rows.map((row) => {
            return (
              <TableRow
                key={row.id}
                hover={true}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  if (row.original.transaction_id) {
                    navigateToTransaction(row.original);
                  }
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {table.getRowModel().rows.length === 0 && (
            <TableNoResults columnCount={table.getAllColumns().length} />
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell sx={{ p: 2 }} colSpan={table.getAllLeafColumns().length}>
              <TablePagination
                gotoPage={table.setPageIndex}
                totalPages={table.getPageCount()}
                setPageSize={table.setPageSize}
                pageIndex={pageIndex}
                pageSize={pageSize}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
