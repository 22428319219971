import { useTheme } from "@mui/material/styles";
import MainCard from "components/MainCard";
import { WebsocketContext } from "contexts/WebsocketContext";
import { parseISO } from "date-fns";
import { format } from "date-fns-tz";
import { useContext, useEffect, useState } from "react";
import ReactApexChart, { Props as ChartProps } from "react-apexcharts";
import { FormattedMessage } from "react-intl";
import { useGetTransactionChartDataQuery } from "service/api-slice";
import { Message, MeterValuesMessage } from "service/monitor/msg/Message";
import { nearestDate } from "utils/datetime/nearestDate";

export type TemperatureChartProps = {
  chargingStationId: string;
  transactionId: string;
  height?: number;
};

export const TemperatureChart = ({
  chargingStationId,
  transactionId,
  height = 320,
}: TemperatureChartProps) => {
  const theme = useTheme();
  const wsContext = useContext(WebsocketContext);
  const { data, isSuccess } = useGetTransactionChartDataQuery({
    cpId: chargingStationId,
    txId: transactionId,
  });
  const [series, setSeries] = useState<
    {
      name: string;
      data: { x: Date; y: number }[];
    }[]
  >([]);
  const [options, setOptions] = useState<ChartProps>({
    chart: {
      id: "temperature-chart",
      type: "line",
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: "temperature-chart",
            columnDelimiter: ",",
            headerCategory: "Date",
            headerValue: "Temperature",
            dateFormatter(timestamp: number) {
              return format(timestamp, "yyyy-MM-dd HH:mm:ss zzz");
            },
          },
        },
      },
      animations: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      width: [2, 2, 2],
      curve: "straight",
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.primary.dark,
      theme.palette.secondary.main,
      theme.palette.secondary.dark,
      theme.palette.warning.main,
    ],
    xaxis: {
      type: "datetime",
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: [
      {
        seriesName: "Pin -",
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: (value: any) => {
            return `${value} °C`;
          },
        },
      },
      {
        seriesName: "Pin +",
        show: false,
        labels: {
          show: true,
          formatter: (value: any) => {
            return `${value} °C`;
          },
        },
      },
      {
        seriesName: "Cable -",
        show: false,
        labels: {
          show: true,
          formatter: (value: any) => {
            return `${value} °C`;
          },
        },
      },
      {
        seriesName: "Cable +",
        show: false,
        labels: {
          show: true,
          formatter: (value: any) => {
            return `${value} °C`;
          },
        },
      },
      {
        seriesName: "Current",
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: (value: any) => {
            return `${value} A`;
          },
        },
      },
    ],
    grid: {
      show: true,
    },
  });

  useEffect(() => {
    if (data) {
      var pinTempsN,
        pinTempsP: Array<{ x: Date; y: number }> = [];

      if (data.temperature_n && data.temperature_p) {
        pinTempsN = data.temperature_n.map((v, i) => {
          return { x: parseISO(data.dates[i]), y: v };
        });
        pinTempsP = data.temperature_p.map((v, i) => {
          return { x: parseISO(data.dates[i]), y: v };
        });
      } else if (data.pin_temps) {
        pinTempsN = data.pin_temps.negative.map((v, i) => {
          return { x: parseISO(data.dates[i]), y: v };
        });
        pinTempsP = data.pin_temps.positive.map((v, i) => {
          return { x: parseISO(data.dates[i]), y: v };
        });
      }
      const current = data.current.map((v, i) => {
        return { x: parseISO(data.dates[i]), y: v };
      });

      const cableTempsN = data.cable_temps?.negative.map((v, i) => {
        return { x: parseISO(data.dates[i]), y: v };
      });
      const cableTempsP = data.cable_temps?.positive.map((v, i) => {
        return { x: parseISO(data.dates[i]), y: v };
      });

      setSeries([
        {
          name: "Pin -",
          data: pinTempsN ?? [],
        },
        {
          name: "Pin +",
          data: pinTempsP ?? [],
        },
        {
          name: "Cable -",
          data: cableTempsN ?? [],
        },
        {
          name: "Cable +",
          data: cableTempsP ?? [],
        },
        {
          name: "Current",
          data: current,
        },
      ]);

      const dates = data?.dates.map((d) => parseISO(d));
      setOptions((prevState) => ({
        ...prevState,
        xaxis: {
          type: "datetime",
          labels: {
            formatter: (value: string, timestamp: number) => {
              let idx = nearestDate(dates, new Date(timestamp));
              if (!idx) {
                idx = data?.soc.findIndex((v) => v > 0);
              }
              if (idx) {
                let soc: number | undefined = data?.soc[idx];
                if (soc) {
                  return [format(timestamp, "h:mm a"), `${soc}%`];
                }
              }
              return [format(timestamp, "h:mm a")];
            },
          },
        },
      }));
    }
  }, [data]);

  useEffect(() => {
    if (!isSuccess) return;

    if (wsContext.message) {
      const msg = wsContext.message as Message<any>;
      switch (msg.type) {
        case "MeterValues":
          processMeterValues(msg as MeterValuesMessage);
          break;
      }
    }

    function processMeterValues(msg: MeterValuesMessage) {
      let current: number | undefined = undefined;
      let pinTempN: number | undefined = undefined;
      let pinTempP: number | undefined = undefined;
      let cableTempN: number | undefined = undefined;
      let cableTempP: number | undefined = undefined;

      msg.message.forEach((v) => {
        if (!v.value) return;
        switch (v.measurand) {
          case "Current.Import":
            current = v.value;
            break;
          case "Temperature.N":
            if (v.location === "Outlet") {
              pinTempN = v.value;
            } else if (v.location === "Cable") {
              cableTempN = v.value;
            }
            break;
          case "Temperature.P":
            if (v.location === "Outlet") {
              pinTempP = v.value;
            } else if (v.location === "Cable") {
              cableTempP = v.value;
            }
            break;
        }
      });

      if (current && pinTempN && pinTempP) {
        setSeries((prevState) => {
          return [
            {
              name: "Pin -",
              data: [...prevState[0].data, { x: parseISO(msg.timestamp), y: pinTempN! }],
            },
            {
              name: "Pin +",
              data: [...prevState[1].data, { x: parseISO(msg.timestamp), y: pinTempP! }],
            },
            {
              name: "Cable -",
              data: cableTempN
                ? [...prevState[2].data, { x: parseISO(msg.timestamp), y: cableTempN! }]
                : prevState[2].data,
            },
            {
              name: "Cable +",
              data: cableTempP
                ? [...prevState[3].data, { x: parseISO(msg.timestamp), y: cableTempP! }]
                : prevState[3].data,
            },
            {
              name: "Current",
              data: [...prevState[4].data, { x: parseISO(msg.timestamp), y: current! }],
            },
          ];
        });
      }
    }
  }, [wsContext.message, isSuccess]);

  return (
    <MainCard
      content={false}
      sx={{ height: "100%" }}
      title={<FormattedMessage id="temperatures" />}
    >
      <ReactApexChart options={options} series={series} type="line" height={height} />
    </MainCard>
  );
};
