import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { ChargingStation } from "service/api-slice";
import FaultedChargingStationsMobile from "./FaultedChargingStationsMobile";
import FaultedChargingStationsTable from "./FaultedChargingStationsTable";

const FaultedChargingStations = ({
  chargingStations,
  onClick,
}: {
  chargingStations: ChargingStation[];
  onClick: (cs: ChargingStation) => void;
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  if (isSmallScreen) {
    return <FaultedChargingStationsMobile onClick={onClick} chargingStations={chargingStations} />;
  }

  return <FaultedChargingStationsTable onClick={onClick} chargingStations={chargingStations} />;
};

export default FaultedChargingStations;
