import { Grid, MenuItem, Stack, TextField } from "@mui/material";
import MainCard from "components/MainCard";
import { useNavigateWithModule } from "hooks/useNavigateWithModule";
import { useSearchParamGetter, useSearchParamSetter } from "hooks/useQueryParamsState";
import TransactionsSummary from "modules/site-operator/transactions/components/TransactionsSummary";
import { TransactionsTable } from "pages/transactions/components/TransactionsTable";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { Transaction, useGetMakesQuery, useGetModelsQuery } from "service/api-slice";
import { useModuleContext } from "types/context";

const ChargingSessionsList = () => {
  const { filter, module } = useModuleContext();
  const navigate = useNavigateWithModule(module);

  const [searchParams, setSearchParams] = useSearchParams();

  const makeId = useSearchParamGetter("makeId", searchParams);
  const vpicId = useSearchParamGetter("vpicId", searchParams);
  const modelId = useSearchParamGetter("modelId", searchParams);

  const setMakeId = useSearchParamSetter("makeId", searchParams, setSearchParams);
  const setVpicId = useSearchParamSetter("vpicId", searchParams, setSearchParams);
  const setModelId = useSearchParamSetter("modelId", searchParams, setSearchParams);

  const { data: makes } = useGetMakesQuery({ page: 0, count: 500 });

  const { data: models } = useGetModelsQuery(
    {
      makeId,
      page: 0,
      count: 500,
    },
    { skip: makeId === "all" }
  );
  const vpicIds = useMemo(() => {
    if (!models || !models.data) {
      return [];
    }

    return models.data.filter(
      (model, index, self) => self.findIndex((m) => m.vpic_id === model.vpic_id) === index
    );
  }, [models]);

  const { data: modelYears } = useGetModelsQuery(
    {
      makeId,
      vpicId: vpicId === "all" ? undefined : vpicId,
      page: 0,
      count: 500,
    },
    { skip: makeId === "all" }
  );
  const years = useMemo(() => {
    if (!modelYears || !modelYears.data) {
      return [];
    }

    return modelYears.data.filter(
      (model, index, self) => self.findIndex((m) => m.year === model.year) === index
    );
  }, [modelYears]);

  function navigateToTransaction(transaction: Transaction) {
    navigate(`charging-sessions/${transaction.charging_station.id}/transactions/${transaction.id}`);
  }

  return (
    <>
      <Stack rowGap={2}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item container xs lg spacing={1} justifyContent={"flex-end"}>
            <Grid item xs={6} md="auto">
              <TextField
                select
                fullWidth
                value={makes && makeId ? makeId : "all"}
                onChange={(e) => {
                  setMakeId(e.target.value);
                  setVpicId("all");
                  setModelId("all");
                }}
              >
                <MenuItem key="all" value="all">
                  <FormattedMessage id="all-makes" />
                </MenuItem>
                {makes?.data?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} md="auto">
              <TextField
                select
                fullWidth
                value={vpicIds && vpicId ? vpicId : "all"}
                onChange={(e) => {
                  setVpicId(e.target.value);
                  setModelId("all");
                }}
              >
                <MenuItem key="all" value="all">
                  <FormattedMessage id="all-models" />
                </MenuItem>
                {vpicIds &&
                  makeId !== "all" &&
                  vpicIds.map((option) => (
                    <MenuItem key={option.vpic_id!} value={option.vpic_id!}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={6} md="auto">
              <TextField
                select
                fullWidth
                value={years && modelId ? modelId : "all"}
                onChange={(e) => setModelId(e.target.value)}
              >
                <MenuItem key="all" value="all">
                  <FormattedMessage id="all-years" />
                </MenuItem>
                {years &&
                  vpicId !== "all" &&
                  years.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.year}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
      <Grid container spacing={2}>
        <TransactionsSummary
          makeId={makeId === "all" ? undefined : makeId}
          modelId={modelId === "all" ? undefined : modelId}
          vpicId={vpicId === "all" ? undefined : vpicId}
        />
        <Grid item sm={12} md={12} lg={12} xl={12}>
          <MainCard content={false}>
            <TransactionsTable
              startTime={filter.timeRange.start}
              endTime={filter.timeRange.end}
              makeId={makeId === "all" ? undefined : makeId}
              modelId={modelId === "all" ? undefined : modelId}
              vpicId={vpicId === "all" ? undefined : vpicId}
              onClick={navigateToTransaction}
            />
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default ChargingSessionsList;
