import { Outlet, RouteObject, useNavigate, useRoutes } from "react-router-dom";

// project import
// import LoginRoutes from './LoginRoutes';
import { useGravityUser } from "contexts/GravityUserContext";
import MainLayout from "layout/MainLayout";
import { AdminRoutes } from "modules/admin/routes";
import { FleetOperatorRoutes } from "modules/fleet-operator/routes";
import { GarageOperatorRoutes } from "modules/garage-operator/routes";
import { PlaygroundRoutes } from "modules/playground/routes";
import { PowerManagementRoutes } from "modules/power-management/routes";
import { SiteOperatorFullScreenRoutes, SiteOperatorRoutes } from "modules/site-operator/routes";
import Error404 from "pages/maintenance/404";
import { useEffect } from "react";
import { AuthenticationGuard } from "utils/route-guard/AuthenticationGuard";

// ==============================|| ROUTING RENDER ||============================== //

const GravityLayout = () => {
  return <MainLayout />;
};

const RedirectComponent = () => {
  const navigate = useNavigate();
  const user = useGravityUser();

  useEffect(() => {
    if (user.allowedModules.includes("site-operator")) {
      navigate("/site-operator", { replace: true });
    } else if (user.allowedModules.includes("fleet-operator")) {
      navigate("/fleet-operator", { replace: true });
    } else if (user.allowedModules.includes("garage-operator")) {
      navigate("/garage-operator", { replace: true });
    }
  }, [navigate, user.allowedModules]);

  return <div>Redirecting...</div>;
};

const routes: RouteObject = {
  element: <AuthenticationGuard component={GravityLayout} />,
  children: [
    FleetOperatorRoutes,
    SiteOperatorRoutes,
    GarageOperatorRoutes,
    PowerManagementRoutes,
    AdminRoutes,
    PlaygroundRoutes,
    { index: true, element: <RedirectComponent /> },
    { path: "*", element: <Error404 /> },
  ],
};

const fullScreenRoutes: RouteObject = {
  element: <AuthenticationGuard component={Outlet} />,
  children: [SiteOperatorFullScreenRoutes],
};

export default function GravityRoutes() {
  return useRoutes([routes, fullScreenRoutes]);
}
