import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FormattedMessage } from "react-intl";
import { Site, useGetSitesQuery } from "service/api-slice";

const columnHelper = createColumnHelper<Site>();

const columns = [
  columnHelper.accessor("id", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="id" />,
  }),
  columnHelper.accessor("name", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="name" />,
  }),
  columnHelper.accessor("address", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="address" />,
  }),
  columnHelper.accessor("city", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="city" />,
  }),
  columnHelper.accessor("state", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="state" />,
  }),
  columnHelper.accessor("zip", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="zip" />,
  }),
  columnHelper.accessor("latitude", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="latitude" />,
  }),
  columnHelper.accessor("longitude", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="longitude" />,
  }),
];

export const SitesTable = () => {
  const { data, isLoading, isError } = useGetSitesQuery();

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableContainer>
      <Table sx={{ minWidth: 350 }} aria-label="Sites Table">
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableCell key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>
        <TableBody>
          {(isLoading || isError) &&
            table.getHeaderGroups().map((headerGroup) => {
              return [...Array(10).keys()].map((i) => (
                <TableRow key={i}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableCell key={`${i}-${header.id}`} colSpan={header.colSpan}>
                        <Skeleton />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ));
            })}
          {table.getRowModel().rows.map((row) => {
            return (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
