import { createContext, useContext, useMemo } from "react";
import { User, useGetMeQuery, useGetModulesQuery } from "service/api-slice";

interface GravityUserContextProps {
  user: User;
  allowedModules: string[];
}

const GravityUserContext = createContext<GravityUserContextProps>({
  user: {} as User,
  allowedModules: [],
});

export type GravityUserProviderProps = {
  children: React.ReactNode;
};

export const GravityUserProvider = (props: GravityUserProviderProps) => {
  const m = useGetModulesQuery();
  const u = useGetMeQuery();

  const context = useMemo(() => {
    return {
      allowedModules: m.data?.data || [],
      user: u.data?.data || ({} as User),
    };
  }, [m.data, u.data]);

  if (m.isLoading || u.isLoading) {
    return <div>loading...</div>;
  }

  if (m.isError || u.isError) {
    console.error(m.error, u.error);
    return <div>error...</div>;
  }

  return (
    <GravityUserContext.Provider value={context}>
      {context.allowedModules.length > 0 ? props.children : <div>No modules loaded.</div>}
    </GravityUserContext.Provider>
  );
};

export const useGravityUser = (context = GravityUserContext): GravityUserContextProps =>
  useContext(context);

export const useModules = (context = GravityUserContext): string[] =>
  useGravityUser().allowedModules;

export const useMe = (context = GravityUserContext): User => useGravityUser().user;
