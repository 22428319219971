import { Grid, Stack } from "@mui/material";
import { intervalToDuration, parseISO } from "date-fns";
import { ChargeCurve } from "modules/site-operator/charging-stations/components/ChargeCurve";
import Error404 from "pages/maintenance/404";
import { TransactionSummaryTables } from "pages/transactions/components/TransactionSummaryTables";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useGetTransactionQuery } from "service/api-slice";
import { useModuleContext } from "types/context";

export const ChargingSessionDetailPage = () => {
  const csId = useParams()["csId"]!!;
  const txId = useParams()["txId"]!!;
  const { module } = useModuleContext();

  const { data, isError, refetch } = useGetTransactionQuery({
    txId: txId,
    csId: csId,
    expandVehicle: true,
    module: module,
  });

  const chargingTime = useMemo(() => {
    if (!data) {
      return;
    }

    return intervalToDuration({
      start: parseISO(data?.data.start_time),
      end: parseISO(data?.data.end_time),
    });
  }, [data]);

  return isError ? (
    <Error404 />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={6}>
        <TransactionSummaryTables txn={data?.data} chargingTime={chargingTime} refetch={refetch} />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Stack spacing={2}>
          {data?.data && (
            <ChargeCurve chargingStationId={data?.data.charging_station.id} transactionId={txId} />
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};
