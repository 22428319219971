import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  CircularProgress,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { Stack } from "@mui/system";
import JsonViewer from "components/JsonViewer";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  DetectionResult,
  LicensePlateEntity,
  VehicleImage,
  VinEntity,
  isLicensePlateDetection,
  isVinDetection,
  useConfirmVehicleImageMutation,
  useDismissVehicleImageMutation,
  useGetVehicleImageResultQuery,
  useRunVinCheckMutation,
} from "service/api-slice";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";
import { valueOrNull } from "utils/strings/util";
import AnnotatedVehicleImage from "./AnnotatedVehicleImage";
import { LicensePlateDetection, VinDetection } from "./DetectedValue";
import DetectionFormControl from "./DetectionFormControl";
import LicensePlateFormControl from "./LicensePlateFormControl";
import OrganizationSelect from "./OrganizationSelect";

interface State {
  licensePlate?: DetectionResult<LicensePlateEntity>;
  vin?: DetectionResult<VinEntity>;
  vehicleImage: VehicleImage;
}

const buildState = (vehicleImage: VehicleImage): State => {
  const detections = vehicleImage.parsed_data?.detections?.filter(
    (detection) => detection.detected
  );
  return {
    vin: detections?.find(isVinDetection),
    licensePlate: detections?.find(isLicensePlateDetection),
    vehicleImage: vehicleImage,
  };
};

const VehicleImageCard = ({
  image,
  onFinished,
}: {
  image: VehicleImage;
  onFinished?: () => void;
}) => {
  const [state, setState] = useState<State>(buildState(image));

  const { data } = useGetVehicleImageResultQuery(image.id, {
    // only need to fetch if the message is new and hasn't been processed yet
    skip: state.vehicleImage.status !== "new",
    pollingInterval: state.vehicleImage.status === "new" ? 1000 : 0,
  });

  useEffect(() => {
    if (data && data.data.status !== "new") {
      setState(buildState(data.data));
    }
  }, [data, setState]);

  const [runVinCheck, vinCheckResult] = useRunVinCheckMutation();

  useEffect(() => {
    if (vinCheckResult.isSuccess) {
      setState((prev) => {
        return {
          ...prev,
          vin: vinCheckResult.data?.data,
        };
      });
    }
  }, [vinCheckResult, setState]);

  const [confirm, confirmResult] = useConfirmVehicleImageMutation();
  const [dismiss, dismissResult] = useDismissVehicleImageMutation();
  useEffect(() => {
    if (dismissResult.isSuccess || confirmResult.isSuccess) {
      const t = setTimeout(() => {
        if (onFinished) {
          onFinished();
        }
      }, 1500);
      return () => clearTimeout(t);
    }
  }, [confirmResult, dismissResult, onFinished]);

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const f = new FormData(e.currentTarget);
    confirm({
      vehicleImageId: state.vehicleImage.id,
      data: {
        vin: f.get("vin") as string,
        license_plate: valueOrNull(f.get("license_plate") as string),
        license_plate_state: valueOrNull(f.get("state") as string),
        organization_id: valueOrNull(f.get("organization_id") as string, "none"),
      },
    });
  }

  return (
    <Stack alignItems={"stretch"}>
      <AnnotatedVehicleImage
        key={`${state.vehicleImage.id}:${state.vehicleImage.status}`}
        vehicleImage={state.vehicleImage}
        opacity={state.vehicleImage.status === "new" ? 0.5 : 1}
      />
      {state.vehicleImage.status === "new" && <LinearProgress />}
      <Accordion defaultExpanded>
        <AccordionSummary>
          <FormattedMessage id="confirm-results" />
        </AccordionSummary>
        <Box
          component="form"
          onSubmit={onSubmit}
          key={`${state.vehicleImage.id}:${state.vehicleImage.status}`}
        >
          <AccordionDetails sx={{ minHeight: "370px", display: "flex", flexDirection: "column" }}>
            <VinDetection detection={state.vin} />
            <LicensePlateDetection detection={state.licensePlate} />
            <DetectionFormControl
              name="vin"
              detection={state.vin}
              required={true}
              loading={vinCheckResult.isLoading}
              reload={(value) => runVinCheck({ vin: value })}
            />
            <LicensePlateFormControl detection={state.licensePlate} />
            <OrganizationSelect name="organization_id" />
            {confirmResult.isError && (
              <Alert severity="error">{(confirmResult.error as any).data.message}</Alert>
            )}
            {confirmResult.isSuccess && (
              <Alert severity="success" icon={<CircularProgress size={15} />}>
                <FormattedMessage id="vehicle-updated" />
              </Alert>
            )}
            {dismissResult.isSuccess && (
              <Alert severity="info" icon={<CircularProgress size={15} />}>
                <FormattedMessage id="vehicle-dismissed" />
              </Alert>
            )}
            {dismissResult.isError && (
              <Alert severity="error">{(dismissResult.error as any).data.message}</Alert>
            )}
            <Stack
              sx={{ flexGrow: 1 }}
              direction={"row"}
              spacing={1}
              paddingTop={1}
              justifyContent={"flex-end"}
              alignItems={"flex-end"}
            >
              <LoadingButton
                onClick={() => dismiss(state.vehicleImage.id)}
                variant="outlined"
                loading={dismissResult.isLoading}
                color="info"
              >
                Dismiss
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="primary"
                loading={confirmResult.isLoading}
                disabled={state.vin?.entity?.vin === undefined}
                type="submit"
              >
                Confirm
              </LoadingButton>
            </Stack>
          </AccordionDetails>
        </Box>
      </Accordion>
      <Accordion>
        <AccordionSummary>Details</AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>Image ID</TableCell>
                <TableCell>{state.vehicleImage.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="status" />
                </TableCell>
                <TableCell>{state.vehicleImage.status}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="vehicle" />
                </TableCell>
                <TableCell>{state.vehicleImage.vehicle_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="charging-station" />
                </TableCell>
                <TableCell>{state.vehicleImage.charging_station_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="transaction" />
                </TableCell>
                <TableCell>{state.vehicleImage.transaction_id.substring(0, 10)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="created-at" />
                </TableCell>
                <TableCell>{parseAndFormatDate(state.vehicleImage.created_at)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="created-at" />
                </TableCell>
                <TableCell>{state.vehicleImage.created_by.name}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <FormattedMessage id="raw-results" />
        </AccordionSummary>
        <AccordionDetails>
          <JsonViewer json={state.vehicleImage.parsed_data} />
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default VehicleImageCard;
