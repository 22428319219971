import { Grid } from "@mui/material";
import MainCard from "components/MainCard";
import { useGetVehicleImagesQuery } from "service/api-slice";
import VehicleImageCard from "./components/VehicleImageCard";

const VehicleImagesList = () => {
  const { data } = useGetVehicleImagesQuery();

  return (
    <MainCard>
      <Grid container spacing={2}>
        {data?.data?.map((image) => (
          <Grid item sm={12} md={4} key={image.id}>
            <VehicleImageCard image={image} />
          </Grid>
        ))}
      </Grid>
    </MainCard>
  );
};

export default VehicleImagesList;
