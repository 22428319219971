import { WebsocketProvider } from "contexts/WebsocketContext";
import { ReactNode, useState } from "react";
import { sec } from "utils/security";
import { AuthConfig } from "utils/ws";

export type MonitorWebsocketContextProps = {};

export type MonitorWebsocketProviderProps = {
  children: ReactNode;
  chargingStationId: string;
};

export const MonitorWebsocketProvider = ({
  children,
  chargingStationId,
}: MonitorWebsocketProviderProps) => {
  var [url] = useState<string>(
    `${process.env.REACT_APP_WS_BASE_URL}/charging-station/${chargingStationId}/monitor`
  );
  var [authConfig] = useState<AuthConfig>({
    paramName: function (): string {
      return "token";
    },
    paramValue: async function (): Promise<string> {
      var jwtToken = await sec.getAccessTokenSilently();
      var resp = fetch(`${process.env.REACT_APP_API_BASE_URL}/monitor/token`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + jwtToken,
        },
      });

      const json = await (await resp).json();
      return json.token;
    },
  });

  return (
    <WebsocketProvider url={url} authConfig={authConfig}>
      {children}
    </WebsocketProvider>
  );
};
