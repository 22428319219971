import { SettingOutlined } from "@ant-design/icons";
import { Checkbox, FormControlLabel, IconButton, Menu, MenuItem } from "@mui/material";
import { flexRender, Table } from "@tanstack/react-table";
import { useRef, useState } from "react";

const ColumnChooser = ({ table }: { table: Table<any> }) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
        color="inherit"
        onClick={handleToggle}
      >
        <SettingOutlined />
      </IconButton>
      <Menu anchorEl={anchorRef.current} open={open} onClose={handleClose} sx={{ maxHeight: 600 }}>
        {table
          .getAllLeafColumns()
          .filter((c) => c.getCanHide())
          .map((c) => {
            return (
              <MenuItem key={c.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={c.getIsVisible()}
                      onChange={c.getToggleVisibilityHandler()}
                    />
                  }
                  label={flexRender(c.columnDef.header, {})}
                />
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};

export default ColumnChooser;
