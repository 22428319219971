import DefaultDashboard from "modules/site-operator/dashboards/default";
import Pumps from "modules/site-operator/pumps/pump";
import PumpStations from "modules/site-operator/pumps/pump-station";
import { TransactionDetailPage } from "pages/transactions/view";
import { RouteObject } from "react-router";
import { ModuleGuard } from "utils/route-guard/ModuleGuard";
import VehicleDetails from "../../components/vehicles/VehicleDetails";
import VehiclesIndex from "../../components/vehicles/VehiclesIndex";
import VehiclesList from "../../components/vehicles/VehiclesList";
import SiteOperatorIndex from "./SiteOperatorIndex";
import ChargePointsPage from "./charge-points";
import ChargePointDetailPage from "./charge-points/view";
import ChargingStationsPage from "./charging-stations";
import ChargingStationsList from "./charging-stations/list";
import ChargingStationPage from "./charging-stations/view";
import FirmwareUpdatesPage from "./maintenance/firmware-updates";
import LogRequestsPage from "./maintenance/log-requests";
import ParkingPage from "./parking/list";
import ParkingSessionPage from "./parking/session";
import RegisterVehicle from "./register-vehicle";
import ChargingStationFaultsPage from "./reports/charging-station-faults";
import DetailReport from "./reports/detail";
import SalesTaxReport from "./reports/sales-tax";
import TransactionFailures from "./reports/transaction-failures";
import SitesPage from "./sites";
import TransactionsPage from "./transactions";
import TransactionsList from "./transactions/list";
import VehicleImagesPage from "./vehicle-images";
import VehicleImagesList from "./vehicle-images/list";

export const SiteOperatorFullScreenRoutes: RouteObject = {
  element: <ModuleGuard modules={["site-operator"]} />,
  path: "site-operator",
  children: [
    {
      path: "charging-stations/:csId/transactions/:txId/register-vehicle",
      element: <RegisterVehicle />,
    },
  ],
};

export const SiteOperatorRoutes: RouteObject = {
  element: <ModuleGuard modules={["site-operator"]} />,
  path: "site-operator",
  children: [
    {
      element: <SiteOperatorIndex />,
      children: [
        {
          index: true,
          element: <DefaultDashboard />,
        },
        {
          path: "charging-sessions",
          element: <TransactionsPage />,
          children: [
            {
              index: true,
              element: <TransactionsList />,
            },
          ],
        },
        {
          path: "maintenance",
          children: [
            {
              path: "log-requests",
              element: <LogRequestsPage />,
            },
            {
              path: "firmware-updates",
              element: <FirmwareUpdatesPage />,
            },
          ],
        },
        {
          path: "parking",
          children: [
            {
              index: true,
              element: <ParkingPage />,
            },
            {
              path: ":session_id",
              element: <ParkingSessionPage />,
            },
          ],
        },
        {
          path: "reports",
          children: [
            {
              path: "detail",
              element: <DetailReport />,
            },
            {
              path: "sales-tax",
              element: <SalesTaxReport />,
            },
            {
              path: "failures",
              element: <TransactionFailures />,
            },
            {
              path: "faults",
              element: <ChargingStationFaultsPage />,
            },
          ],
        },
        {
          path: "vehicles",
          element: <VehiclesIndex />,
          children: [
            {
              index: true,
              element: <VehiclesList />,
            },
            {
              path: ":id",
              element: <VehicleDetails />,
            },
          ],
        },
        {
          path: "charging-stations",
          element: <ChargingStationsPage />,
          children: [
            {
              index: true,
              element: <ChargingStationsList />,
            },
            {
              path: ":id",
              id: "charge-station-details",
              element: <ChargingStationPage />,
            },
            {
              path: ":csId/transactions/:txId",
              id: "transaction-details",
              element: <TransactionDetailPage />,
            },
          ],
        },
      ],
    },
    {
      path: "vehicle-images",
      element: <VehicleImagesPage />,
      children: [
        {
          index: true,
          element: <VehicleImagesList />,
        },
      ],
    },
    {
      path: "sites",
      element: <SitesPage />,
    },
    {
      path: "charge-points",
      children: [
        {
          index: true,
          element: <ChargePointsPage />,
        },
        {
          path: ":id",
          id: "charge-point-details",
          element: <ChargePointDetailPage />,
        },
      ],
    },
    {
      path: "pump-stations",
      element: <PumpStations name="test" />,
    },
    {
      path: "pump-stations/:id",
      element: <Pumps />,
    },
  ],
};
