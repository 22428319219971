import Link from "components/Link";
import { SyntheticEvent } from "react";
import { Vehicle } from "service/api-slice";
import { useModuleContext } from "types/context";
import { Module } from "types/modules";

const VehicleName = ({
  vehicle,
  short = false,
  unknownLabel = "-",
}: {
  vehicle?: Vehicle | null;
  short?: boolean;
  unknownLabel?: string;
}) => {
  const { module } = useModuleContext();
  function onClick(e: SyntheticEvent) {
    e.stopPropagation();
  }

  if (vehicle?.vin) {
    const parts = [
      `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
      short ? vehicle.color : undefined,
      vehicle.license_plate_state && vehicle.license_plate
        ? `${vehicle.license_plate_state} ${vehicle.license_plate}`
        : undefined,
    ];
    const label = parts.filter((part) => part).join(" / ");
    const owner = vehicle.owner;
    if (owner && owner.id !== "00000000-0000-0000-0000-000000000000") {
      var linkText = `[${owner.display_name.toUpperCase()}] ${label}`;
      if (module === Module.FleetOperator) {
        linkText = label;
      }
      return (
        <Link to={`/vehicles/${vehicle.id}`} onClick={onClick}>
          {linkText}
        </Link>
      );
    }
    return (
      <Link to={`/vehicles/${vehicle.id}`} onClick={onClick}>
        {label}
      </Link>
    );
  }

  if (vehicle) {
    return (
      <Link to={`/vehicles/${vehicle.id}`} onClick={onClick}>
        {vehicle?.mac_address ?? unknownLabel}
      </Link>
    );
  }

  return <span>{unknownLabel}</span>;
};

export default VehicleName;
