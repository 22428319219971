import { FormControl, FormLabel } from "@mui/material";
import { Stack } from "@mui/system";
import StateSelector from "components/StateSelector";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { DetectionResult, LicensePlateEntity } from "service/api-slice";
import { isBlank } from "utils/strings/util";
import DetectionFormControl from "./DetectionFormControl";

const LicensePlateFormControl = ({
  detection,
}: {
  detection?: DetectionResult<LicensePlateEntity>;
}) => {
  const [licensePlate, setLicensePlate] = useState<string | undefined>(
    detection?.entity?.license_plate
  );
  const [selectedState, setSelectedState] = useState<string | undefined>(detection?.entity?.state);

  return (
    <Stack direction={"row"} spacing={1}>
      <DetectionFormControl
        required={!isBlank(selectedState)}
        onSelectionChange={(value) => setLicensePlate(value)}
        name="license_plate"
        detection={detection}
      />
      <FormControl fullWidth margin="normal">
        <FormLabel>
          <FormattedMessage id="state" />
        </FormLabel>
        <StateSelector
          required={!isBlank(licensePlate)}
          defaultValue={detection?.entity?.state}
          onStateChange={(state) => setSelectedState(state?.value)}
        />
      </FormControl>
    </Stack>
  );
};

export default LicensePlateFormControl;
