// material-ui
import { FallOutlined, RiseOutlined } from "@ant-design/icons";
import { Box, Button, Chip, ChipProps, Stack, Typography } from "@mui/material";

// project import
import MainCard from "components/MainCard";

// assets
import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

interface Props {
  title: string;
  count: string;
  percentage?: number | null;
  isLoss?: boolean | null;
  color?: ChipProps["color"];
  children?: any;
  buttonClick?: () => void;
  buttonText?: ReactNode;
}

const AnalyticsDataCard = ({
  color,
  title,
  count,
  percentage,
  isLoss,
  children,
  buttonClick,
  buttonText,
}: Props) => (
  <MainCard content={false}>
    <Box sx={{ p: 2.25 }}>
      <Stack spacing={0.5}>
        <Typography variant="h6">
          <FormattedMessage id={title} />
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            <Typography variant="h3" color="inherit">
              {count}
            </Typography>
            {percentage && percentage !== 0 && (
              <Chip
                variant="combined"
                color={color ?? (isLoss ? "error" : "success")}
                icon={
                  <>
                    {isLoss === false && (
                      <RiseOutlined style={{ fontSize: "0.75rem", color: "inherit" }} />
                    )}
                    {isLoss === true && (
                      <FallOutlined style={{ fontSize: "0.75rem", color: "inherit" }} />
                    )}
                  </>
                }
                label={`${percentage}%`}
                sx={{ ml: 1.25, pl: isLoss === undefined ? 0 : 1 }}
                size="small"
              />
            )}
          </Stack>
          {buttonClick && buttonText && (
            <Button color="secondary" size="small" onClick={buttonClick}>
              {buttonText}
            </Button>
          )}
        </Stack>
      </Stack>
    </Box>
    {children}
  </MainCard>
);

export default AnalyticsDataCard;
