import MainCard from "components/MainCard";
import { useFeatureFlags } from "experimentation/FeatureFlagProvider";
import { Flags } from "experimentation/Flags";
import { useNavigateWithModule } from "hooks/useNavigateWithModule";
import { ChargingStationTable } from "modules/site-operator/charging-stations/list";
import { ChargingStation } from "service/api-slice";
import { useModuleContext } from "types/context";

export const PaymentChargingStationsPage = () => {
  const { module } = useModuleContext();
  const navigate = useNavigateWithModule(module);
  const exp = useFeatureFlags();

  function navigateToChargingStationPayment(chargingStation: ChargingStation) {
    navigate(`payment/${chargingStation.id}`);
  }

  function filterToExperiment(chargingStation: ChargingStation) {
    const expFlagValue = exp.evaluateFeature(Flags.PreauthorizeChargingStation);
    return expFlagValue.cs_ids.includes(chargingStation.id);
  }

  return (
    <MainCard content={false}>
      <ChargingStationTable
        onClick={navigateToChargingStationPayment}
        disableBusyStations={true}
        csFilter={filterToExperiment}
      />
    </MainCard>
  );
};
