// material-ui
import {
  Box,
  Chip,
  ChipProps,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

// project import
import MainCard from "components/MainCard";

// assets
import { FallOutlined, RiseOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

const tableCellStyle = {
  border: "none", // Remove borders
  padding: 0, // Remove padding
};

interface DataItems {
  [key: string]: string;
}

export type Value = {
  value?: string;
  percentage?: number;
  isLoss?: boolean;
  dataItems?: DataItems;
  caption?: string;
};

interface Props {
  title: string;
  value: Value;
  icon?: any;
  color?: ChipProps["color"];
  children?: any;
}

const SingleMeterValue = ({ color = "primary", title, value, icon, children }: Props) => (
  <MainCard content={false} sx={{ height: "100%" }}>
    <Box sx={{ p: 2.25 }}>
      <Stack spacing={0.5}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {icon}
          <Typography variant="h6">
            <FormattedMessage id={title} />
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          {value.value === undefined && value.dataItems === undefined && (
            <Typography variant="h3" color="inherit">
              -
            </Typography>
          )}
          {value.value && (
            <Typography variant="h3" color="inherit">
              {value.value}
            </Typography>
          )}
          {value.percentage && value.percentage !== 0 && (
            <Chip
              variant="combined"
              color={color}
              icon={
                <>
                  {value.isLoss === false && (
                    <RiseOutlined style={{ fontSize: "0.75rem", color: "inherit" }} />
                  )}
                  {value.isLoss === true && (
                    <FallOutlined style={{ fontSize: "0.75rem", color: "inherit" }} />
                  )}
                </>
              }
              label={`${value.percentage}%`}
              sx={{ ml: 1.25, pl: 1 }}
              size="small"
            />
          )}
        </Stack>
        {value.caption && (
          <Typography variant="body1" color="inherit">
            {value.caption}
          </Typography>
        )}
        {value.dataItems && (
          <TableContainer sx={{ m: 0 }}>
            <Table size="small">
              <TableBody>
                {Object.keys(value.dataItems).map((key) => (
                  <TableRow hover={false} key={key}>
                    <TableCell style={tableCellStyle} align="left" sx={{ pl: 0 }}>
                      {key}
                    </TableCell>
                    <TableCell style={tableCellStyle} align="left">
                      {value.dataItems![key]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </Box>
    {children}
  </MainCard>
);

export default SingleMeterValue;
