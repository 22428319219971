import { LoadingButton } from "@mui/lab";
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import ReactMarkdown from "react-markdown";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export const FirmwareVersions = ({
  versions,
}: {
  versions: string | { name: string; version: string }[];
}) => {
  if (typeof versions === "string") {
    versions = versions.split(",").map((ent) => {
      const [k, v] = ent.split(":");
      return { name: k, version: v };
    });
  }

  return (
    <Box component="ul" sx={{ m: 0, p: 0, display: "flex", listStyle: "none", flexWrap: "wrap" }}>
      {versions.map(({ name, version }) => (
        <ListItem key={name}>
          <Chip label={`${name}: ${version}`} size="small" />
        </ListItem>
      ))}
    </Box>
  );
};

const FirmwareNotesDialog = ({
  update,
  onClose,
}: {
  update: { name: string; version: string; notes: string } | null;
  onClose: () => void;
}) => {
  if (!update) {
    return <></>;
  }
  return (
    <Dialog open={!!update} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id="firmware-notes" values={update} />
      </DialogTitle>
      <DialogContent>
        <ReactMarkdown>{update.notes}</ReactMarkdown>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={onClose}>
          <FormattedMessage id="close" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const FirmwareUpdates = ({
  updates,
}: {
  updates: { name: string; version: string; notes: string }[];
}) => {
  const [update, setUpdate] = useState<{ name: string; version: string; notes: string } | null>(
    null
  );
  return (
    <>
      <FirmwareNotesDialog update={update} onClose={() => setUpdate(null)} />
      <Typography
        variant="caption"
        sx={{
          color: "text.secondary",
          display: { xs: "none", sm: "block" },
        }}
      >
        <Stack>
          {updates.map((update) => (
            <Box onClick={() => setUpdate(update)} key={update.name}>
              {update.name}:{update.version}
            </Box>
          ))}
        </Stack>
      </Typography>
    </>
  );
};
