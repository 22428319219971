import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import MainCard from "components/MainCard";
import { Duration, formatDuration, intervalToDuration, parseISO } from "date-fns";
import { ChargeCurve } from "modules/site-operator/charging-stations/components/ChargeCurve";
import { PowerUsed } from "modules/site-operator/charging-stations/components/PowerUsed";
import SingleMeterValue from "modules/site-operator/charging-stations/components/SingleMeterValue";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  GetTransactionsArgs,
  useGetTransactionSummaryQuery,
  useGetTransactionsQuery,
} from "service/api-slice";
import { useModuleContext } from "types/context";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";

const Summary = ({ csId, txId }: { csId: string; txId: string }) => {
  const { data } = useGetTransactionSummaryQuery({
    txId,
    csId,
  });

  interface State {
    maxPower?: string | null;
    minSoC?: string | null;
    maxSoC?: string | null;
  }

  const [state, setState] = useState<State>({});

  useEffect(() => {
    const state: State = {};
    data?.data.measurands.forEach((summary) => {
      switch (summary.measurand) {
        case "Power.Active.Import":
          state.maxPower = `${summary.max / 1000} kW`;
          break;
        case "SoC":
          state.minSoC = `${summary.min} %`;
          state.maxSoC = `${summary.max === 99 ? 100 : summary.max} %`;
          break;
      }
    });
    setState(state);
  }, [data]);

  return (
    <>
      {state.minSoC && (
        <TableRow hover={false}>
          <TableCell>
            <FormattedMessage id="starting-soc" />
          </TableCell>
          <TableCell>{state.minSoC}</TableCell>
        </TableRow>
      )}
      {state.maxSoC && (
        <TableRow hover={false}>
          <TableCell>
            <FormattedMessage id="ending-soc" />
          </TableCell>
          <TableCell>{state.maxSoC}</TableCell>
        </TableRow>
      )}
      {state.maxPower && (
        <TableRow hover={false}>
          <TableCell>
            <FormattedMessage id="max-charging-speed" />
          </TableCell>
          <TableCell>{state.maxPower}</TableCell>
        </TableRow>
      )}
    </>
  );
};

const DetailReport = () => {
  const { filter } = useModuleContext();

  const [args, setArgs] = useState<GetTransactionsArgs>({
    start: filter.timeRange.start.toISOString(),
    end: filter.timeRange.end.toISOString(),
    siteId: filter.siteId,
    page: 0,
    count: 500,
    orgId: filter.orgId,
    expandVehicle: true,
  });

  useEffect(() => {
    setArgs({
      start: filter.timeRange.start.toISOString(),
      end: filter.timeRange.end.toISOString(),
      page: 0,
      count: 500,
      siteId: filter.siteId,
      orgId: filter.orgId,
      expandVehicle: true,
    });
  }, [filter.timeRange.start, filter.timeRange.end, filter.orgId, filter.siteId]);

  const { data, isError } = useGetTransactionsQuery(args);

  return (
    <Grid container spacing={2}>
      {isError && <div>Something went wrong</div>}
      {data?.data &&
        data.data.map((transaction) => {
          const chargingTime: Duration = intervalToDuration({
            start: parseISO(transaction.start_time),
            end: parseISO(transaction.end_time),
          });
          return (
            <Grid item xs={12} key={transaction.id}>
              <MainCard item>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h3">{transaction.vehicle?.description}</Typography>
                        <Typography>{transaction.vehicle?.vin}</Typography>
                        <Typography>
                          {transaction.vehicle?.license_plate_state}{" "}
                          {transaction.vehicle?.license_plate}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <SingleMeterValue
                          title="charging-time"
                          value={{
                            value: chargingTime
                              ? formatDuration(chargingTime, { format: ["hours", "minutes"] })
                              : "-",
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SingleMeterValue
                          title="energy-delivered"
                          value={{
                            value: `${
                              (transaction.meter_end - transaction.meter_start) / 1000
                            } kWh`,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TableContainer sx={{ mt: 2 }}>
                          <Table>
                            <TableBody>
                              <TableRow hover={false}>
                                <TableCell>
                                  <FormattedMessage id="start-time" />
                                </TableCell>
                                <TableCell>{parseAndFormatDate(transaction.start_time)}</TableCell>
                              </TableRow>
                              <TableRow hover={false}>
                                <TableCell>
                                  <FormattedMessage id="end-time" />
                                </TableCell>
                                <TableCell>{parseAndFormatDate(transaction.end_time)}</TableCell>
                              </TableRow>
                              <Summary
                                csId={transaction.charging_station.id}
                                txId={transaction.id}
                              />
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack sx={{ pt: 2 }}>
                          <Typography variant="caption">
                            {transaction.charging_station.name}
                          </Typography>
                          <Typography variant="caption">{transaction.id}</Typography>
                          <Typography variant="caption">{transaction.vehicle?.id}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="column" spacing={1}>
                      <ChargeCurve
                        height={205}
                        transactionId={transaction.id}
                        chargingStationId={transaction.charging_station.id}
                      />
                      <PowerUsed
                        height={205}
                        transactionId={transaction.id}
                        chargingStationId={transaction.charging_station.id}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </MainCard>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default DetailReport;
