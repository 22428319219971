import {
  Button,
  InputLabel,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, Fragment, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  ChargingStation,
  useCancelChargingStationPreauthMutation,
  usePreauthChargingStationMutation,
} from "service/api-slice";
import { LoadingButton } from "@mui/lab";
import { useFeatureFlags } from "experimentation/FeatureFlagProvider";
import { Flags } from "experimentation/Flags";
import { SubmitToReaderButton, useCardReader } from "components/payments/card-reader";
import {
  PaymentStatus,
  TestOnlyPresentPaymentButtons,
  usePaymentIntentSyncer,
} from "components/payments/payment-intent";
import CurrencyInput, { formatValue } from "react-currency-input-field";
import { openSnackbarError } from "store/reducers/snackbar";
import { useDispatch } from "react-redux";
import { CurrencyInputOnChangeValues } from "react-currency-input-field";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";
import StartTransactionButton from "modules/site-operator/charging-stations/components/StartTransactionButton";
import isValidEmail from "utils/email";

export const PreauthorizePaymentInnerForm = ({
  cs,
  refetch,
  hideForm,
}: {
  cs: ChargingStation;
  refetch: () => void;
  hideForm: () => void;
}) => {
  const cardReader = useCardReader();
  const piSyncer = usePaymentIntentSyncer("requires_capture", refetch);

  const [amount, setAmount] = useState<number>(100);
  const [soc, setSOC] = useState<number>(80);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);

  const [preauthChargingStation, preauthChargingStationResult] =
    usePreauthChargingStationMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (preauthChargingStationResult.isSuccess) {
      piSyncer.setPaymentIntent(preauthChargingStationResult.data.data.payment_intent);
      cardReader.startPaymentIntent(preauthChargingStationResult.data.data.payment_intent);
      preauthChargingStationResult.reset();
      refetch();
    } else if (preauthChargingStationResult.isError) {
      dispatch(
        openSnackbarError({
          title: "Failed to preauthorize",
          message: JSON.stringify(preauthChargingStationResult.error),
        })
      );
      preauthChargingStationResult.reset();
      refetch();
    }
  }, [cardReader, dispatch, preauthChargingStationResult, piSyncer, refetch]);

  const onPreauthClick = useCallback(
    (readerId: string) => {
      preauthChargingStation({
        csId: cs.id,
        amount_in_cents: amount * 100,
        state_of_charge: soc,

        email_receipt_to: email === "" ? undefined : email,
        reader_id: readerId,
      });
      cardReader.startOp();
    },
    [amount, cardReader, cs, email, preauthChargingStation, soc]
  );

  const onAmountChange = useCallback(
    (value?: string, name?: string, values?: CurrencyInputOnChangeValues) => {
      if (values?.float) {
        setAmount(values.float);
      } else {
        setAmount(0);
      }
    },
    [setAmount]
  );

  const onSOCSliderChange = useCallback(
    (_, value) => {
      setSOC(value as number);
    },
    [setSOC]
  );

  const onEmailChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const email = e.target.value;
      setEmailError(email !== "" && !isValidEmail(email));
      setEmail(email);
    },
    [setEmail, setEmailError]
  );

  const onCancelClick = useCallback(() => {
    hideForm();
    cardReader.reset();
  }, [cardReader, hideForm]);

  return (
    <form>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <InputLabel htmlFor="amount">
                  <FormattedMessage id="amount" />
                </InputLabel>
              </TableCell>
              <TableCell>
                <CurrencyInput
                  id="amount"
                  prefix="$"
                  decimalScale={2}
                  allowDecimals={true}
                  decimalSeparator="."
                  disabled={!!piSyncer.paymentIntent}
                  defaultValue={amount.toString()}
                  onValueChange={onAmountChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <InputLabel htmlFor="soc">
                  <FormattedMessage id="soc" />
                </InputLabel>
              </TableCell>
              <TableCell>
                <Slider
                  id="soc"
                  step={10}
                  min={50}
                  max={100}
                  marks={[
                    { value: 50, label: "50%" },
                    { value: 60 },
                    { value: 70 },
                    { value: 80, label: "80%" },
                    { value: 90 },
                    { value: 100, label: "100%" },
                  ]}
                  disabled={!!piSyncer.paymentIntent}
                  value={soc}
                  onChange={onSOCSliderChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <InputLabel htmlFor="email">
                  <FormattedMessage id="email" />
                </InputLabel>
              </TableCell>
              <TableCell>
                <TextField
                  id="email"
                  size="small"
                  fullWidth
                  value={email}
                  onChange={onEmailChange}
                  error={emailError}
                  {...(emailError
                    ? { helperText: <FormattedMessage id="enter-valid-email" /> }
                    : {})}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <SubmitToReaderButton
        disabled={
          preauthChargingStationResult.isLoading ||
          !!piSyncer.paymentIntent ||
          cardReader.isResetting ||
          emailError
        }
        onClick={onPreauthClick}
        cardReader={cardReader}
      />
      <Button variant="outlined" onClick={onCancelClick}>
        <FormattedMessage id="cancel" />
      </Button>
      <PaymentStatus cardReader={cardReader} piSyncer={piSyncer} />
      <TestOnlyPresentPaymentButtons pi={piSyncer.paymentIntent} />
    </form>
  );
};

export const PreauthorizePaymentCollapsableForm = (props: {
  cs: ChargingStation;
  refetch: () => void;
}) => {
  const [expand, setExpand] = useState<boolean>(false);

  return expand ? (
    <PreauthorizePaymentInnerForm
      {...props}
      hideForm={() => {
        setExpand(false);
      }}
    />
  ) : (
    <Button onClick={() => setExpand(true)}>
      <FormattedMessage id="preauthorize-payment" />
    </Button>
  );
};

export const PreauthorizeStatusForm = ({
  cs,
  refetch,
}: {
  cs: ChargingStation;
  refetch: () => void;
}) => {
  const [cancel, cancelResult] = useCancelChargingStationPreauthMutation();
  useEffect(() => {
    if (cancelResult.isSuccess || cancelResult.isError) {
      refetch();
    }
  }, [cancelResult, refetch]);

  const onClickCancel = (e: SyntheticEvent) => {
    e.stopPropagation();
    cancel(cs.id);
  };
  const authorization = getAuthorization(cs);
  if (!authorization) {
    return <Typography>No Preauthorization</Typography>;
  }
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <FormattedMessage id="preauth-status" />
            </TableCell>
            <TableCell>{authorization.status}</TableCell>
          </TableRow>
          {authorization.status === "Authorized" &&
            authorization.mac_address !== "" &&
            cs.status?.status === "Occupied" &&
            cs.current_transaction === null && (
              <TableRow>
                <TableCell colSpan={2}>
                  <StartTransactionButton chargingStationId={cs.id} />
                </TableCell>
              </TableRow>
            )}
          <TableRow>
            <TableCell>
              <FormattedMessage id="preauth-type" />
            </TableCell>
            <TableCell>{authorization.type}</TableCell>
          </TableRow>
          {authorization?.mac_address && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="mac-address" />
              </TableCell>
              <TableCell>{authorization?.mac_address}</TableCell>
            </TableRow>
          )}
          {authorization?.stop_conditions?.state_of_charge && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="ending-soc" />
              </TableCell>
              <TableCell>{authorization?.stop_conditions?.state_of_charge}%</TableCell>
            </TableRow>
          )}
          {authorization?.stop_conditions?.amount_in_cents && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="amount" />
              </TableCell>
              <TableCell>
                {formatValue({
                  value: (authorization?.stop_conditions?.amount_in_cents / 100).toString(),
                  decimalSeparator: ".",
                  decimalScale: 2,
                  prefix: "$",
                })}
              </TableCell>
            </TableRow>
          )}
          {authorization?.stop_conditions?.energy_wh && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="energy-delivered" />
              </TableCell>
              <TableCell>{authorization?.stop_conditions?.energy_wh} Wh</TableCell>
            </TableRow>
          )}
          {authorization?.valid_until && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="valid-until" />
              </TableCell>
              <TableCell>{parseAndFormatDate(authorization?.valid_until)}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>
              <LoadingButton
                size="small"
                loading={cancelResult.isLoading}
                variant="contained"
                color="primary"
                onClick={onClickCancel}
              >
                <FormattedMessage id="cancel-preauthorization" />
              </LoadingButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const PreauthorizePane = ({ cs, refetch }: { cs: ChargingStation; refetch: () => void }) => {
  const authStatus = getAuthorization(cs)?.status;
  return (
    <Fragment>
      {(!authStatus || ["NotStarted", "Pending"].includes(authStatus)) && (
        <PreauthorizePaymentCollapsableForm cs={cs} refetch={refetch} />
      )}
      {!!authStatus && ["Pending", "Authorized"].includes(authStatus) && (
        <PreauthorizeStatusForm cs={cs} refetch={refetch} />
      )}
    </Fragment>
  );
};

export const useIsInPreauthExperiment = () => {
  const exp = useFeatureFlags();
  const isInExperiment = useCallback(
    (cs: ChargingStation) => {
      const expFlagValue = exp.evaluateFeature(Flags.PreauthorizeChargingStation);
      return expFlagValue.cs_ids.includes(cs.id);
    },
    [exp]
  );
  return isInExperiment;
};

export const getAuthorization = (cs: ChargingStation) => {
  return cs.authorization;
};

export const isPreauthorized = (cs: ChargingStation) => {
  return !!getAuthorization(cs);
};
