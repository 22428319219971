import { FormControl, FormLabel, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useGetOrganizationsQuery } from "service/api-slice";

const OrganizationSelect = ({ name }: { name: string }) => {
  const orgs = useGetOrganizationsQuery({ page: 0, count: 500 });

  const [orgId, setOrgID] = useState("none");

  return (
    <FormControl fullWidth variant="filled" margin="normal">
      <FormLabel>
        <FormattedMessage id="organization" />
      </FormLabel>
      <TextField
        name={name}
        select
        value={orgId}
        onChange={(e) => {
          setOrgID(e.target.value);
        }}
      >
        <MenuItem value={"none"}>
          <em>{"<none>"}</em>
        </MenuItem>
        {orgs.isSuccess &&
          orgs?.data?.data?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
      </TextField>
    </FormControl>
  );
};

export default OrganizationSelect;
