// material-ui
import { EnvironmentFilled } from "@ant-design/icons";
import {
  Grid,
  Stack,
  Divider,
  Box,
  TableContainer,
  Table,
  TableFooter,
  TableRow,
  Typography,
  TableCell,
} from "@mui/material";
import MainCard from "components/MainCard";
import GoogleMapReact from "google-map-react";

// ==============================|| SAMPLE PAGE ||============================== //

const Marker = (props: { lat: number; lng: number }) => (
  <EnvironmentFilled style={{ fontSize: "1.8rem", color: "#e1f0ef" }} />
);

const SitesPage = () => {
  const center = {
    lat: 40.765152,
    lng: -73.975578,
  };

  return (
    <MainCard content={false}>
      <Grid container alignItems="top">
        <Grid item xs={12} lg={3}>
          <Stack flexDirection="column" justifyContent="space-between" height="100%">
            <Stack divider={<Divider />}>
              <Box m={2}>
                <Typography variant="h5">64th Street</Typography>
                <Typography variant="body1" mt={2}>
                  310 E 64th St <br /> New York, NY 10065
                </Typography>
              </Box>
              <Box m={2}>
                <Typography variant="h5">43rd Street</Typography>
                <Typography variant="body1" mt={2}>
                  515 W 43rd St <br /> New York, NY 10036
                </Typography>
              </Box>
            </Stack>
            <Box>
              <TableContainer>
                <Table>
                  <TableFooter>
                    <TableRow>
                      <TableCell>2 Locations</TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={9}>
          <div style={{ height: "100%", minHeight: "475px", width: "100%" }}>
            <GoogleMapReact
              options={{ styles: GoogleMapStyles }}
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
              }}
              defaultCenter={center}
              defaultZoom={14}
            >
              <Marker lat={40.76336699785517} lng={-73.9616100015615} />
              <Marker lat={40.760876} lng={-73.995834} />
            </GoogleMapReact>
          </div>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default SitesPage;

const GoogleMapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#272727",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#746855",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#242f3e",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#c1c1c1",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#b0b7c0",
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#66777f",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#38414e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#212a37",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9ca5b3",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#393a3c",
      },
      {
        lightness: -5,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels",
    stylers: [
      {
        color: "#b6b8be",
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#746855",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#0e6566",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#1f2835",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#f3d19c",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#323435",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#999b9e",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#2e3236",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#2f3948",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry.fill",
    stylers: [
      { visibility: "off" },
      {
        color: "#33353d",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [
      { visibility: "off" },
      {
        color: "#008cb4",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#7a7a7a",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#191a1a",
      },
      {
        saturation: -15,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#515c6d",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#17263c",
      },
    ],
  },
];
