import MainCard from "components/MainCard";
import { ParkingTable } from "./components/ParkingTable";
import { useModuleContext } from "types/context";

const ParkingPage = () => {
  const { filter } = useModuleContext();
  return (
    <MainCard content={false} title={"Parking Sessions"}>
      <ParkingTable siteId={filter.siteId} />
    </MainCard>
  );
};

export default ParkingPage;
