import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import TableNoResults from "components/TableNoResults";
import { TablePagination } from "components/pagination/table-pagination";
import { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { GetMeterValuesArgs, MeterValue, useGetMeterValuesQuery } from "service/api-slice";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";

export const MeterValuesTable = ({
  chargingStationdId,
  transactionId,
}: {
  chargingStationdId: string;
  transactionId?: string | null;
}) => {
  const columnHelper = createColumnHelper<MeterValue>();

  const columns = [
    columnHelper.accessor("timestamp", {
      cell: (i) => parseAndFormatDate(i.getValue()),
      header: () => "Timestamp",
    }),
    columnHelper.accessor("transaction_id", {
      cell: (i) => i.getValue(),
      header: () => "Transaction ID",
    }),
    columnHelper.accessor("value", {
      cell: (i) => i.getValue(),
      header: () => "Value",
    }),
    columnHelper.accessor("context", {
      cell: (i) => i.getValue(),
      header: () => "Context",
    }),
    columnHelper.accessor("measurand", {
      cell: (i) => i.getValue(),
      header: () => "Measurand",
    }),
    columnHelper.accessor("phase", {
      cell: (i) => i.getValue(),
      header: () => "Phase",
    }),
    columnHelper.accessor("location", {
      cell: (i) => i.getValue(),
      header: () => "Location",
    }),
    columnHelper.accessor("unit", {
      cell: (i) => i.getValue(),
      header: () => "Unit",
    }),
  ];

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });

  const [args, setArgs] = useState<GetMeterValuesArgs>({
    cpId: chargingStationdId,
    page: pageIndex,
    count: pageSize,
    txId: transactionId,
  });

  const { data, isLoading, isError } = useGetMeterValuesQuery(args);

  const pagination = useMemo(() => {
    setArgs((prev) => ({
      ...prev,
      page: pageIndex,
      count: pageSize,
    }));
    return {
      pageIndex,
      pageSize,
    };
  }, [pageIndex, pageSize]);

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    pageCount: data?.meta.pagination.page_count ?? -1,
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    table.getColumn("phase")?.toggleVisibility(false);
    table.getColumn("unit")?.toggleVisibility(false);
  }, [table]);

  // const wsContext = useContext(WebsocketContext);

  // useEffect(() => {
  //   if (wsContext.message) {
  //     // processMessage(wsContext.message);
  //   }
  // }, [wsContext.message]);

  // function processMessage(msg: any) {
  //   switch ((msg as Message<any>).type) {
  //     case "MeterValues":
  //       var meterValuesMessage = (msg as MeterValuesMessage).message;
  //       meterValuesMessage.forEach((v) => {
  //         data?.data?.push({
  //           timestamp: msg.timestamp,
  //           transaction_id: msg.transaction_id,
  //           value: v.value,
  //           context: v.context,
  //           measurand: v.measurand,
  //           phase: v.phase,
  //           location: v.location,
  //           unit: v.unit,
  //         });
  //       });
  //       break;
  //   }
  // }

  return (
    <TableContainer>
      <Table sx={{ minWidth: 350 }} aria-label="Meter Values Table">
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableCell key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>
        <TableBody>
          {(isLoading || isError) &&
            table.getHeaderGroups().map((headerGroup) => {
              return [...Array(10).keys()].map((i) => (
                <TableRow key={i}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableCell key={`${i}-${header.id}`} colSpan={header.colSpan}>
                        <Skeleton />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ));
            })}
          {table.getRowModel().rows.map((row) => {
            return (
              <TableRow key={row.id} hover={true}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {table.getRowModel().rows.length === 0 && (
            <TableNoResults columnCount={table.getAllLeafColumns().length} />
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell sx={{ p: 2 }} colSpan={table.getAllLeafColumns().length}>
              <TablePagination
                gotoPage={table.setPageIndex}
                totalPages={table.getPageCount()}
                setPageSize={table.setPageSize}
                pageIndex={pageIndex}
                pageSize={pageSize}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
