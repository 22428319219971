import { DownOutlined, UpOutlined, UploadOutlined } from "@ant-design/icons";
import { ScheduleOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import MainCard from "components/MainCard";
import TableNoResults from "components/TableNoResults";
import TableRowSkeleton from "components/TableRowSkeleton";
import { format } from "date-fns";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import {
  ChargingStation,
  FirmwareRelease,
  FirmwareUpdate,
  useCreateFirmwareReleaseMutation,
  useCreateFirmwareRolloutMutation,
  useGetChargingStationsQuery,
  useGetFirmwareReleasesQuery,
  useGetFirmwareRolloutQuery,
  useGetFirmwareRolloutsQuery,
} from "service/api-slice";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";
import ChargingStationName from "../charging-stations/components/ChargingStationName";
import { FirmwareUpdates, FirmwareVersions } from "./components/firmware-version";

type SeverityEnum = "info" | "error" | "success";
type AlertCallback = (severity: SeverityEnum, msg: ReactNode) => void;

const suggestReleaseName = () => {
  const now = new Date();
  return format(now, "yyyyMMdd");
};

const suggestRolloutName = (releaseName: string, rollouts?: string[]) => {
  if (!rollouts) {
    return `${releaseName}-????`;
  }

  const next = rollouts.length + 1;
  return `${releaseName}-${next.toString().padStart(2, "0")}`;
};

const isValidReleaseName = (name: string) => {
  return name.match(/^[a-zA-Z0-9_-]{8,20}$/);
};

const isValidRolloutName = (name: string) => {
  return name.match(/^[a-zA-Z0-9_-]{11,24}$/);
};

const ExpandButton = ({
  expanded,
  setExpanded,
}: {
  expanded: boolean;
  setExpanded: (e: boolean) => void;
}) => {
  return (
    <IconButton aria-label="expand row" size="small" onClick={() => setExpanded(!expanded)}>
      {expanded ? <DownOutlined /> : <UpOutlined />}
    </IconButton>
  );
};

const CreateReleaseForm = ({
  refresh,
  onAlert,
}: {
  refresh: () => void;
  onAlert: AlertCallback;
}) => {
  const [expandForm, setExpandForm] = useState(false);
  const [releaseName, setReleaseName] = useState(suggestReleaseName());

  const [file, setFile] = useState<File | null>(null);
  const [fileContents, setFileContents] = useState<ArrayBuffer | null>(null);
  const [upload, uploadResult] = useCreateFirmwareReleaseMutation();

  const fileChosenHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length === 1) {
      setFileContents(null);
      const f = e.target.files[0];
      setFile(f);
    }
  };

  useEffect(() => {
    if (uploadResult.isError) {
      refresh();
      onAlert("error", <FormattedMessage id="error-occured" />);
      setExpandForm(false);

      console.debug(uploadResult.error);

      // Maintain the old form values so that they can be adjusted and retried.
      uploadResult.reset();
    } else if (uploadResult.isSuccess) {
      refresh();
      onAlert("success", <FormattedMessage id="firmware-uploaded" />);
      setExpandForm(false);

      setFile(null);
      setFileContents(null);
      setReleaseName(suggestReleaseName());

      uploadResult.reset();
    }
  }, [onAlert, refresh, setFile, setExpandForm, setFileContents, setReleaseName, uploadResult]);

  useEffect(() => {
    if (file && !fileContents) {
      file
        .arrayBuffer()
        .then(setFileContents)
        .catch((reason: any) => {
          onAlert("error", <FormattedMessage id="error-occured" />);
          console.debug("Failed to read file contents", reason);

          setFile(null);
        });
    }
  }, [file, fileContents, onAlert, setFileContents]);

  const onCancel = () => {
    setExpandForm(false);
    if (uploadResult.isLoading) {
      uploadResult.reset();
    }
  };

  const onCreate = () => {
    if (!file || !fileContents) {
      console.debug("Unexpected missing data in CreateReleaseForm ", file, fileContents);
      return;
    }
    upload({
      release_name: releaseName,
      filename: file.name,
      data: fileContents,
    });
  };

  const isFilledOut = releaseName && file && fileContents && !uploadResult.isLoading;

  return (
    <Stack direction="row" sx={{ width: 1 }} spacing={2}>
      <LoadingButton
        disabled={expandForm || !uploadResult.isUninitialized}
        variant="outlined"
        startIcon={<UploadOutlined />}
        onClick={() => {
          setExpandForm(!expandForm);
        }}
      >
        <FormattedMessage id="upload-firmware" />
      </LoadingButton>
      <Dialog open={expandForm} onClose={onCancel}>
        <DialogTitle>
          <FormattedMessage id="upload-firmware" />
        </DialogTitle>
        <DialogContent>
          <FormControl margin="normal" disabled={uploadResult.isLoading}>
            <TextField
              label="Release Name"
              value={releaseName}
              sx={{ minWidth: 300 }}
              fullWidth
              error={!isValidReleaseName(releaseName)}
              onChange={(e) => setReleaseName(e.target.value)}
            />
            <LoadingButton
              component="label"
              variant="outlined"
              sx={{ mt: 2 }}
              loading={file != null && fileContents == null}
              disabled={uploadResult.isLoading || uploadResult.isSuccess || uploadResult.isError}
              endIcon={<UploadOutlined />}
            >
              {file?.name || <FormattedMessage id="choose-file" />}
              <input hidden type="file" accept="application/zip" onChange={fileChosenHandler} />
            </LoadingButton>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ pr: 3, pb: 2 }}>
          <LoadingButton
            loading={uploadResult.isLoading}
            disabled={!isFilledOut}
            variant="contained"
            color="primary"
            onClick={onCreate}
          >
            <FormattedMessage id="upload-firmware" />
          </LoadingButton>
          <LoadingButton onClick={onCancel} variant="outlined" color="secondary">
            <FormattedMessage id="cancel" />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

const columnHelper = createColumnHelper<ChargingStation>();
const columns = [
  columnHelper.accessor("name", {
    cell: (i) => <ChargingStationName chargingStation={i.row.original} />,
    header: () => <FormattedMessage id="name" />,
    meta: { showOnMobile: true },
    sortingFn: "alphanumeric",
  }),
  columnHelper.accessor("firmware_version", {
    cell: (i) => <FirmwareVersions versions={i.getValue()} />,
    header: () => <FormattedMessage id="firmware-versions" />,
    meta: { showOnMobile: false },
  }),
];

const ChargingStationsPicker = ({
  chargingStations,
  setChargingStations,
  fullScreen,
}: {
  chargingStations: Set<string>;
  setChargingStations: (cs: Set<string>) => void;
  fullScreen: Boolean;
}) => {
  const chargingStationsQuery = useGetChargingStationsQuery();

  const table = useReactTable({
    data: chargingStationsQuery.data?.data ?? [],
    columns: columns,
    initialState: {
      sorting: [
        {
          id: "name",
          desc: false,
        },
      ],
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <MainCard content={false}>
      <TableContainer sx={{ maxHeight: fullScreen ? undefined : 440 }}>
        <Table stickyHeader>
          <TableBody>
            <TableRowSkeleton
              isLoading={chargingStationsQuery.isLoading}
              columnCount={3}
              rowCount={5}
            />
            {chargingStationsQuery.isError && (
              <TableRow>
                <TableCell>
                  <Alert severity="error">{chargingStationsQuery.error}</Alert>
                </TableCell>
              </TableRow>
            )}
            {table.getRowModel().rows.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Checkbox
                      checked={chargingStations?.has(row.original.id) || false}
                      onClick={() => {
                        const newSet = new Set(chargingStations);
                        if (newSet.has(row.original.id)) {
                          newSet.delete(row.original.id);
                        } else {
                          newSet.add(row.original.id);
                        }
                        setChargingStations(newSet);
                      }}
                    />
                  </TableCell>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <TableCell
                        key={cell.id}
                        sx={{
                          display: {
                            xs: cell.column.columnDef.meta!!.showOnMobile ? "table-cell" : "none",
                            sm: "table-cell",
                          },
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {chargingStationsQuery.data?.data?.length === 0 && <TableNoResults columnCount={3} />}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

const mostRecentTimeStep = () =>
  new Date(Math.floor(new Date().getTime() / (15 * 60 * 1000)) * 15 * 60 * 1000);

type NewRollout = {
  name: string | null;
  downloadAt: Date | null;
  installAt: Date | null;
  chargingStations: Set<string>;
};

const newRolloutTemplate = (): NewRollout => {
  return {
    name: null,
    downloadAt: mostRecentTimeStep(),
    installAt: mostRecentTimeStep(),
    chargingStations: new Set(),
  };
};

const CreateRolloutForm = ({
  releaseName,
  suggestedRolloutName,
  refresh,
  onAlert,
}: {
  releaseName: string;
  suggestedRolloutName: string;
  refresh: () => void;
  onAlert: AlertCallback;
}) => {
  const [expandForm, setExpandForm] = useState(false);
  const [newRollout, setNewRollout] = useState<NewRollout>(newRolloutTemplate());

  const [createRollout, createRolloutResult] = useCreateFirmwareRolloutMutation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (createRolloutResult.isError) {
      refresh();

      console.debug(createRolloutResult.error);
      onAlert("error", <FormattedMessage id="error-occured" />);
      setExpandForm(false);
      createRolloutResult.reset();
    } else if (createRolloutResult.isSuccess) {
      refresh();

      onAlert("success", <FormattedMessage id="rollout-scheduled" />);
      setExpandForm(false);
      setNewRollout(newRolloutTemplate());
      createRolloutResult.reset();
    }
  }, [createRolloutResult, onAlert, refresh, releaseName, setExpandForm, setNewRollout]);

  const onCancel = () => {
    setExpandForm(false);
  };

  const onCreate = () => {
    if (!isValidRollout(newRollout, suggestedRolloutName)) {
      console.error(
        "Unexpected missing data in CreateRolloutForm ",
        releaseName,
        suggestedRolloutName,
        newRollout
      );
      return;
    }

    createRollout({
      releaseName: releaseName,
      rolloutName: getRolloutName(newRollout, suggestedRolloutName),
      downloadAt: newRollout.downloadAt?.toISOString() ?? "",
      installAt: newRollout.installAt?.toISOString() ?? "",
      chargingStationIds: Array.from(newRollout.chargingStations),
    });
    refresh();
  };

  const getRolloutName = (newRollout: NewRollout, suggestedRolloutName: string) => {
    if (!newRollout.name || newRollout.name.length === 0) {
      return suggestedRolloutName;
    }
    return newRollout.name;
  };

  const isValidRollout = (rollout: NewRollout, suggestedRolloutName: string): boolean => {
    const rolloutName = getRolloutName(rollout, suggestedRolloutName);
    return (
      (rolloutName.length > 0 &&
        rollout.downloadAt &&
        rollout.installAt &&
        rollout.chargingStations.size > 0) ||
      false
    );
  };

  return (
    <>
      <LoadingButton
        disabled={expandForm || !createRolloutResult.isUninitialized}
        variant="outlined"
        startIcon={<ScheduleOutlined />}
        onClick={() => {
          setExpandForm(true);
        }}
      >
        <FormattedMessage id="schedule-rollout" />
      </LoadingButton>
      <Dialog open={expandForm} onClose={onCancel} fullScreen={mobile}>
        <DialogTitle>
          <FormattedMessage id="schedule-rollout" />
        </DialogTitle>
        <DialogContent>
          <form>
            <FormControl disabled={createRolloutResult.isLoading} margin="normal" fullWidth>
              <Stack spacing={2}>
                <TextField
                  variant="outlined"
                  fullWidth
                  error={!isValidRolloutName(getRolloutName(newRollout, suggestedRolloutName))}
                  label={<FormattedMessage id="rollout-id" />}
                  value={getRolloutName(newRollout, suggestedRolloutName)}
                  onChange={(e) =>
                    setNewRollout({
                      ...newRollout,
                      name: e.target.value,
                    })
                  }
                />
                <DateTimePicker
                  value={newRollout.downloadAt}
                  label={<FormattedMessage id="download-at" />}
                  referenceDate={mostRecentTimeStep()}
                  onChange={(value) => setNewRollout({ ...newRollout, downloadAt: value })}
                  timeSteps={{ minutes: 15 }}
                  skipDisabled
                  minTime={mostRecentTimeStep()}
                  slotProps={{
                    actionBar: { actions: ["accept", "cancel"] },
                    shortcuts: { items: [{ label: "Now", getValue: mostRecentTimeStep }] },
                  }}
                />
                <DateTimePicker
                  value={newRollout.installAt}
                  label={<FormattedMessage id="install-at" />}
                  referenceDate={mostRecentTimeStep()}
                  onChange={(value) => setNewRollout({ ...newRollout, installAt: value })}
                  timeSteps={{ minutes: 15 }}
                  skipDisabled
                  minTime={newRollout.downloadAt}
                  slotProps={{
                    actionBar: { actions: ["accept", "cancel"] },
                    shortcuts: { items: [{ label: "Now", getValue: mostRecentTimeStep }] },
                  }}
                />
                <ChargingStationsPicker
                  chargingStations={newRollout.chargingStations}
                  fullScreen={mobile}
                  setChargingStations={(newSet) =>
                    setNewRollout({ ...newRollout, chargingStations: newSet })
                  }
                />
              </Stack>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions sx={{ pr: 3, pb: 2 }}>
          <LoadingButton
            disabled={!isValidRollout(newRollout, suggestedRolloutName)}
            loading={createRolloutResult.isLoading}
            variant="contained"
            onClick={onCreate}
          >
            <FormattedMessage id="schedule-rollout" />
          </LoadingButton>
          <LoadingButton onClick={onCancel} variant="outlined" color="secondary">
            <FormattedMessage id="cancel" />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const UpdatesTable = ({
  updates,
  polling,
}: {
  updates: FirmwareUpdate[] | null | undefined;
  polling: boolean;
}) => {
  const query = useGetChargingStationsQuery();
  const csMap = useMemo(() => {
    return new Map<string, ChargingStation>(query.data?.data?.map((cs) => [cs.id, cs]));
  }, [query]);
  const lookupChargingStationNameOrId = (id: string) => {
    const cs = csMap.get(id);
    return cs ? cs.name : id;
  };
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="charging-station" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="request-id" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="submit-status" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="firmware-status" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="last-updated" />
            </TableCell>
            <TableCell align="right">{polling && <CircularProgress size={13} />}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowSkeleton isLoading={!updates} columnCount={1} rowCount={5} />
          {updates?.map((update) => (
            <TableRow key={update.request_id}>
              <TableCell>
                <Link to={`/site-operator/charging-stations/${update.charging_station_id}`}>
                  {lookupChargingStationNameOrId(update.charging_station_id)}
                </Link>
              </TableCell>
              <TableCell>{update.request_id}</TableCell>
              <TableCell>{update.submit_status}</TableCell>
              <TableCell>{update.firmware_status}</TableCell>
              <TableCell colSpan={2}>{parseAndFormatDate(update.last_update)}</TableCell>
            </TableRow>
          ))}
          {updates?.length === 0 && <TableNoResults columnCount={1} />}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const FirmwareRolloutRow = ({
  releaseName,
  rolloutName,
}: {
  releaseName: string;
  rolloutName: string;
}) => {
  const PollingInterval = 10_000;

  const [openUpdateList, setOpenUpdateList] = useState(false);
  const [pollingInterval, setPollingInterval] = useState<number | undefined>(undefined);
  const rolloutQuery = useGetFirmwareRolloutQuery(
    {
      release_name: releaseName,
      rollout_name: rolloutName,
    },
    {
      pollingInterval: openUpdateList ? pollingInterval : undefined,
    }
  );

  useEffect(() => {
    const updates = rolloutQuery.data?.data?.updates ?? [];
    const requiresPolling = updates.find((u) => u.firmware_status !== "Installed");
    setPollingInterval(requiresPolling ? PollingInterval : undefined);
  }, [rolloutQuery.data]);

  const openListCallback = useCallback(() => {
    setOpenUpdateList(!openUpdateList);
  }, [openUpdateList, setOpenUpdateList]);

  return (
    <>
      <TableRow onClick={openListCallback} sx={{ cursor: "pointer" }}>
        <TableCell>
          <ExpandButton expanded={openUpdateList} setExpanded={setOpenUpdateList} />
        </TableCell>
        <TableCell>{rolloutName}</TableCell>
        <TableCell>
          {rolloutQuery.data?.data?.download_at
            ? parseAndFormatDate(rolloutQuery.data.data.download_at)
            : "-"}
        </TableCell>
        <TableCell>
          {rolloutQuery.data?.data?.install_at
            ? parseAndFormatDate(rolloutQuery.data.data.install_at)
            : "-"}
        </TableCell>
      </TableRow>
      {openUpdateList && (
        <TableRow>
          <TableCell></TableCell>
          <TableCell colSpan={3}>
            <MainCard content={false}>
              <UpdatesTable
                updates={rolloutQuery.data?.data?.updates}
                polling={pollingInterval !== undefined}
              />
            </MainCard>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const RolloutsTable = ({ releaseName, rollouts }: { releaseName: string; rollouts: string[] }) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <FormattedMessage id="rollout-id" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="download-at" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="install-at" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowSkeleton isLoading={!rollouts} columnCount={3} rowCount={5} />
          {rollouts.map((rollout) => (
            <FirmwareRolloutRow key={rollout} releaseName={releaseName} rolloutName={rollout} />
          ))}
          {rollouts?.length === 0 && <TableNoResults columnCount={3} />}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const FirmwareReleaseRow = ({
  release,
  startOpened,
  onAlert,
}: {
  release: FirmwareRelease;
  startOpened: boolean;
  onAlert: AlertCallback;
}) => {
  const [openRolloutList, setOpenRolloutList] = useState(startOpened);
  const firmwareRollouts = useGetFirmwareRolloutsQuery({ release_name: release.id });
  const theme = useTheme();

  return (
    <>
      <TableRow onClick={() => setOpenRolloutList(!openRolloutList)} sx={{ cursor: "pointer" }}>
        <TableCell>
          <ExpandButton expanded={openRolloutList} setExpanded={setOpenRolloutList} />
        </TableCell>
        <TableCell>{release.id}</TableCell>
        <TableCell>
          {release.updates && <FirmwareUpdates updates={release.updates} />}
          {!release.updates && release.versions && <FirmwareVersions versions={release.versions} />}
        </TableCell>
        <TableCell>{parseAndFormatDate(release.created_at)}</TableCell>
        <TableCell>{release.path}</TableCell>
        <TableCell>{release.original_filename}</TableCell>
      </TableRow>
      {openRolloutList && (
        <>
          {release.updates && release.updates.length > 0 && (
            <TableRow
              sx={{ "& > *": { borderBottom: "unset" }, background: theme.palette.primary.lighter }}
            >
              <TableCell colSpan={6}>
                <MainCard
                  collapsible={{ expanded: false }}
                  title={<FormattedMessage id="release-notes" />}
                >
                  <ReactMarkdown>{release.updates.map((u) => u.notes).join("\n\n")}</ReactMarkdown>
                </MainCard>
              </TableCell>
            </TableRow>
          )}
          <TableRow sx={{ background: theme.palette.primary.lighter }}>
            <TableCell colSpan={6}>
              <MainCard
                content={false}
                title={`Rollouts for ${release.id}`}
                secondary={
                  <CreateRolloutForm
                    releaseName={release.id}
                    suggestedRolloutName={suggestRolloutName(
                      release.id,
                      firmwareRollouts.data?.data.rollouts
                    )}
                    refresh={firmwareRollouts.refetch}
                    onAlert={onAlert}
                  />
                }
              >
                <RolloutsTable
                  releaseName={release.id}
                  rollouts={firmwareRollouts.data?.data?.rollouts || []}
                />
              </MainCard>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

const ReleasesTable = ({
  releases,
  isLoading,
  onAlert,
}: {
  releases: FirmwareRelease[] | null | undefined;
  isLoading: boolean;
  onAlert: AlertCallback;
}) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <FormattedMessage id="release-id" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="firmware-versions" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="created-at" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="path" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="original-filename" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowSkeleton isLoading={isLoading} columnCount={6} rowCount={10} />
          {releases?.map((release, idx) => (
            <FirmwareReleaseRow
              key={release.id}
              release={release}
              startOpened={idx === 0}
              onAlert={onAlert}
            />
          ))}
          {(!releases || releases.length === 0) && <TableNoResults columnCount={6} />}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const FirmwareReleasesPage = () => {
  const releasesQuery = useGetFirmwareReleasesQuery();
  const [alertbar, setAlertbar] = useState<{ severity: SeverityEnum; msg: ReactNode } | null>(null);
  const onAlert = (severity: SeverityEnum, msg: ReactNode) => {
    setAlertbar({ severity, msg });
  };
  return (
    <>
      {alertbar && (
        <Alert severity={alertbar?.severity ?? "success"} onClose={() => setAlertbar(null)}>
          {alertbar?.msg}
        </Alert>
      )}
      <MainCard
        content={false}
        title={<FormattedMessage id="maintenance-firmware-updates" />}
        secondary={<CreateReleaseForm refresh={releasesQuery.refetch} onAlert={onAlert} />}
      >
        <ReleasesTable
          releases={releasesQuery.data?.data?.releases}
          onAlert={onAlert}
          isLoading={releasesQuery.isLoading}
        />
      </MainCard>
    </>
  );
};

export default FirmwareReleasesPage;
