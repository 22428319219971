import { Skeleton } from "@mui/material";
import { useEffect, useRef } from "react";
import {
  DetectionResult,
  VehicleImage,
  isLicensePlateDetection,
  isVinDetection,
  useGetVehicleImageQuery,
} from "service/api-slice";

const AnnotatedVehicleImage = ({
  vehicleImage,
  opacity = 1.0,
}: {
  vehicleImage: VehicleImage;
  opacity?: number;
}) => {
  const { isLoading, data } = useGetVehicleImageQuery(vehicleImage.id);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const allDetections = vehicleImage.parsed_data?.detections?.filter(
    (detection) => detection.detected
  );

  const vinDetection = allDetections?.find(isVinDetection);
  const plateDetection = allDetections?.find(isLicensePlateDetection);
  const detections = [vinDetection, plateDetection].filter((d) => d) as DetectionResult[];

  useEffect(() => {
    if (!data?.url) {
      return;
    }

    const image = new Image();
    image.src = data.url;
    image.onload = () => {
      const canvas = canvasRef.current;
      const ctx = canvasRef.current?.getContext("2d");
      if (!canvas || !ctx) {
        return;
      }

      ctx.save();
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (detections && detections.length > 0) {
        scaleToFitAndDrawDetections(ctx, canvas, image, detections);
      } else {
        scaleToFitAndDraw(ctx, canvas, image);
      }
      ctx.restore();
    };
  }, [data, detections]);

  return isLoading ? (
    <Skeleton variant="rectangular" width={"100%"} height={250} />
  ) : (
    <canvas
      ref={canvasRef}
      height={250}
      style={{ width: "100%", opacity }}
      onMouseEnter={() => {
        // change cursor to pointer when hovering over canvas
        canvasRef.current!.style.cursor = "pointer";
      }}
      onClick={(e) => {
        // open image in new tab when clicking on canvas
        e.preventDefault();
        window.open(data?.url, "_blank");
      }}
    />
  );
};

export default AnnotatedVehicleImage;

const scaleToFitAndDraw = (
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  image: HTMLImageElement
) => {
  // scale to fit to canvas width, expanding height if needed,
  // while maintaining aspect ratio
  const ratio = canvas.width / image.naturalWidth;
  const newHeight = image.naturalHeight * ratio;
  canvas.height = newHeight;
  ctx.scale(ratio, ratio);
  ctx.drawImage(image, 0, 0);
  ctx.restore();
};

const scaleToFitAndDrawDetections = (
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  image: HTMLImageElement,
  detections: DetectionResult[]
) => {
  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;

  const allBounds = detections.flatMap((detection) => detection.bounds);

  const startX = allBounds.reduce((acc, bound) => Math.min(acc, bound[0]), Infinity);
  const startY = allBounds.reduce((acc, bound) => Math.min(acc, bound[1]), Infinity);
  const boundingBoxWidth = allBounds.reduce((acc, bound) => Math.max(acc, bound[0]), 0) - startX;
  const boundingBoxHeight = allBounds.reduce((acc, bound) => Math.max(acc, bound[1]), 0) - startY;

  // We want to add a 30% buffer around the bounding box to ensure that the bounding box
  // is not too close to the edge of the canvas.
  const buffer = 1.3;
  let enlargedBoundingBoxWidth = Math.min(
    image.naturalWidth,
    Math.max(boundingBoxWidth * buffer, canvasWidth)
  );
  let enlargedBoundingBoxHeight = Math.min(
    image.naturalHeight,
    Math.max(boundingBoxHeight * buffer, canvasHeight)
  );
  let newStartX = Math.max(0, startX - (enlargedBoundingBoxWidth - boundingBoxWidth) / 2);
  let newStartY = Math.max(0, startY - (enlargedBoundingBoxHeight - boundingBoxHeight) / 2);

  // Determine if we need to scale the image up or down to fit the bounding box
  // on the canvas. If the bounding box is larger than the canvas, we need to scale
  // the image down. If the bounding box is smaller than the canvas, we need to scale
  // the image up. We want to prioritize filling the width of the canvas, so we will
  // scale the image to fit the width of the canvas and then expand the height to fit
  // the bounding box height.
  let scale = 1;
  if (enlargedBoundingBoxHeight > canvasHeight) {
    scale = canvasHeight / enlargedBoundingBoxHeight;
    enlargedBoundingBoxWidth = enlargedBoundingBoxWidth / scale;
    newStartX = Math.max(0, startX - (enlargedBoundingBoxWidth - boundingBoxWidth) / 2);
  }
  // only scale if we didn't already scale the width
  if (scale === 1 && enlargedBoundingBoxWidth > canvasWidth) {
    scale = canvasWidth / enlargedBoundingBoxWidth;
    enlargedBoundingBoxHeight = enlargedBoundingBoxHeight / scale;
    newStartY = Math.max(0, startY - (enlargedBoundingBoxHeight - boundingBoxHeight) / 2);
  }

  ctx.scale(scale, scale);
  ctx.drawImage(
    image,
    // sub section of image to draw
    newStartX,
    newStartY,
    enlargedBoundingBoxWidth,
    enlargedBoundingBoxHeight,
    // where to draw on the canvas
    0,
    0,
    enlargedBoundingBoxWidth,
    enlargedBoundingBoxHeight
  );

  // draw bounding box around all detections
  drawBoundingBox(
    ctx,
    [
      [startX, startY],
      [startX + boundingBoxWidth, startY],
      [startX + boundingBoxWidth, startY + boundingBoxHeight],
      [startX, startY + boundingBoxHeight],
    ],
    1 / scale,
    "green",
    newStartX,
    newStartY
  );

  const vinDetection = detections.find(isVinDetection);
  if (vinDetection) {
    drawBoundingBox(ctx, vinDetection.bounds, 1 / scale, "blue", newStartX, newStartY);
  }

  const plateDetection = detections.find(isLicensePlateDetection);
  if (plateDetection) {
    drawBoundingBox(ctx, plateDetection.bounds, 1 / scale, "yellow", newStartX, newStartY);
  }
};

const drawBoundingBox = (
  ctx: CanvasRenderingContext2D,
  bounds: number[][],
  stokeWidth: number = 1,
  strokeStyle: string | CanvasGradient | CanvasPattern = "blue",
  offsetX: number = 0,
  offsetY: number = 0
) => {
  ctx.lineWidth = stokeWidth;
  ctx.strokeStyle = strokeStyle;
  bounds.forEach((bound, i) => {
    if (i === 0) {
      ctx.beginPath();
      ctx.moveTo(bound[0] - offsetX, bound[1] - offsetY);
    } else {
      ctx.lineTo(bound[0] - offsetX, bound[1] - offsetY);
    }
  });
  ctx.closePath();
  ctx.stroke();
};
