import Grid from "@mui/material/Grid";
import EnergyUsedChart from "components/charts/EnergyUsed";
import TotalSessionsChart from "components/charts/TotalSessions";
import MainCard from "components/MainCard";
import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  ChargePoint,
  GetOcppMessagesArg,
  GetSessionsArgs,
  OcppMessage,
  useGetChargePointsQuery,
  useGetOcppMessagesQuery,
} from "../../../service/api-slice";

import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  IconButton,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import JsonViewer from "components/JsonViewer";
import { startOfYear } from "date-fns";
import endOfDay from "date-fns/endOfDay";
import { SessionsTable, TablePagination } from "pages/sessions";
import { useParams } from "react-router";

const now = new Date();
const defaultStartTime = startOfYear(now);
const defaultEndTime = endOfDay(now);

const ChargePointDetailPage = () => {
  const cpId = useParams()["id"];

  const [sessionArgs] = useState<GetSessionsArgs>({
    start: defaultStartTime.toISOString(),
    end: defaultEndTime.toISOString(),
    count: 25,
    page: 0,
    cpId: cpId,
    siteId: "10",
  });

  const { data } = useGetChargePointsQuery();

  let cp: ChargePoint | undefined;
  if (data) {
    cp = data?.data?.find((i) => cpId === i.id);
  }

  const [selectedTab, setSelectedTab] = useState("sessions");

  const handleTabChanged = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      {/* row 1 */}
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MainCard height="100%">
          {cp && (
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow hover={false}>
                    <TableCell>Status</TableCell>
                    <TableCell>{cp.status?.status}</TableCell>
                  </TableRow>
                  <TableRow hover={false}>
                    <TableCell>S/N</TableCell>
                    <TableCell>{cp.serial_number}</TableCell>
                  </TableRow>
                  <TableRow hover={false}>
                    <TableCell>Model</TableCell>
                    <TableCell>{cp.model}</TableCell>
                  </TableRow>
                  <TableRow hover={false}>
                    <TableCell>Firmware</TableCell>
                    <TableCell>{cp.firmware_version}</TableCell>
                  </TableRow>
                  <TableRow hover={false}>
                    <TableCell>Connectors</TableCell>
                    <TableCell>1</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </MainCard>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <EnergyUsedChart height={190} sessionArgs={sessionArgs} />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TotalSessionsChart height={190} sessionArgs={sessionArgs} />
      </Grid>

      {/* row 2 */}
      <Grid item xs={12} md={12} lg={12}>
        <MainCard content={false}>
          <TabContext value={selectedTab}>
            <TabList onChange={handleTabChanged} aria-label="Charge Point Data">
              <Tab value="sessions" label={<FormattedMessage id="sessions" />} />
              <Tab value="meter-readings" label={<FormattedMessage id="meter-reading" />} />
              <Tab value="messages" label={<FormattedMessage id="messages" />} />
            </TabList>
            <TabPanel value="sessions" sx={{ padding: 0 }}>
              <SessionsTable cpId={cpId} />
            </TabPanel>
            <TabPanel value="meter-readings">
              <Typography variant="h4">No data available</Typography>
            </TabPanel>
            <TabPanel value="messages" sx={{ padding: 0 }}>
              <OcppMessagesTable cpId={cpId as string} />
            </TabPanel>
          </TabContext>
        </MainCard>
      </Grid>

      <Grid item md={8} sx={{ display: { sm: "none", md: "block", lg: "none" } }} />
    </Grid>
  );
};

export default ChargePointDetailPage;

const columnHelper = createColumnHelper<OcppMessage>();

const columns = [
  columnHelper.display({
    id: "expander",
    header: () => null,
    cell: ({ row }) => {
      return row.getCanExpand() ? (
        <IconButton aria-label="expand row" size="small" onClick={row.getToggleExpandedHandler()}>
          {row.getIsExpanded() ? <DownOutlined /> : <RightOutlined />}
        </IconButton>
      ) : (
        <></>
      );
    },
  }),
  columnHelper.accessor("created_at", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="message-time" />,
  }),
  columnHelper.accessor("unique_id", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="unique-id" />,
  }),
  columnHelper.accessor("message_type", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="message-type" />,
  }),
  columnHelper.accessor("origin", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="origin" />,
  }),
  columnHelper.accessor("action", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="action" />,
  }),
];

const OcppMessagesTable = (props: { cpId: string }) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });

  const [queryArgs, setQueryArgs] = useState<GetOcppMessagesArg>({
    cpId: props.cpId,
    count: pageSize,
    page: pageIndex,
  });

  const { data, isLoading, isError } = useGetOcppMessagesQuery(queryArgs);

  const pagination = useMemo(() => {
    setQueryArgs({
      page: pageIndex,
      count: pageSize,
      cpId: props.cpId,
    });
    return {
      pageIndex,
      pageSize,
    };
  }, [props.cpId, pageIndex, pageSize]);

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    pageCount: data?.meta.pagination.page_count ?? -1,
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand: (row) => row.original.payload,
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <TableContainer>
      <Table aria-label="OCPP Messages Table">
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableCell key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>
        <TableBody>
          {(isLoading || isError) &&
            table.getHeaderGroups().map((headerGroup) => {
              return [...Array(10).keys()].map((i) => (
                <TableRow key={i}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableCell key={`${i}-${header.id}`} colSpan={header.colSpan}>
                        <Skeleton />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ));
            })}
          {table.getRowModel().rows.map((row) => {
            return (
              <>
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <TableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
                {row.getIsExpanded() && (
                  <TableRow>
                    <TableCell sx={{ p: 0 }} colSpan={row.getVisibleCells().length}>
                      <JsonViewer json={row.original.payload} />
                    </TableCell>
                  </TableRow>
                )}
              </>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell sx={{ p: 2 }} colSpan={table.getAllLeafColumns().length}>
              <TablePagination
                gotoPage={table.setPageIndex}
                totalPages={table.getPageCount()}
                setPageSize={table.setPageSize}
                pageIndex={pageIndex}
                pageSize={pageSize}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
