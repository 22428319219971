import Alert from "@mui/material/Alert";
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableRow } from "@mui/material";
import { TablePagination } from "components/pagination/table-pagination";
import TableNoResults from "components/TableNoResults";
import TableRowSkeleton from "components/TableRowSkeleton";
import VehicleName from "modules/site-operator/charging-stations/components/VehicleName";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import { ParkingSession, useGetParkingSessionsQuery } from "service/api-slice";
import { PaginationState } from "@tanstack/react-table";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";

const ParkingRow = ({ session }: { session: ParkingSession }) => {
  const navigate = useNavigate();
  const navigateToSession = () => {
    navigate(`/site-operator/parking/${session.id}`);
  };
  const departed = session.state === "Departed";
  const hasVehicleLocation = !!session.vehicle_location;
  const hasKeyLocation = !!session.key_location;
  const numRows = 1 + (!departed ? 1 : 0) + (hasVehicleLocation ? 1 : 0) + (hasKeyLocation ? 1 : 0);
  return (
    <>
      <TableRow style={{ borderTop: "2px solid" }} onClick={navigateToSession}>
        <TableCell rowSpan={numRows} style={{ verticalAlign: "top" }}>
          <VehicleName vehicle={session.vehicle} />
        </TableCell>
        <TableCell>
          <FormattedMessage id={session.state.toLowerCase()} />
        </TableCell>
        <TableCell>{parseAndFormatDate(session.state_time)}</TableCell>
      </TableRow>
      {!departed && (
        <TableRow onClick={navigateToSession}>
          <TableCell>
            <FormattedMessage id="expected-pickup" />
          </TableCell>
          <TableCell>{parseAndFormatDate(session.end_time)}</TableCell>
        </TableRow>
      )}
      {hasVehicleLocation && (
        <TableRow onClick={navigateToSession}>
          <TableCell>
            <FormattedMessage id="vehicle" />
          </TableCell>
          <TableCell>{session.vehicle_location}</TableCell>
        </TableRow>
      )}
      {hasKeyLocation && (
        <TableRow onClick={navigateToSession}>
          <TableCell>
            <FormattedMessage id="key" />
          </TableCell>
          <TableCell>{session.key_location}</TableCell>
        </TableRow>
      )}
    </>
  );
};

export const ParkingTable = ({ siteId }: { siteId: string | null | undefined }) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });
  const { data, error } = useGetParkingSessionsQuery(
    {
      site_id: siteId,
      count: pagination.pageSize,
      page: pagination.pageIndex,
    },
    {
      pollingInterval: 30 * 1000, // in msec
    }
  );

  const sessions = data?.data?.sessions;
  return (
    <>
      {error && (
        <Alert severity="error">
          <FormattedMessage id="error-occured" />
        </Alert>
      )}
      <TableContainer>
        <Table>
          <TableBody>
            <TableRowSkeleton isLoading={!sessions} columnCount={3} rowCount={10} />
            {sessions &&
              sessions.map((session, index) => <ParkingRow key={session.id} session={session} />)}
            {sessions && sessions.length === 0 && <TableNoResults columnCount={3} />}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={3}>
                <TablePagination
                  gotoPage={(idx: number) => {
                    setPagination({ ...pagination, pageIndex: idx });
                  }}
                  setPageSize={(size: number) => {
                    setPagination({ ...pagination, pageSize: size });
                  }}
                  pageIndex={pagination.pageIndex}
                  pageSize={pagination.pageSize}
                  totalPages={data?.meta.pagination.page_count ?? 1}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
