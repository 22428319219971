import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Chip, Grid, Tab } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import GravityTable from "components/table/GravityTable";
import useMobile from "hooks/useMobile";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useGetComponentConfigQuery, Variable } from "service/api-slice";

const columnHelper = createColumnHelper<Variable>();

const columns = [
  columnHelper.accessor("name", {
    cell: (i) => <Chip label={<pre>{i.getValue()}</pre>} size="small" />,
    header: () => <FormattedMessage id="name" />,
  }),
  columnHelper.accessor("instance", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="instance" />,
    enableHiding: false,
  }),
  columnHelper.accessor("unit", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="unit" />,
    meta: {
      hidden: true,
    },
  }),
  columnHelper.accessor("data_type", {
    cell: (i) => i.getValue(),
    enableSorting: false,
    header: () => <FormattedMessage id="data_type" />,
    meta: {
      showOnMobile: false,
    },
  }),
  columnHelper.accessor("min_limit", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="min_limit" />,
    meta: {
      hidden: true,
    },
  }),
  columnHelper.accessor("max_limit", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="max_limit" />,
    meta: {
      hidden: true,
    },
  }),
  columnHelper.accessor("values_list", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="values_list" />,
    meta: {
      hidden: true,
    },
  }),
  columnHelper.accessor("supports_monitoring", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="supports_monitoring" />,
    meta: {
      hidden: true,
    },
  }),
  columnHelper.accessor("attribute.type", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="attribute_type" />,
  }),
  columnHelper.accessor("attribute.value", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="attribute_value" />,
  }),
  columnHelper.accessor("attribute.mutability", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="attribute_mutability" />,
  }),
  columnHelper.accessor("attribute.persistent", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="attribute_persistent" />,
    meta: {
      hidden: true,
    },
  }),
  columnHelper.accessor("attribute.constant", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="attribute_constant" />,
    meta: {
      hidden: true,
    },
  }),
];

const ConfigurationView = ({ csId }: { csId: string }) => {
  const { data } = useGetComponentConfigQuery(csId);
  const [selectedTab, setSelectedTab] = useState("one");
  const mobile = useMobile();

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setSelectedTab(newValue);
    },
    [setSelectedTab]
  );

  return (
    <TabContext value={selectedTab}>
      <Grid container sx={{ borderTop: 1, borderColor: "divider" }}>
        <Grid item xs={12} md={2}>
          <TabList
            orientation={mobile ? "horizontal" : "vertical"}
            variant="scrollable"
            centered={false}
            onChange={handleChange}
            sx={{ borderRight: 1, borderColor: "divider", width: "100%", height: "100%" }}
          >
            {data?.data?.map((config, index) => (
              <Tab
                key={`tab-${config.id}`}
                value={config.id}
                label={config.name}
                sx={{ alignItems: "start" }}
              />
            ))}
          </TabList>
        </Grid>
        <Grid item xs={12} md={10}>
          {data?.data?.map((config, index) => (
            <TabPanel key={`tab-panel-${config.id}`} value={config.id} sx={{ p: 0 }}>
              <GravityTable<Variable>
                columns={columns}
                data={config.variables}
                toolbar={{ showFilter: true }}
              />
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </TabContext>
  );
};

export default ConfigurationView;
