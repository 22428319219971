import { Grid } from "@mui/material";
import DefaultWeekdays from "./DefaultWeekdays";
import SpecificDates from "./SpecificDates";

const GarageOperatorScheduler = () => {
  const siteId = "688ccf85-b51d-4287-9cf6-ece971463a98";
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={3}>
        <DefaultWeekdays siteId={siteId} />
      </Grid>
      <Grid item xs={12} lg={9}>
        <SpecificDates siteId={siteId} />
      </Grid>
    </Grid>
  );
};

export default GarageOperatorScheduler;
