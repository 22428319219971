import { Tooltip } from "@mui/material";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import { format } from "date-fns-tz";
import { ChargingStation } from "service/api-slice";

const ChargingStationLastSeen = (props: { chargingStation: ChargingStation }) => {
  if (!props.chargingStation.status?.last_seen) {
    return <span>-</span>;
  }

  const lastSeen = parseISO(props.chargingStation.status.last_seen);

  return (
    <Tooltip title={format(lastSeen, "MM/dd/yyyy HH:mm:ss zzz")}>
      <span>{formatDistanceToNowStrict(lastSeen, { addSuffix: true })}</span>
    </Tooltip>
  );
};

export default ChargingStationLastSeen;
