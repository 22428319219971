import { Button } from "@mui/material";
import axios from "axios";
import { PumpStation } from "modules/site-operator/pumps/records";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { sec } from "utils/security";

/* eslint react/no-unstable-nested-components : 0 */

interface Props {
  name: string;
}

type PumpRequest = {
  pump_stations: PumpStation[];
};

const PumpStations: React.FC<Props> = ({ name }) => {
  const [pumps, setPumps] = useState<PumpStation[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchPumps() {
      const token = await sec.getAccessTokenSilently();
      const response = await axios.get<PumpRequest>(
        `${process.env.REACT_APP_API_BASE_URL}/pump-stations2`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPumps(response.data.pump_stations);
    }
    fetchPumps();
  }, []);

  const ManageButton = (ps: PumpStation) => {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={(_) =>
          navigate(ps.id, {
            state: { pump_station: ps },
          })
        }
      >
        Manage
      </Button>
    );
  };

  return (
    <DataTable value={pumps} sortMode="multiple">
      <Column header="Manage" body={(x: PumpStation) => ManageButton(x)}></Column>
      <Column field="id" header="Id" sortable filter filterField="code"></Column>
      <Column field="name" header="Name" sortable filter filterField="name"></Column>
      <Column field="site_id" header="Site Id" sortable filter filterField="site_id"></Column>
      <Column field="site_name" header="Site Name" sortable filter filterField="site_name"></Column>
      <Column
        field="powered_on"
        header="Powered On"
        sortable
        filter
        filterField="powered_on"
      ></Column>
      <Column
        field="refresh_enabled"
        header="Refresh Enabled"
        sortable
        filter
        filterField="refresh_enabled"
      ></Column>
      <Column
        field="refresh_active"
        header="Refresh Active"
        sortable
        filter
        filterField="refresh_active"
      ></Column>
      <Column
        field="service_mode"
        header="Service Mode"
        sortable
        filter
        filterField="service_mode"
      ></Column>
    </DataTable>
  );
};

export default PumpStations;
