import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import TableNoResults from "components/TableNoResults";
import { FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { Envelope, Transaction } from "service/api-slice";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";
import TransactionVehicleInfo from "./TransactionVehicleInfo";

const columnHelper = createColumnHelper<Transaction>();

const columns = [
  columnHelper.accessor("vehicle", {
    id: "vehicle",
    cell: (i) => {
      if (i.row.original) {
        return <TransactionVehicleInfo transaction={i.row.original} />;
      }
      return "-";
    },
    header: () => <FormattedMessage id="vehicle" />,
  }),
  columnHelper.accessor("start_time", {
    id: "start_time",
    cell: (i) => parseAndFormatDate(i.getValue()),
    header: () => <FormattedMessage id="start_time" />,
  }),
  columnHelper.accessor("last_meter_values", {
    id: "time-remaining",
    cell: (i) => {
      const soc = i.getValue()?.soc;
      const time_remaining = i.getValue()?.time_remaining;
      if (soc) {
        return `${soc}% (${time_remaining} min remaining)`;
      }
      return "-";
    },
    header: () => <FormattedMessage id="soc" />,
  }),
  columnHelper.accessor("last_meter_values", {
    id: "power",
    cell: (i) => {
      const power = i.getValue()?.power;
      if (power && power > 0) {
        return `${(power / 1000).toFixed(2)} kW`;
      }
      return "-";
    },
    header: () => <FormattedMessage id="power" />,
  }),
];

export const ActiveTransactionsTable = (props: {
  data?: Envelope<Transaction[]>;
  isLoading: boolean;
  isError: boolean;
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const table = useReactTable({
    data: props.data?.data ?? [],
    columns: columns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableContainer>
      <Table sx={{ minWidth: 350 }} aria-label="Charger Sessions Table">
        {!isSmallScreen && (
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableCell key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableHead>
        )}
        <TableBody>
          {(props.isLoading || props.isError) &&
            table.getHeaderGroups().map((headerGroup) => {
              return [...Array(10).keys()].map((i) => (
                <TableRow key={i}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableCell key={`${i}-${header.id}`} colSpan={header.colSpan}>
                        <Skeleton />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ));
            })}
          {table.getRowModel().rows.map((row) => {
            return (
              <TableRow key={row.id} hover={true} sx={{ cursor: "pointer" }}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {table.getRowModel().rows.length === 0 && (
            <TableNoResults columnCount={table.getAllColumns().length} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
