import { Alert, AlertTitle, Box, Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MainCard from "components/MainCard";
import EnergyUsedChart from "components/charts/EnergyUsed";
import TotalSessionsChart from "components/charts/TotalSessions";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";
import {
  ChargingStation,
  GetSessionsArgs,
  Transaction,
  useGetChargingStationsQuery,
  useGetPumpStationsQuery,
} from "../../../service/api-slice";

import NewVehicles from "components/charts/NewVehicles";
import SessionFailures from "components/charts/SessionFailures";
import { useNavigateWithModule } from "hooks/useNavigateWithModule";
import { TransactionsTable } from "pages/transactions/components/TransactionsTable";
import { useModuleContext } from "types/context";
import ChargePointStatusChart from "../charging-stations/components/ChargePointStatus";
import FaultedChargingStations from "../charging-stations/components/FaultedChargingStations";
import OccupiedChargingStations from "../charging-stations/components/OccupiedChargingStations";

const DefaultDashboard = () => {
  const intl = useIntl();
  const { filter, module } = useModuleContext();
  const theme = useTheme();
  const navigate = useNavigateWithModule(module);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const sessionArgs: GetSessionsArgs = {
    start: filter.timeRange.start.toISOString(),
    end: filter.timeRange.end.toISOString(),
    count: 25,
    page: 0,
    orgId: filter.orgId,
    siteId: filter.siteId,
    new: true,
  };

  const navigateToChargingStation = useCallback(
    (chargingStation: ChargingStation) => {
      navigate(`charging-stations/${chargingStation.id}`);
    },
    [navigate]
  );

  const navigateToTransaction = useCallback(
    (transaction: Transaction) => {
      navigate(
        `charging-stations/${transaction.charging_station.id}/transactions/${transaction.id}`
      );
    },
    [navigate]
  );

  const { data: csResp } = useGetChargingStationsQuery(
    {
      siteId: filter.siteId,
      expand_authorization: true,
      expand_cooling: true,
      expand_current_transaction: true,
    },
    {
      pollingInterval: 15_000,
      skipPollingIfUnfocused: true,
    }
  );

  const occupiedChargingStations = useMemo(() => {
    return csResp?.data?.filter((cs) => cs.status?.status === "Occupied") ?? [];
  }, [csResp]);

  const faultedChargingStations = useMemo(() => {
    return (
      csResp?.data?.filter(
        (cs) => cs.status?.status === "Faulted" || cs.status?.status === "Offline"
      ) ?? []
    );
  }, [csResp]);

  const { data: pumpsResp } = useGetPumpStationsQuery(undefined, {
    pollingInterval: 60_000,
    skipPollingIfUnfocused: true,
  });

  return (
    <Grid container spacing={2}>
      {/* row 1 */}
      {!mobile && (
        <>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MainCard content={false}>
              <Box sx={{ p: 2.25 }}>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      <FormattedMessage id="charging-station-status-chart-label" />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Stack direction="row" alignItems="center" spacing={0}>
                      <Button
                        color="secondary"
                        size="small"
                        component={RouterLink}
                        to={`/${module}/charging-stations`}
                      >
                        <FormattedMessage id="view-charging-stations" />
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <ChargePointStatusChart height={160} />
              <br />
            </MainCard>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <EnergyUsedChart height={160} sessionArgs={sessionArgs} />
          </Grid>

          <Grid item xs={12} sm={6} md={12} lg={12}>
            <TotalSessionsChart height={160} sessionArgs={sessionArgs} />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <SessionFailures
              height={160}
              args={{
                start: filter.timeRange.start.toISOString(),
                end: filter.timeRange.end.toISOString(),
                siteId: filter.siteId,
                orgId: filter.orgId,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <NewVehicles height={160} orgId={filter.orgId} />
          </Grid>
        </>
      )}

      {pumpsResp?.pump_stations
        .filter((p) => p.powered_on === false && p.site_name)
        .map((pumpStation) => {
          return (
            <Grid item xs={12} md={12} lg={12} key={pumpStation.id}>
              <Alert severity="error" variant="filled">
                <AlertTitle>
                  <FormattedMessage id="cooling-system-off" />
                </AlertTitle>
                Pump station <strong>{pumpStation.name}</strong> is powered off
              </Alert>
            </Grid>
          );
        })}

      {/* row 2 */}
      {faultedChargingStations.length > 0 && (
        <Grid item xs={12} md={12} lg={12}>
          <MainCard
            content={false}
            title={
              intl.formatMessage({ id: "faulted-charging-stations" }) +
              " (" +
              faultedChargingStations.length +
              ")"
            }
            collapsible={{ expanded: !mobile }}
          >
            <FaultedChargingStations
              chargingStations={faultedChargingStations}
              onClick={navigateToChargingStation}
            />
          </MainCard>
        </Grid>
      )}

      {/* row 2 */}
      {occupiedChargingStations.length > 0 && (
        <Grid item xs={12} md={12} lg={12}>
          <MainCard
            content={false}
            title={
              intl.formatMessage({ id: "occupied-charging-stations" }) +
              " (" +
              occupiedChargingStations.length +
              ")"
            }
          >
            <OccupiedChargingStations
              chargingStations={occupiedChargingStations}
              onClick={(chargingStation: ChargingStation) => {
                if (chargingStation.current_transaction) {
                  navigateToTransaction(chargingStation.current_transaction);
                } else {
                  navigateToChargingStation(chargingStation);
                }
              }}
            />
          </MainCard>
        </Grid>
      )}

      {/* row 3 */}
      <Grid item xs={12} md={12} lg={12}>
        <MainCard content={false} title={intl.formatMessage({ id: "charging-sessions" })}>
          <TransactionsTable
            startTime={filter.timeRange.start}
            endTime={filter.timeRange.end}
            orgId={filter.orgId}
            siteId={filter.siteId}
            onClick={navigateToTransaction}
          />
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default DefaultDashboard;
