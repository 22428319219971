import { Button } from "@mui/material";
import { Feature } from "experimentation/Feature";
import { Flags } from "experimentation/Flags";
import { useNavigateWithModule } from "hooks/useNavigateWithModule";
import VehicleName from "modules/site-operator/charging-stations/components/VehicleName";
import { SyntheticEvent } from "react";
import { FormattedMessage } from "react-intl";
import { Transaction } from "service/api-slice";
import { useModuleContext } from "types/context";

const TransactionVehicleInfo = ({ transaction }: { transaction: Transaction }) => {
  const vehicle = transaction?.vehicle;
  const hasVin = vehicle?.vin !== "" && vehicle?.vin !== null;

  const { module } = useModuleContext();
  const navigate = useNavigateWithModule(module);

  if (!vehicle || hasVin) {
    return <VehicleName vehicle={vehicle} />;
  }

  const navigateToRegisterVehicle = (e: SyntheticEvent) => {
    e.stopPropagation();
    navigate(
      `charging-stations/${transaction.charging_station.id}/transactions/${transaction.id}/register-vehicle`
    );
  };

  return (
    <Feature flag={Flags.VehicleImageUpload} fallback={<VehicleName vehicle={vehicle} />}>
      <Button onClick={navigateToRegisterVehicle} sx={{ marginLeft: -1 }}>
        <FormattedMessage id="register-vehicle" />
      </Button>
    </Feature>
  );
};

export default TransactionVehicleInfo;
