import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import DateRangePicker from "components/DateRangePicker";
import { endOfDay, startOfDay } from "date-fns";
import { useQueryParamsState } from "hooks/useQueryParamsState";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Outlet } from "react-router";
import { Module } from "types/modules";

const now = new Date();
const defaultStartTime = startOfDay(now);
const defaultEndTime = endOfDay(now);

const FleetOperatorIndex = () => {
  const [range, setRange] = useQueryParamsState("range", [
    defaultStartTime.getTime(),
    defaultEndTime.getTime(),
  ]);

  const context = useMemo(() => {
    const start = range[0];
    const end = range[1];

    return {
      module: Module.FleetOperator,
      filter: {
        timeRange: {
          start: new Date(start),
          end: new Date(end),
        },
        tags: [],
      },
    };
  }, [range]);

  return (
    <Stack rowGap={2}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h4">
            <FormattedMessage id="overview" />
          </Typography>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={1}>
            <DateRangePicker
              selection={{
                start: context.filter.timeRange.start,
                end: context.filter.timeRange.end,
              }}
              onSelectionChanged={({ start, end }) => {
                setRange([start.getTime(), end.getTime()]);
              }}
            />
          </Stack>
        </Grid>
      </Grid>
      <Outlet context={context} />
    </Stack>
  );
};

export default FleetOperatorIndex;
