import { Autocomplete, Box, TextField } from "@mui/material";
import { useState } from "react";
import { VehicleOwner, useGetVehicleOwnersQuery } from "service/api-slice";

const OwnerSelector = ({
  onOwnerChange,
  defaultValue,
  label,
  width,
  fullWidth = true,
}: {
  width?: number;
  fullWidth?: boolean;
  label?: React.ReactNode;
  defaultValue?: VehicleOwner | null;
  onOwnerChange: (selectedOwner: VehicleOwner | null) => void;
}) => {
  const { data } = useGetVehicleOwnersQuery();
  const [selectedOwner, setSelectedOwner] = useState<VehicleOwner | null>(defaultValue || null);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: VehicleOwner | null) => {
    setSelectedOwner(newValue);
    onOwnerChange(newValue);
  };

  return (
    <Box width={width}>
      <Autocomplete
        fullWidth={fullWidth}
        value={selectedOwner}
        onChange={handleChange}
        options={data?.data || []}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        getOptionLabel={(option) => option.display_name}
        renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
      />
      <input type="hidden" name="owner_id" value={selectedOwner?.id} />
    </Box>
  );
};

export default OwnerSelector;
